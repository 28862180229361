<template>
  <cp-module-modal
    :modalConf="modalConf"
    @opened="modalOpened"
    v-on="$listeners"
    width="600"
  >
    <v-toolbar dark color="primary" height="52">
      <v-toolbar-title>Add Task </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon class="mr-1" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card :loading="tasksLoading" class="modal-content-card">
      <v-card-text>
        <v-text-field
          :value="search"
          @input="q_text => updateTasksParams({ q_text })"
          :label="addExistingTaskModalTitle"
          hide-details
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          class="card-listing-search-field"
        />
        <!-- <v-text-field v-model="search" hide-details dense outlined placeholder="Search"
                    append-icon="mdi-magnify"></v-text-field> -->
        <v-list class="list-add-tasks">
          <Scrollbar
            ref="scrollbar"
            @ps-y-reach-end="loadMore"
            suppress-scroll-x
          >
            <!-- <v-radio-group v-model="tasksArray" multiple hide-details> -->
            <v-list-item v-for="task in tasks" :key="task.id">
              <!-- <v-radio :label="tasksList.task" :value="tasksList.id"></v-radio> -->
              <v-checkbox
                hide-details
                v-model="tasksArray"
                :label="task.title"
                :value="task.id"
              />
              <v-list-item-action>
                <cp-explain root-slot max-width="400">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="#8B5DCB">mdi-information</v-icon>
                  </template>

                  <task-detail-card :item="task" />
                </cp-explain>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="tasksLoading || loadingMore">
              <v-list-item-icon>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-list-item-icon>
              <v-list-item-content>Loading...</v-list-item-content>
            </v-list-item>
            <!-- </v-radio-group> -->
          </Scrollbar>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" text @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" @click="submitAndClose">Add Task</v-btn>
      </v-card-actions>
    </v-card>
  </cp-module-modal>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";
import { mapActions, mapState, mapGetters } from "vuex";

import { wait } from "@cp/utils/promiseUtils";
import { tasks } from "@/store/modules/plans";

export default {
  name: "AddExistingTaskModal",
  mixins: [tasks.modalMixin, tasks.loadMoreMixin],
  components: { Scrollbar },
  computed: {
    ...mapState("plans", ["tasks", "tasksLoading"]),
    ...mapState("plans", {
      planId: s => s.item.id,
      alreadyAssignedIds: s => s.item.tasks.map(x => x.id),
      search: s => s.tasksParams.q_text,
    }),
    ...mapGetters("plans", ["addExistingTaskModalTitle"]),
  },
  data: () => ({
    tasksArray: [],
  }),
  methods: {
    ...mapActions("plans", [
      "fetchTasks",
      "updateTasksParams",
      "addTasksToItem",
      "refetchItem",
    ]),
    async submitAndClose() {
      await this.addTasksToItem(this.tasksArray);
      this.$CpEvent.$emit("snackAlert", {
        message: "Tasks Added to Plan",
        color: "success",
      });
      this.closeModal({ success: true });
      this.tasksArray = [];
      // await wait(100);
      // this.refetchItem();
    },
    modalOpened() {
      this.fetchTasks().then(async () => {
        await wait(10);
        // for some reason, it has a x-scroll. this kills that
        this.$refs.scrollbar.$el.scrollTo(0, 1);
        await wait(10);
        this.$refs.scrollbar.$el.scrollTo(0, 0);
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.list-add-tasks.v-list::v-deep {
  border: 1px solid #afb4c5;
  border-radius: 4px;
  height: calc(100vh - 400px);
  padding: 0;
  margin-top: 1rem;

  > .ps {
    height: 100%;
    overflow-x: hidden !important;
  }

  .v-list-item {
    padding: 0;
    min-height: auto;
    align-items: stretch;

    & + .v-list-item {
      border-top: 1px solid #afb4c5;
    }

    &__action {
      margin: 0;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  .v-input,
  .v-input__slot {
    margin: 0;
  }

  .v-input {
    padding: 0;

    &--checkbox {
      margin: 0;
      width: calc(100% - 2.5rem);

      & > label {
        color: #52555e;
      }
    }

    &__slot {
      padding: 1rem;
    }
  }
}
</style>
