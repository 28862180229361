import Dashboard from "../views/Dashboard.vue";
import Plans from "../views/Plans.vue";
import Tasks from "../views/Tasks.vue";
import Assignments from "../views/Assignments.vue";
import Calculator from "../views/Calculator.vue";

// Employee status
import Employees from "../views/Employees.vue";
import EmployeeStatus from "../views/EmployeeStatus.vue";

// plan table views
import PlansReports from "../views/reports/Plans.vue";
import TasksReports from "../views/reports/Tasks.vue";
import AssigneesReports from "../views/reports/Assignees.vue";
import RegionsReports from "../views/reports/Regions.vue";
import LocationsReports from "../views/reports/Locations.vue";

// single plan views
import PlanReport from "../views/reports/PlanReport.vue";
import TaskReport from "../views/reports/TaskReport.vue";
import AssigneeReport from "../views/reports/AssigneeReport.vue";
import RegionReport from "../views/reports/RegionReport.vue";
import LocationReport from "../views/reports/LocationReport.vue";

export default [
  {
    path: "/",
    redirect: "/plans",
  },
  {
    path: "/plans/:id?",
    name: "Plans",
    component: Plans,
  },
  {
    path: "/tasks/:id?",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/assignments/:id?",
    name: "Assignments",
    component: Assignments,
  },
  {
    path: "/employees",
    name: "EmployeesReports",
    component: Employees,
  },
  {
    path: "/employees/:id",
    name: "EmployeeStatus",
    component: EmployeeStatus,
  },
  {
    path: "/reports/plans",
    name: "PlansReports",
    component: PlansReports,
  },
  {
    path: "/reports/plans/:id",
    name: "PlanReport",
    component: PlanReport,
  },
  {
    path: "/reports/tasks",
    name: "TasksReports",
    component: TasksReports,
  },
  {
    path: "/reports/tasks/:id",
    name: "TaskReport",
    component: TaskReport,
  },
  {
    path: "/reports/assignees",
    name: "AssigneesReports",
    component: AssigneesReports,
  },
  {
    path: "/reports/assignees/:id",
    name: "AssigneeReport",
    component: AssigneeReport,
  },
  {
    path: "/reports/regions",
    name: "RegionsReports",
    component: RegionsReports,
  },
  {
    path: "/reports/regions/:id",
    name: "RegionReport",
    component: RegionReport,
  },
  {
    path: "/reports/locations",
    name: "LocationsReports",
    component: LocationsReports,
  },
  {
    path: "/reports/locations/:id",
    name: "LocationReport",
    component: LocationReport,
  },
  {
    path: "/calculator",
    name: "Calculator",
    component: Calculator,
    meta: {
      title: "Calculator",
    },
  },
];
