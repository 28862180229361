import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('cp-breadcrumbs',_vm._b({},'cp-breadcrumbs',_vm.breadcrumbProps,false))]},proxy:true}])},[_vm._v(" Location Report ")]),_c(VCard,{staticClass:"modal-content-card mcc-details"},[_c(VCardText,[_c('div',{staticClass:"d-flex flex-column align-center"},[_c(VProgressCircular,{staticClass:"dashboard-compliance-score mt-0",attrs:{"rotate":-90,"size":120,"width":14,"value":_vm.report.compliant_percentage,"color":_vm.report.compliant_percentage < 70 ? '#E40000' : '#009A44'}},[_vm._v(" "+_vm._s(_vm.report.compliant_percentage)+"% ")]),_c('p',{staticClass:"text-center"},[_c('small',[_vm._v("Average"),_c('br'),_vm._v("Compliance")])])],1),_c('div',[_c('h4',{staticClass:"mb-3 d-flex"},[_c(VIcon,[_vm._v("mdi-check-decagram")]),_vm._v(_vm._s(_vm.detail.full_name)+" ")],1)])])],1),_c(VCard,{staticClass:"mt-4"},[_c(VTabs,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab,i){return _c(VTab,{key:i},[_vm._v(_vm._s(tab.label))])}),1),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab,i){return _c(VTabItem,{key:i},[_c('report-sub-table',_vm._g(_vm._b({},'report-sub-table',tab.tableProps,false),tab.tableListeners))],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }