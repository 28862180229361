import { mergeMixins } from "@cp/store/mixins";
import { ReportsTable, ReportPage } from "@/store/mixins";
import { keyBy } from "@cp/utils/arrayUtils";

const module = "reports";
const reportsApi = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/reports/api`;
const plansUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/plans`;
const tasksUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/tasks`;
const userUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/users`;
const regionUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/geographical_groups`;
const locationUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/locations`;

export const completedTypes = [
  {
    id: 1,
    icon: "mdi-checkbox-marked-outline",
    color: "#008a09",
    props: { color: "success" },
  },
  {
    id: 2,
    icon: "mdi-checkbox-blank-outline",
    color: "#9e9e9e",
    props: { color: "grey" },
  },
  {
    id: 3,
    icon: "mdi-checkbox-blank-off-outline",
    color: "#f0b323",
    props: { color: "warning" },
  },
];
export const completedTypeObj = keyBy(completedTypes, "id");

export const plansTable = new ReportsTable({
  module,
  baseUrl: reportsApi,
  name: "plans",
  noun: "plan",
  params: {
    structures: {
      scope: "plans",
    },
  },
  tableOptions: {
    sort_by: "compliant_percentage",
  },
  headers: [
    {
      text: "",
      align: "start",
      sortable: false,
      value: "alert",
      width: "50",
    },
    {
      text: "Score",
      sortable: true,
      value: "compliant_percentage",
      width: 100,
    },
    {
      text: "Plan",
      align: "start",
      sortable: true,
      value: "title",
      width: "50%",
      cellClass: "clickable-cell",
    },
    {
      text: "Type",
      value: "name",
      sortable: true,
    },
    {
      text: "Modified",
      value: "updated_at",
      sortable: true,
      width: 180,
    },
  ],
  filters: [
    {
      type: "radio",
      key: "plan_types",
      items: [
        { label: "All", value: "" },
        { label: "Onboarding", value: 1 },
        { label: "Offboarding", value: 2 },
      ],
      initialValue: "",
    },
    {
      type: "sub_query",
      key: "locations",
      label: "Locations",
      multiple: true,
    },
    {
      type: "radio",
      key: "general",
      items: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: "active",
    },
  ],
});

export const planReport = new ReportPage({
  module,
  name: "plan",
  detailConfig: {
    baseUrl: plansUrl,
    initialValue: {
      id: "",
      plan_status_id: 1,
      plan_type_id: 1,
      title: "",
      description: "",
      updated_at: "",
      "is_deletable?": false,
      plan_type: {
        label: "",
        value: 0,
      },
      plan_status: {
        label: "",
        value: 0,
      },
      employees_on_plan: 0,
      positions_for_plan: [],
      position_ids: [],
      location_ids: [],
      region_ids: [],
      tasks: [],
      scores: [],
    },
  },
  reportConfig: {
    filterByIdKey: "plans",
  },
  subReports: [
    {
      scope: "tasks",
      label: "Tasks in this plan",
      routeName: "TaskReport",
      headers: [
        {
          text: "",
          sortable: false,
          value: "task_info",
          width: "50",
        },
        {
          text: "Task",
          align: "start",
          sortable: true,
          value: "title",
          width: "50%",
          cellClass: "clickable-cell",
        },
        {
          text: "Assigned To",
          sortable: true,
          value: "assignment_type",
        },
      ],
    },
    {
      scope: "assignees",
      label: "Task Assignees",
      routeName: "AssigneeReport",
      headers: [
        {
          text: "Employee",
          sortable: true,
          value: "full_name",
          cellClass: "clickable-cell",
        },
        {
          text: "Position",
          sortable: true,
          value: "position",
        },
        {
          text: "Location",
          sortable: true,
          value: "location",
        },
      ],
    },
    {
      scope: "locations",
      label: "Locations using plan",
      routeName: "LocationReport",
      headers: [
        {
          text: "Location",
          sortable: true,
          value: "name",
          cellClass: "clickable-cell",
        },
      ],
    },
  ],
});

export const tasksTable = new ReportsTable({
  module,
  baseUrl: reportsApi,
  name: "tasks",
  noun: "task",
  params: {
    structures: {
      scope: "tasks",
    },
  },
  tableOptions: {
    sort_by: "compliant_percentage",
  },
  headers: [
    {
      text: "",
      align: "start",
      sortable: false,
      value: "alert",
      width: "50",
    },
    {
      text: "Score",
      sortable: true,
      value: "compliant_percentage",
      width: 100,
    },
    {
      text: "Task",
      align: "start",
      sortable: true,
      value: "title",
      cellClass: "clickable-cell",
    },
    {
      text: "Modified",
      value: "updated_at",
      sortable: true,
      width: 180,
    },
  ],
  filters: [
    {
      type: "radio",
      key: "plan_types",
      items: [
        { label: "All", value: "" },
        { label: "Onboarding", value: 1 },
        { label: "Offboarding", value: 2 },
      ],
      initialValue: "",
    },
    {
      type: "sub_query",
      key: "locations",
      label: "Locations",
      multiple: true,
    },
    {
      type: "radio",
      key: "general",
      items: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: "active",
    },
  ],
});

export const taskReport = new ReportPage({
  module,
  name: "task",
  detailConfig: {
    baseUrl: tasksUrl,
    initialValue: {
      task_status_id: 1,
      task_type_id: 1,
      title: "",
      description: "",
      updated_at: "",
      "is_deletable?": false,
      due_type: "",
      assignment_type: "",
      assignment_group_id: null,
      alert_lead_time: 0,
      days_till_abandon: 0,
      days_till_escalate: 0,
      escalate: true,
      schedule: "",
      task_type: {
        text: "Onboarding",
        value: 1,
      },
      due_day_distance: 0,
      task_status: {
        text: "Active",
        value: 1,
      },
      assigned_to: "",
      employees_on_task: 0,
      plans: [],
      files: [],
    },
  },
  reportConfig: {
    filterByIdKey: "tasks",
  },
  subReports: [
    {
      scope: "plans",
      label: "This task in plans",
      routeName: "PlanReport",
      headers: [
        {
          text: "",
          sortable: false,
          value: "plan_info",
          width: "50",
        },
        {
          text: "Plan",
          align: "start",
          sortable: true,
          value: "title",
          width: "50%",
          cellClass: "clickable-cell",
        },
      ],
    },
    {
      scope: "assignees",
      label: "Assignees",
      routeName: "AssigneeReport",
      headers: [
        {
          text: "Employee",
          sortable: true,
          value: "full_name",
          cellClass: "clickable-cell",
        },
        {
          text: "Position",
          sortable: true,
          value: "position",
        },
        {
          text: "Location",
          sortable: true,
          value: "location",
        },
      ],
    },
    {
      scope: "locations",
      label: "This task at different locations",
      routeName: "LocationReport",
      headers: [
        {
          text: "Location",
          sortable: true,
          value: "name",
          cellClass: "clickable-cell",
        },
      ],
    },
  ],
});

export const assigneesTable = new ReportsTable({
  module,
  baseUrl: reportsApi,
  name: "assignees",
  noun: "assignee",
  params: {
    structures: {
      scope: "assignees",
    },
  },
  tableOptions: {
    sort_by: "compliant_percentage",
  },
  headers: [
    {
      text: "",
      align: "start",
      sortable: false,
      value: "alert",
      width: "50",
    },
    {
      text: "Score",
      sortable: true,
      value: "compliant_percentage",
      width: 100,
    },
    {
      text: "Employee",
      align: "start",
      sortable: true,
      value: "full_name",
      cellClass: "clickable-cell",
    },
    {
      text: "Position",
      sortable: true,
      value: "position",
    },
    {
      text: "Location",
      sortable: true,
      value: "location",
    },
    {
      text: "Tasks Assigned",
      sortable: true,
      value: "task_count",
    },
  ],
  filters: [
    {
      type: "sub_query",
      key: "locations",
      label: "Location",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "positions",
      label: "Position",
      multiple: true,
    },
    {
      type: "radio",
      key: "general",
      items: [
        { label: "All", value: "" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: "",
    },
  ],
});

export const assigneeReport = new ReportPage({
  module,
  name: "assignee",
  detailConfig: {
    baseUrl: userUrl,
    initialValue: {
      avatar: {
        url: "",
        thumb: { url: "" },
      },
      email: "",
      full_name: "",
      location: "",
      position: "",
      managers: [],
    },
  },
  reportConfig: {
    filterByIdKey: "assignees",
  },
  subReports: [
    {
      scope: "tasks",
      label: "Tasks assigned to employee",
      routeName: "TaskReport",
      headers: [
        {
          text: "",
          sortable: false,
          value: "task_info",
          width: "50",
        },
        {
          text: "Task",
          align: "start",
          sortable: true,
          value: "title",
          cellClass: "clickable-cell",
        },
        {
          text: "Task type",
          sortable: true,
          value: "task_type",
        },
        {
          text: "Times assigned",
          sortable: true,
          value: "users_task_count",
        },
      ],
    },
  ],
});

export const regionsTable = new ReportsTable({
  module,
  baseUrl: reportsApi,
  name: "regions",
  noun: "region",
  params: {
    structures: {
      scope: "regions",
    },
  },
  tableOptions: {
    sort_by: "compliant_percentage",
  },
  headers: [
    {
      text: "",
      align: "start",
      sortable: false,
      value: "alert",
      width: "50",
    },
    {
      text: "Score",
      sortable: true,
      value: "compliant_percentage",
      width: 100,
    },
    {
      text: "Region",
      align: "start",
      sortable: true,
      value: "name",
      cellClass: "clickable-cell",
    },
  ],
  filters: [
    {
      type: "radio",
      key: "general",
      items: [
        { label: "All", value: "" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: "",
    },
  ],
});

export const regionReport = new ReportPage({
  module,
  name: "region",
  detailConfig: {
    baseUrl: regionUrl,
    initialValue: {},
  },
  reportConfig: {
    filterByIdKey: "regions",
  },
  subReports: [
    {
      scope: "locations",
      label: "Locations in this region",
      routeName: "LocationReport",
      headers: [
        {
          text: "Location",
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "clickable-cell",
        },
      ],
    },
    {
      scope: "assignees",
      label: "Assignees in this region",
      routeName: "AssigneeReport",
      headers: [
        {
          text: "Employee",
          sortable: true,
          value: "full_name",
          cellClass: "clickable-cell",
        },
        {
          text: "Position",
          sortable: true,
          value: "position",
        },
        {
          text: "Location",
          sortable: true,
          value: "location",
        },
      ],
    },
  ],
});

export const locationsTable = new ReportsTable({
  module,
  baseUrl: reportsApi,
  name: "locations",
  noun: "location",
  params: {
    structures: {
      scope: "locations",
    },
  },
  tableOptions: {
    sort_by: "compliant_percentage",
  },
  headers: [
    {
      text: "",
      align: "start",
      sortable: false,
      value: "alert",
      width: "50",
    },
    {
      text: "Score",
      sortable: true,
      value: "compliant_percentage",
      width: 100,
    },
    {
      text: "Location",
      align: "start",
      sortable: true,
      value: "name",
      cellClass: "clickable-cell",
    },
  ],
  filters: [
    {
      type: "radio",
      key: "general",
      items: [
        { label: "All", value: "" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "archived" },
      ],
      initialValue: "",
    },
  ],
});

export const locationReport = new ReportPage({
  module,
  name: "location",
  detailConfig: {
    baseUrl: locationUrl,
    initialValue: {},
  },
  reportConfig: {
    filterByIdKey: "locations",
  },
  subReports: [
    {
      scope: "assignees",
      label: "Assignees at this location",
      routeName: "AssigneeReport",
      headers: [
        {
          text: "Employee",
          sortable: true,
          value: "full_name",
          cellClass: "clickable-cell",
        },
        {
          text: "Position",
          sortable: true,
          value: "position",
        },
        {
          text: "Location",
          sortable: true,
          value: "location",
        },
      ],
    },
  ],
});

export default mergeMixins(
  plansTable,
  planReport,
  tasksTable,
  taskReport,
  assigneesTable,
  assigneeReport,
  regionsTable,
  regionReport,
  locationsTable,
  locationReport
);
