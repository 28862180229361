<template>
  <div>
    <cp-h1>
      Employees
      <template
        #subtitle
        v-if="meta.pagination.records && tableProps.options.itemsPerPage"
      >
        Total:
        {{ meta.pagination.records }}
      </template>

      <template #right>
        <cp-filters-menu :module="table" class="search--center" />
      </template>
    </cp-h1>

    <v-card class="card-listing">
      <div class="pb-4">
        <v-data-table
          v-bind="tableProps"
          v-on="tableListeners"
          @click:row="
            item =>
              $router.push({
                name: 'EmployeeStatus',
                params: { id: item.id },
              })
          "
          class="clickable-rows"
          id="employees-table"
        >
          <template #header.position="{header}">
            <cp-table-header-subqueryable
              :header="header"
              :table="table"
              submodule-ref-key="employeesPositions"
            />
          </template>
          <template #header.location="{header}">
            <cp-table-header-subqueryable
              :header="header"
              :table="table"
              submodule-ref-key="employeesLocations"
            />
          </template>
          <template #header.user_lifecycle_statuses_id="{header}">
            <cp-table-header-subqueryable
              :header="header"
              :table="table"
              submodule-ref-key="employeesLifecycleStatuses"
            />
          </template>

          <template v-slot:item.users.first_name="{ item }">
            <strong>{{ item.full_name }}</strong>
          </template>

          <template v-slot:item.alert="{ item }">
            <v-badge
              v-if="parseInt(item.compliant_percentage) < 70"
              color="#E40000"
              dot
              class="v-badge-circle"
            ></v-badge>
          </template>

          <template v-slot:item.compliant_percentage="{ item }">
            <strong>{{ Math.round(item.compliant_percentage) }}%</strong>
          </template>

          <template v-slot:item.user_lifecycle_statuses_id="{ item }">
            {{ item.lifecycle_name }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { employeesTable } from "@/store/modules/employees";

export default {
  name: "EmployeesStatusTable",
  mixins: [employeesTable.mixin, employeesTable.filtersMixin],
};
</script>
