import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-module-modal',_vm._g({attrs:{"modalConf":_vm.modalConf,"width":"600"},on:{"opened":_vm.modalOpened}},_vm.$listeners),[_c(VToolbar,{attrs:{"dark":"","color":"primary","height":"52"}},[_c(VToolbarTitle,[_vm._v("Add Task ")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","icon":""},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCard,{staticClass:"modal-content-card",attrs:{"loading":_vm.tasksLoading}},[_c(VCardText,[_c(VTextField,{staticClass:"card-listing-search-field",attrs:{"value":_vm.search,"label":_vm.addExistingTaskModalTitle,"hide-details":"","dense":"","outlined":"","prepend-inner-icon":"mdi-magnify"},on:{"input":function (q_text) { return _vm.updateTasksParams({ q_text: q_text }); }}}),_c(VList,{staticClass:"list-add-tasks"},[_c('Scrollbar',{ref:"scrollbar",attrs:{"suppress-scroll-x":""},on:{"ps-y-reach-end":_vm.loadMore}},[_vm._l((_vm.tasks),function(task){return _c(VListItem,{key:task.id},[_c(VCheckbox,{attrs:{"hide-details":"","label":task.title,"value":task.id},model:{value:(_vm.tasksArray),callback:function ($$v) {_vm.tasksArray=$$v},expression:"tasksArray"}}),_c(VListItemAction,[_c('cp-explain',{attrs:{"root-slot":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"#8B5DCB"}},on),[_vm._v("mdi-information")])]}}],null,true)},[_c('task-detail-card',{attrs:{"item":task}})],1)],1)],1)}),(_vm.tasksLoading || _vm.loadingMore)?_c(VListItem,[_c(VListItemIcon,[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1),_c(VListItemContent,[_vm._v("Loading...")])],1):_vm._e()],2)],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.submitAndClose}},[_vm._v("Add Task")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }