<template>
  <div v-if="tasks.length">
    <div class="text-caption d-flex">
      Progress:
      <v-spacer horizontal />
      {{ Math.round(prct) }}%
    </div>
    <div class="bar-container">
      <div
        v-for="(bar, i) in bars"
        :key="i"
        class="status-bar"
        v-bind="bar.props"
      >
        {{ bar.count }}
      </div>
    </div>
  </div>
</template>

<script>
import { partition } from "@cp/utils/arrayUtils";

export default {
  name: "TasksProgressBar",
  props: { tasks: { type: Array, default: () => [] } },
  computed: {
    counts() {
      const [unresolved, resolved] = partition(
        this.tasks,
        x => x.completion === 2
      );
      return {
        unresolved: unresolved.length,
        resolved: resolved.length,
      };
    },
    prct() {
      return (this.counts.resolved / this.tasks.length) * 100;
    },
    bars() {
      const bars = [];

      if (this.counts.resolved)
        bars.push({
          props: {
            class: "grey lighten-1",
            style: {
              width: (this.counts.resolved / this.tasks.length) * 100 + "%",
            },
          },
          count: this.counts.resolved,
        });

      if (this.counts.unresolved)
        bars.push({
          props: {
            class: "grey lighten-2",
            style: {
              width: (this.counts.unresolved / this.tasks.length) * 100 + "%",
            },
          },
          count: this.counts.unresolved,
        });

      return bars;
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-container {
  width: 100%;
  height: 12px;
  display: flex;

  .status-bar {
    height: 100%;
    text-align: center;
    color: black;
    font-size: 9px;
    line-height: 12px;
  }
}
</style>
