<template>
  <v-card dense>
    <v-card-title>{{ item.name }}</v-card-title>
    <v-card-text v-if="item.assignee_count || item.escalation_count">
      <strong v-if="item.assignee_count" class="d-block">
        Assignees: {{ item.assignee_count }}
      </strong>
      <strong v-if="item.escalation_count" class="d-block">
        Escalations: {{ item.escalation_count }}
      </strong>
    </v-card-text>
    <v-card-text v-else class="empty">
      <v-icon>mdi-account-alert</v-icon>
      <h4>This Assignment is Empty</h4>
      <p>You need to add employees to this group</p>
      <v-btn color="primary" small @click="editAssignmentGroup(item.id)">
        <v-icon small>mdi-account-multiple-plus</v-icon>
        Add Employees
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AssignmentGroupDetailCard",
  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  padding-top: 0;
  padding-bottom: 0;

  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid #afb4c5;
    }

    &__title {
      font-size: 1rem;
      font-weight: 700;
      color: #52555e;
    }

    p {
      color: #52555e;
      margin-top: 3px;

      span {
        color: #767b87;
      }
    }
  }
}

.empty {
  padding-top: 1.5rem;
  text-align: center;

  & > .v-icon {
    width: 3.5rem;
    height: 3.5rem;
    background: rgba(30, 100, 220, 0.1);
    border: 1px solid rgba(30, 100, 220, 0.3);
    border-radius: 50%;
    color: #1e64dc;
    font-size: 2rem;
    margin-bottom: 0.375rem;
  }

  h4,
  p {
    color: #767b87;
    margin: 0.5rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
  }

  p {
    font-size: 14px;
  }

  .v-btn {
    .v-icon {
      margin-right: 4px;
    }
  }
}
</style>
