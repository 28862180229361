import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.alert",fn:function(ref){
var item = ref.item;
return [(parseInt(item.compliant_percentage) < 70)?_c(VBadge,{staticClass:"v-badge-circle",attrs:{"color":"#E40000","dot":""}}):_vm._e()]}},{key:"item.compliant_percentage",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(Math.round(item.compliant_percentage))+"%")])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }