import { Api } from "@cp/lib";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

export default class {
  constructor({
    data = {},
    meta = {
      filters: {
        date_range: {},
      },
      structures: {
        date_interval: {},
      },
      pagination: {
        current: null,
        total_page: null,
      },
    },
    filters = {},
    structures = {},
    page = {
      number: 1,
      size: 15,
    },
    q_text = null,
    callback = null,
    sortBy = null,
    sortDesc = false,
    awaitingResponse = false,
    chartData = [],
    chartInterval = "Monthly",
  } = {}) {
    this.data = data;
    this.meta = meta;
    this.filters = {
      ...filters,
    };
    this.structures = structures;
    this.page = {
      number: 1,
      size: 15,
      ...page,
    };
    this.sortBy = sortBy;
    this.sortDesc = sortDesc;
    this.q_text = q_text;
    this.callback = callback;
    this.awaitingResponse = awaitingResponse;
    this.chartData = chartData;
    this.chartInterval = chartInterval;

    this.bApi = new Api(`${process.env.VUE_APP_FIVER_API_PATH}/en/v1`);
    this.fetchTimeout = null;
  }

  fetch({ debounce = 0, callback = null } = {}) {
    // if the fetch should wait an amount of time before firing
    if (debounce && debounce > 0) {
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => {
        return this.fetch({ callback });
      }, debounce);

      return this.fetchTimeout;
    }

    this.awaitingResponse = true;

    // these two are to get around an annoyance with how vuetify handles multisort
    if (Array.isArray(this.sortBy)) {
      this.sortBy = this.sortBy[0];
    }

    if (Array.isArray(this.sortDesc)) {
      this.sortDesc = this.sortDesc[0];
    }

    return this.bApi
      .authorize()
      .get("intouch/dashboard", {
        // clear out any filters that are null or empty arrays
        filters: Object.entries(this.filters).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        ),
        structures: this.structures,
        page: this.page,
        q_text: this.q_text,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      })
      .then(response => {
        this.awaitingResponse = false;

        this.data = response.data;
        this.meta = response.meta;

        if (callback) callback(response);

        return response;
      });
  }

  download({
    filename = "export.xlsx",
    params = {},
    endpoint = "intouch/dashboard.xlsx",
  } = {}) {
    this.awaitingResponse = true;

    return this.bApi
      .authorize()
      .download(endpoint, filename, {
        filters: Object.entries(this.filters).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        ),
        structures: this.structures,
        ...params,
        page: {
          size: "all",
        },
      })
      .then(response => {
        this.awaitingResponse = false;
        return response;
      });
  }

  shareOverEmail({
    emails = [],
    message = null,
    link = null,
    endpoint = "intouch/dashboard.xlsx",
    params = {},
  }) {
    this.awaitingResponse = true;

    return this.bApi
      .authorize()
      .post(endpoint, {
        filters: Object.entries(this.filters).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        ),
        structures: this.structures,
        q_text: this.q_text,
        emails: emails,
        message: message,
        link: link,
        ...params,
        page: {
          size: "all",
        },
      })
      .then(response => {
        this.awaitingResponse = false;
        return response;
      });
  }

  previousPage() {
    if (this.onFirstPage()) return;

    this.page.number = this.meta.pagination.current - 1;

    this.fetch();
  }

  nextPage() {
    if (this.onLastPage()) return;

    this.page.number = this.meta.pagination.current + 1;

    this.fetch();
  }

  goToPage(pageNumber) {
    if (pageNumber <= 0 || pageNumber > this.meta.pagination.total_pages) {
      return;
    }

    this.page.number = pageNumber;

    this.fetch();
  }

  onFirstPage() {
    return this.meta.pagination.current == 1;
  }

  onLastPage() {
    return this.meta.pagination.current == this.meta.pagination.total_pages;
  }

  paginationOptions() {
    let options = [];

    for (var i = 1; i <= this.meta.pagination.total_pages; i++) {
      options.push({
        text: `Page ${i}`,
        value: i,
      });
    }

    return options;
  }

  reset() {
    this.filters = {};
    this.structures = {};
    this.page = {};
    this.q_text = null;
  }

  clear(path) {
    const [i, j] = typeof path === "string" ? path.split(".") : path;
    delete this[i][j];
  }
}
