<template>
  <cp-module-modal :modalConf="modalConf" v-on="$listeners" width="600">
    <v-toolbar dark color="primary" height="52">
      <v-toolbar-title>Enroll {{ item.full_name }} in plan:</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon class="mr-1" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card :loading="plansLoading">
      <v-card-text class="pt-1">
        <v-radio-group
          v-model="selectedPlanId"
          hide-details
          label="Select a plan:"
        >
          <v-list class="list-enroll">
            <Scrollbar ref="scrollbar" suppress-scroll-x>
              <v-list-item v-for="plan in plansForEmployee" :key="plan.id">
                <v-radio :label="plan.title" :value="plan.id" class="mb-0" />
                <v-list-item-action>
                  <cp-explain root-slot max-width="400">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="#8B5DCB">mdi-information</v-icon>
                    </template>

                    <plan-detail-card :item="plan" />
                  </cp-explain>
                </v-list-item-action>
              </v-list-item>

              <v-list-item v-if="plansLoading">
                <v-list-item-icon>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-list-item-icon>
                <v-list-item-content>Loading...</v-list-item-content>
              </v-list-item>
              <!-- </v-radio-group> -->
            </Scrollbar>
          </v-list>
        </v-radio-group>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" text @click="closeModal">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="submitAndClose"
          :disabled="!selectedPlanId"
          >Enroll</v-btn
        >
      </v-card-actions>
    </v-card>
  </cp-module-modal>
</template>

<script>
import Scrollbar from "vue-custom-scrollbar";
import { mapActions, mapState, mapGetters } from "vuex";

import { enrollmentModal } from "@/store/modules/enrollments";
import { mapErrors } from "carrot-patch-v2/src/utils/jsonapiUtils";

export default {
  name: "EnrollForm.vue",
  mixins: [enrollmentModal.modalMixin],
  components: { Scrollbar },
  data: () => ({
    selectedPlanId: "",
  }),
  computed: {
    ...mapState("employees", ["employeeId"]),
    ...mapState("employees", ["item", "enrollments"]),
    ...mapState("enrollments", ["plansLoading", "plans"]),
    plansForEmployee() {
      const enrolledPlanIds = this.enrollments.items.map(x => x.plan_id);
      return this.plans.filter(x => !enrolledPlanIds.includes(x.id));
    },
  },
  methods: {
    ...mapActions("enrollments", ["enroll"]),
    submitAndClose() {
      const $vm = this;
      this.enroll({
        user_id: this.employeeId,
        plan_id: this.selectedPlanId,
      })
        .then(() => {
          this.$CpEvent.$emit("snackAlert", {
            message: "Enrolled",
            color: "success",
          });
          this.closeModal({ success: true });
          this.selectedPlanId = "";
        })
        .catch(err => {
          const errors = Object.values(mapErrors(err.response));

          this.$CpEvent.$emit("snackAlert", {
            message: errors.join(" "),
            color: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enroll.v-list::v-deep {
  border: 1px solid #afb4c5;
  border-radius: 4px;
  height: calc(100vh - 400px);
  padding: 0;

  > .ps {
    height: 100%;
    overflow-x: hidden !important;
  }

  .v-list-item {
    padding: 0;
    min-height: auto;
    align-items: stretch;

    & + .v-list-item {
      border-top: 1px solid #afb4c5;
    }

    &__action {
      margin: 0;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  .v-input,
  .v-input__slot {
    margin: 0;
  }

  .v-radio {
    margin: 0;
    padding-left: 1rem;
    width: calc(100% - 2.5rem);
    .v-label {
      padding: 1rem;
      flex-grow: 1;
    }
  }
}
</style>
