<template>
  <div v-if="tasks.length" style="width: 100%">
    <div class="text-caption">Statuses</div>
    <div class="bar-container">
      <cp-explain
        v-for="status in statuses"
        :key="status.id"
        root-slot
        nudge-bottom="20"
      >
        <template #activator="{on}">
          <div
            :key="status.id"
            v-on="on"
            @click="$emit('filter', status.filterValue)"
            :style="status.style"
            class="status-bar"
            :class="status.status.chipProps.class"
          >
            {{ status.tasks.length }}
          </div>
        </template>
        <v-card class="text-center">
          <div class="px-2">{{ status.status.title }}</div>
          <v-divider />
          <div class="text-caption px-2">click to filter</div>
        </v-card>
      </cp-explain>
    </div>
  </div>
</template>

<script>
import pluralize from "pluralize";
import {
  progressStatusesOrder,
  complianceStatusesOrder,
} from "@/store/modules/tasks";

export default {
  name: "TasksComplianceBar",
  props: { tasks: { type: Array, default: () => [] } },
  computed: {
    statuses() {
      const statuses = [];

      for (const status of progressStatusesOrder) {
        if (status.id === 4) continue;
        const tasks = this.tasks.filter(
          x => x.user_task_progress_status_id === status.id
        );
        if (tasks.length) {
          statuses.push({
            id: `progress-id-${status.id}`,
            filterValue: { progressStatus: status.id },
            status,
            tasks,
            style: {
              width: `${(tasks.length / this.tasks.length) * 100}%`,
              backgroundColor: status.color,
              color: status.chipProps.textColor,
            },
          });
        }
      }

      for (const status of complianceStatusesOrder) {
        const tasks = this.tasks.filter(
          x => x.user_task_compliance_status_id === status.id
        );
        if (tasks.length) {
          statuses.push({
            id: `compliance-id-${status.id}`,
            filterValue: { complianceStatus: status.id },
            status,
            tasks,
            style: {
              width: `${(tasks.length / this.tasks.length) * 100}%`,
              backgroundColor: status.color,
              color: status.chipProps.textColor,
            },
          });
        }
      }

      return statuses;
    },
  },
  methods: { pluralize },
};
</script>

<style lang="scss" scoped>
.bar-container {
  width: 100%;
  height: 12px;
  display: flex;

  .status-bar {
    height: 100%;
    cursor: pointer;
    text-align: center;
    font-size: 9px;
    line-height: 12px;
  }
}
</style>
