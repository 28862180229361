<template>
  <v-card dense>
    <v-card-title class="grey--text text--darken-2">{{
      item.title
    }}</v-card-title>
    <v-card-text>
      <p>
        <strong>Description:</strong>
        <div v-html="item.description" class="displayHTML"></div>
      </p>
      <p>
        <strong>Schedule:</strong>
        {{ item.schedule || "N/A" }}
      </p>
      <p>
        <strong>Plans Using This Task:</strong>
        <span v-if="item.plans && item.plans.length">
          {{ item.plans.map(x => x.title).join(", ") }}
        </span>
        <span v-else>
          N/A
        </span>
      </p>
      <p>
        <strong>Assigned To:</strong>
        {{ item.assigned_to || "N/A" }}
      </p>
      <p class="mb-0">
        <strong>Attachments:</strong>
        <span v-if="item.files && item.files.length">
          <cp-file-chip
            v-for="file in item.files"
            :key="file.id"
            :name="file.name"
            :url="file.url"
          />
        </span>
        <span v-else>N/A</span>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { genericConstants } from "@/constants/genericConstants";
export default {
  name: "TaskDetailCard",
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      constants: genericConstants,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: break-word;
}
.v-list {
  padding-top: 0;
  padding-bottom: 0;

  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid #afb4c5;
    }

    &__title {
      font-size: 1rem;
      font-weight: 700;
      color: #52555e;
    }

    p {
      color: #52555e;
      margin-top: 3px;

      span {
        color: #767b87;
      }
    }
  }
}

.empty {
  padding-top: 1.5rem;
  text-align: center;

  & > .v-icon {
    width: 3.5rem;
    height: 3.5rem;
    background: rgba(30, 100, 220, 0.1);
    border: 1px solid rgba(30, 100, 220, 0.3);
    border-radius: 50%;
    color: #1e64dc;
    font-size: 2rem;
    margin-bottom: 0.375rem;
  }

  h4,
  p {
    color: #767b87;
    margin: 0.5rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
  }

  p {
    font-size: 14px;
  }

  .v-btn {
    .v-icon {
      margin-right: 4px;
    }
  }
}
</style>
