export const genericConstants = Object.freeze({
  // Dashboard
  DASHBOARD: "Dashboard",
  EMPLOYEES: "Employees",
  COMPLIANCE_SCORE: "Compliance Score",
  REGIONS: "Regions",
  LOCATIONS: "Locations",
  POSITIONS: "Positions",
  TASK_COMPLIANCE: "Task Compliance",
  ONBOARDING: "Onboarding",
  OFFBOARDING: "Offboarding",
  COMPLETED: "Completed",
  PENDING: "Pending",
  CANT_COMPLETE: "Can’t Complete",
  PAST_DUE: "Past Due",
  ABANDONED: "Abandoned",

  // Plans
  PLANS: "Plans",
  ACTIVE_PLANS: "Active Plans",
  ARCHIVED_PLANS: "Archived Plans",
  EDIT_PLAN: "Edit Plan",
  PLAN: "Plan",
  PLAN_POSITIONS_HEADING: "Positions for this plan is used for:",
  PLAN_EMPLOYEES_HEADING: "Employees this plan:",

  // Tasks
  TASKS: "Tasks",
  ACTIVE_TASKS: "Active Tasks",
  ARCHIVED_TASKS: "Archived Tasks",
  EDIT_TASK: "Edit Task",
  TASK: "Task",
  SCHEDULE: "Schedule:",
  TASKS_OF_PLANS: "Plan using this task:",
  ASSIGN_TO: "Assign To:",
  ATTACHMENTS: "Attachments:",

  // Assignments
  ASSIGNMENT_GROUPS: "Assignments",
  ACTIVE_ASSIGNMENT_GROUPS: "Active Assignments",
  ARCHIVED_ASSIGNMENT_GROUPS: "Archived Assignments",
  ACTIVE_GROUPS: "Active Assignments",
  ARCHIVED_GROUPS: "Archived Assignments",
  EDIT_GROUP: "Edit Assignment",
  GROUP: "Group",
  EMPLOYEE_N0: "Employee #",
  ASSIGNMENTS: "Assignments",

  // Reports
  REPORTS: "Reports",
  DUE_DATE: "Due Date",
  STATUS: "Status",
  NOTES: "Notes",
  DUE: "Due",

  //Common Constants
  TYPE: "Type",
  MODIFIED: "Modified",
  RESTORE: "Restore",
  DUPLICATE: "Duplicate",
  DELETE: "Delete",
  REMOVE: "Remove",
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  INACTIVE: "Inactive",
  TAB_ONE_CONTENT: "Tab 1 Content",
  TAB_SECOND_CONTENT: "Tab 2 Content",
  ACTIVE_SLUG: "active",
  ARCHIVED_SLUG: "archived",
  ACTIVE_TAB_ICON: "check_circle",
  ARCHIVED_TAB_ICON: "cancel",
  DESCRIPTION_HEADING: "Description:",
});
