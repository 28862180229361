import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"modal-content-card"},[_c(VCardText,[_c('div',{staticClass:"plan-details-wrap"},[_c('div',{staticClass:"pd-sec-head"},[_c('h2',[_c(VIcon,[_vm._v("mdi-clipboard-check")]),_vm._v(" "+_vm._s(_vm.item.title)+" "),(_vm.item.is_core)?_c('core-indicator',{staticClass:"ml-4"},[_c('div',[_c('h3',[_vm._v("This is a core plan.")]),_vm._v(" It applies to all locations and positions. ")])]):_vm._e()],1)]),_c('div',{staticClass:"pd-info"},[_c('p',[_c('strong',[_vm._v("Plan Type:")]),_vm._v(" "+_vm._s(_vm.item.plan_type.label)+" ")]),_c('p',[_c('strong',[_vm._v("Positions:")]),_vm._v(" "+_vm._s(_vm.item.positions_for_plan.map(function (data) { return data.name; }).join(", "))+" ")]),_c('p',[_c('strong',[_vm._v("Description:")])]),_c('div',{staticClass:"displayHTML",domProps:{"innerHTML":_vm._s(_vm.item.description)}}),_c('p')]),_c('div',{staticClass:"pd-tasks-list-wrap"},[_c(VList,{staticClass:"tasks-list-wrap"},_vm._l((_vm.item.tasks),function(task){return _c(VListItem,{key:task.id},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"d-flex align-center"},[_c(VIcon,{attrs:{"color":"#008A09"}},[_vm._v("mdi-check-decagram")]),_c('span',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(task.title)+" ")]),(task.is_core)?_c('core-indicator',{attrs:{"size":"24"}},[_c('div',[_c('h3',[_vm._v("This is a core task.")]),_vm._v(" It cannot be removed. ")])]):_vm._e(),(
                    !task.assignment_type ||
                      (task.assignment_type === 'assignment_group' &&
                        !task.assignment_group_id)
                  )?_c('cp-explain',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"ml-3",attrs:{"color":"error"}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_vm._v(" Missing Assignment! ")]):_vm._e()],1),_c(VListItemSubtitle,[_c(VIcon,{attrs:{"color":"#8B5DCB"}},[_vm._v("mdi-calendar-clock")]),_c('span',{staticClass:"mx-4"},[_vm._v(_vm._s(task.schedule))])],1)],1)],1)}),1),(!_vm.item.tasks.length)?_c('no-data-screen',{attrs:{"text":"This template task list is empty","icon":"mdi-check-decagram","sub-text":"You will need to create tasks for this plan","show-button":false}}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }