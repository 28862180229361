<template>
  <cp-fullscreen-module-modal :modalConf="modalConf">
    <template #title>{{ editMode ? "Edit" : "Create" }} Assignment</template>
    <v-form ref="form" autocomplete="off">
      <v-card class="form-controls-sec-wrap modal-content-card">
        <v-card-text>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>1</v-chip>
              <h6>Name Assignment</h6>
              <cp-explain>
                <p>
                  You'll use this when you assign tasks.<br />
                  We suggest...
                </p>
                <p>
                  For a regional assignment:<br />
                  <strong>Atlanta - Maintenance Leaders</strong>
                </p>
                <p>
                  For a company-wide assignment:<br />
                  <strong>HR - legal</strong> or
                  <strong>HR - compliance</strong>
                </p>
              </cp-explain>

              <template v-if="editMode">
                <v-spacer />
                <v-chip v-if="isArchived" text class="mr-3">
                  <v-icon class="mr-1">mdi-archive-outline</v-icon>
                  Archived
                </v-chip>

                <settings-menu :items="actions" />
              </template>
            </div>
            <v-text-field
              v-bind="fields.name.attrs"
              @input="fields.name.input"
              dense
              outlined
              label="Give your assignment a name"
              placeholder="Give your assignment a name"
            ></v-text-field>
            <div class="form-controls-sec"></div>

            <div class="fcs-head">
              <v-chip>2</v-chip>
              <h6>Add Employees To This Group</h6>
              <cp-explain>
                <p>
                  <strong>Assignment</strong> - get notified before the due
                  date<br />
                  <strong>Escalation</strong> - only gets notified after the due
                  date is missed<br />
                  We suggest...
                </p>
                <p>
                  2-5 <strong>assignment</strong> employees.<br />
                  They will be most likley to complete the task.
                </p>
                <p>
                  1-3 <strong>escalation</strong> employees.<br />
                  They will be ultimatley responsible to ensure the task's
                  completion.
                </p>
              </cp-explain>
            </div>

            <div
              v-if="showErrors.assignment_ids"
              class="v-messages theme--light error--text"
            >
              <div class="v-messages__message">
                You must select assignment employees
              </div>
            </div>

            <v-btn-toggle
              mandatory
              dense
              color="primary"
              group
              class="btn-toggle-full mb-14px"
              v-model="currentMode"
            >
              <v-btn value="assignment">
                Assignment
                {{
                  this.assignmentEmployees.length
                    ? `(${this.assignmentEmployees.length})`
                    : ""
                }}
              </v-btn>
              <v-btn value="escalation">
                Escalation
                {{
                  this.escalationEmployees.length
                    ? `(${this.escalationEmployees.length})`
                    : ""
                }}
              </v-btn>
            </v-btn-toggle>

            <v-row>
              <v-col>
                <v-select
                  :items="regionsOptions"
                  :value="emlpoyeesFilters.regions"
                  @focus="() => fetchRegions()"
                  @change="regions => updateEmployeesFilter({ regions })"
                  item-text="label"
                  clearable
                  multiple
                  label="Regions"
                  dense
                  outlined
                />
              </v-col>
              <v-col>
                <v-select
                  :items="positionsOptions"
                  :value="emlpoyeesFilters.positions"
                  @focus="() => fetchPositions()"
                  @change="positions => updateEmployeesFilter({ positions })"
                  :loading="positionsSearching"
                  item-text="label"
                  clearable
                  multiple
                  label="Positions"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <div class="search-assign-employees">
              <div class="fcs-head">
                <h6>
                  Search For
                  {{ capitalize(mode.current.listName) }}
                  Employee(s):
                </h6>
                <cp-explain>
                  <p>
                    Search for employees by name.<br />
                    To narrow your search, select
                    <strong>Locations</strong> and/or
                    <strong>Positions</strong> above.
                  </p>
                </cp-explain>
              </div>

              <v-autocomplete
                v-model="selectedEmployee"
                :search-input.sync="employeeSearch"
                :items="filterEmployeeOptions.list"
                :loading="employeesSearching"
                :no-data-text="employeeSelectNoDataText"
                @update:search-input="
                  q_text => updateEmployeesFilter({ q_text })
                "
                @input="addEmployee"
                item-value="data"
                placeholder="Type to search..."
                background-color="white"
                prepend-inner-icon="mdi-magnify"
                outlined
                dense
                class="mb-3"
                :menu-props="{ maxHeight: 400 }"
                :filter="x => x"
              >
                <template #item="{ item, attrs, on }">
                  <employee-list-item
                    :employee="item"
                    show-disclaimer
                    v-on="on"
                    v-bind="attrs"
                  />
                </template>
              </v-autocomplete>
              <div class="fcs-head">
                <h6 class="font-weight-regular">
                  {{ mode.current.count }}
                  Employees in this
                  {{ capitalize(mode.current.listName) }}
                  Group
                </h6>
              </div>
              <div
                class="empty-block empty-block-border"
                v-if="mode.current.count === 0"
              >
                <div>
                  <v-icon class="icon-large">mdi-account-plus</v-icon>
                  <h4>
                    Your
                    {{ capitalize(mode.current.listName) }}
                    Group is Empty
                  </h4>
                  <p>Looks like you haven’t added employees to your list yet</p>
                </div>
              </div>
              <div v-else>
                <v-list class="employees-list">
                  <employee-list-item
                    v-for="employee in $data[mode.current.listKey]"
                    :key="employee.user_id"
                    :employee="employee"
                    @delete="removeEmployeeLocally(employee.user_id)"
                    show-delete
                  />
                </v-list>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <error-list :errors="apiErrors" :already-displayed="['name']" />

          <v-btn
            @click="submit"
            :disabled="!valid"
            :loading="submitting"
            color="primary"
            >{{ editMode ? "Save Changes" : "Create Group" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </cp-fullscreen-module-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import pluralize from "pluralize";
import { capitalize } from "@cp/utils/stringUtils";
import { partition } from "@cp/utils/arrayUtils";
import { onCpEvent } from "@cp/mixins";
import { itemFormModal } from "@/store/modules/assignments";

export default {
  name: "AssignmentForm",
  mixins: [
    itemFormModal.mixin,
    itemFormModal.modalMixin,
    onCpEvent(itemFormModal.keys.modalOpenedEvent, function() {
      this.fetchRegions();
      this.fetchPositions();
      this.reset();
      this.afterLoad();
    }),
    onCpEvent(itemFormModal.keys.modalClosedEvent, function() {
      this.reset();
    }),
    onCpEvent(itemFormModal.keys.fetchComplete, function() {
      this.afterLoad();
    }),
  ],
  data: () => ({
    // form data
    assignmentEmployees: [],
    escalationEmployees: [],

    // ui state
    currentMode: "assignment",
    employeeSearch: "",
    selectedEmployee: [],
  }),
  computed: {
    ...mapState("assignments", ["apiErrors"]),
    ...mapState("assignments/employeesRegions", {
      regionsOptions: "items",
    }),
    ...mapState("assignments/employeesPositions", {
      positionsOptions: "items",
      positionsSearching: "loading",
    }),
    ...mapState("assignments", {
      employeesOptions: "employees",
      employeesSearching: "employeesLoading",
      emlpoyeesFilters: "employeesFilterParams",
    }),
    isArchived() {
      return this.item.assignment_group_status_id === 2;
    },
    actions() {
      const actions = [];
      const employeeCount = this.item.employees_on_task;

      if (this.isArchived)
        actions.push({
          icon: "archive-off-outline",
          text: "Restore Assignment",
          click: () =>
            this.confirm({
              verb: "restore",
              noun: "assignment",
              appendText:
                "This will activate it, which will start sending emails to the assignees.",
              action: "assignments/restoreAssignment",
            }),
        });
      else
        actions.push(
          {
            icon: "archive-outline",
            text: "Archive Assignment",
            click: () =>
              this.confirm({
                verb: "archive",
                noun: "assignment",
                appendText:
                  "This will only deactivate it, and you can reactivate it at any time.",
                alertText: employeeCount
                  ? `This assignment is currently in use with ${employeeCount} employees.`
                  : "This assignment is not currently being used by any employees.",
                alertProps: employeeCount
                  ? { icon: "mdi-account-alert" }
                  : { icon: "mdi-check", color: "success" },
                action: "assignments/archiveAssignment",
              }),
          },
          {
            icon: "content-copy",
            text: "Duplicate Assignment",
            click: () => this.duplicateAssignment(),
          }
        );

      if (this.item["is_deletable?"])
        actions.push({
          icon: "trash-can-outline",
          text: "Delete Assignment",
          click: () =>
            this.confirm({
              verb: "delete",
              noun: "assignment",
              alertText: "This will delete the assignment permanently!",
              action: "assignments/deleteAssignment",
            }),
          props: {
            class: "red--text text--darken-2",
          },
        });

      return actions;
    },
    editMode() {
      return !!this.item.id;
    },
    mode() {
      const assignment = {
        listKey: "assignmentEmployees",
        listName: "assignment",
        count: this.assignmentEmployees.length,
        dataKey: "assignment_ids",
      };
      const escalation = {
        listKey: "escalationEmployees",
        listName: "escalation",
        count: this.escalationEmployees.length,
        dataKey: "escalation_ids",
      };
      const isAss = this.currentMode === "assignment";
      return {
        current: isAss ? assignment : escalation,
        other: !isAss ? assignment : escalation,
      };
    },
    filterEmployeeOptions() {
      const alreadyInOtherList = this[this.mode.other.listKey].map(
        x => x.user_id
      );
      const alreadyInCurrentList = this[this.mode.current.listKey].map(
        x => x.user_id
      );
      const employees = this.employeesOptions.map(x => {
        // add text if the user is already in the other list
        if (alreadyInOtherList.includes(x.user_id))
          x.disclaimer = ` (remove from ${this.mode.other.listName} list)`;
        return x;
      });
      const [removed, list] = partition(employees, x =>
        alreadyInCurrentList.includes(x.user_id)
      );

      return { removed, list };
    },
    employeeSelectNoDataText() {
      const listCount = this.filterEmployeeOptions.list.length;
      const removedCount = this.filterEmployeeOptions.removed.length;
      if (!listCount && !removedCount) return "No data available";
      return removedCount
        ? `${pluralize("Employee", removedCount, true)} ${
            removedCount == 1 ? "is" : "are"
          } already in ${this.mode.current.listName} list`
        : "";
    },
  },
  methods: {
    capitalize,
    ...mapActions("assignments", [
      "fetchEmployees",
      "duplicateAssignment",
      "updateEmployeesFilter",
      "resetEmployeesFilters",
    ]),
    ...mapActions("assignments/employeesRegions", {
      fetchRegions: "fetchItems",
    }),
    ...mapActions("assignments/employeesPositions", {
      fetchPositions: "fetchItems",
    }),
    ...mapActions("confirm", ["confirm"]),
    ...mapMutations("assignments", ["setItemTouched"]),
    onOpened() {
      this.fetchPositions();
      this.fetchRegions();
    },
    updateEmployeeFormData() {
      this.fields.assignment_ids.input(
        this.assignmentEmployees.map(x => x.user_id)
      );
      this.fields.escalation_ids.input(
        this.escalationEmployees.map(x => x.user_id)
      );
    },
    reset() {
      // ui state
      this.currentMode = "assignment";
      this.employeeSearch = "";
      this.selectedEmployee = [];
      this.resetEmployeesFilters();
      this.updateEmployeeFormData();
    },
    afterLoad() {
      this.assignmentEmployees = this.item.assignees || [];
      this.escalationEmployees = this.item.escalations || [];
    },
    submit() {
      const reqConf = this.editMode ? { method: "PUT", id: this.item.id } : {};
      this.itemSubmit(reqConf).then(({ data }) =>
        this.closeModal({
          success: true,
          id: data.id,
        })
      );
    },
    addEmployee() {
      this[this.mode.current.listKey].push(this.selectedEmployee);
      const indexInOtherList = this[this.mode.other.listKey].findIndex(
        x => x.user_id === this.selectedEmployee.user_id
      );
      if (indexInOtherList >= 0) {
        this[this.mode.other.listKey].splice(indexInOtherList, 1);
        this.setItemTouched(this.mode.other.dataKey);
      }
      this.employeeSearch = "";
      this.selectedEmployee = {};
      this.updateEmployeeFormData();
      this.setItemTouched(this.mode.current.dataKey);
    },
    removeEmployeeLocally(id) {
      this[this.mode.current.listKey] = this[this.mode.current.listKey].filter(
        x => x.user_id != id
      );
      this.updateEmployeeFormData();
      this.setItemTouched(this.mode.current.dataKey);
    },
  },
};
</script>

<style scoped>
.btn-toggle-full {
  width: 100%;
}

.v-progress-linear.employeeSearchProgress {
  top: calc(100% - 3px);
}

.empty-block {
  background: #fff;
  padding: 3rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-listing & {
    min-height: 40vh;
  }

  &.empty-block-border {
    border: 1px solid #afb4c5;
    border-radius: 4px;
  }

  .v-icon.icon-large {
    width: 5.75rem;
    height: 5.75rem;
    border-radius: 50%;
    background: rgba(30, 100, 220, 0.1);
    border: 1px solid rgba(30, 100, 220, 0.3);
    border-radius: 50%;
    color: #1e64dc;
    font-size: 3.5rem;
    margin-bottom: 0.375rem;
  }

  h4,
  p {
    color: #767b87;
    margin: 0.5rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
  }

  p {
    font-size: 14px;
  }
}
</style>
