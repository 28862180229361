<template>
  <div class="ml-auto">
    <div class="d-flex align-center">
      <div class="dashboard-topbar-toggles">
        <p>Active</p>
        <v-switch
          hide-details
          v-model="activeSwitch"
          inset
          label="All"
        ></v-switch>
        <v-divider vertical></v-divider>
        <v-switch
          hide-details
          v-model="expectationsSwitch"
          inset
          label="Exceptions"
        ></v-switch>
      </div>
      <v-btn @click.stop="drawer = !drawer" outlined color="primary">
        <v-icon small>mdi-filter</v-icon>
        Filters
      </v-btn>
    </div>
    <v-dialog
      v-model="drawer"
      content-class="right-drawer-dialog"
      width="360"
      transition="slide-x-reverse-transition"
      hide-overlay
    >
      <v-card tile>
        <v-card-title>
          Filters
          <v-btn
            text
            color="primary"
            class="ml-auto"
            @click.stop="drawer = !drawer"
          >
            <v-icon left>mdi-close</v-icon>
            Close
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="planTypeSelected"
            :items="planTypeList"
            label="Plan Type"
            outlined
            dense
            clearable
          ></v-select>
          <v-select
            v-model="regionsListValue"
            :items="regions"
            outlined
            dense
            label="Regions"
            multiple
            :menu-props="{ contentClass: 'filter-dd-menu' }"
            clearable
          >
          </v-select>
          <v-autocomplete
            v-model="locationsListValue"
            :items="locations"
            outlined
            dense
            label="Locations"
            multiple
            :menu-props="{ contentClass: 'filter-dd-menu' }"
            clearable
            ref="locations"
            @change="$refs.locations.internalSearch = ''"
          />
          <v-select
            v-model="selectedAssignedPositions"
            :items="positions"
            label="Positions"
            persistent-hint
            dense
            outlined
            multiple
            clearable
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggle"
                slot="prepend-item"
              >
                <v-list-item-action>
                  <v-icon
                    :color="
                      selectedAssignedPositions.length > 0
                        ? 'indigo darken-4'
                        : ''
                    "
                  >
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Select All
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>

          <v-select
            v-model="selectedReportingGroups"
            :items="reportingGroups"
            label="Reporting Groups"
            persistent-hint
            dense
            outlined
            multiple
            clearable
          />

          <div class="text-right">
            <v-btn text color="primary" @click="resetFilters()"
              ><v-icon left>mdi-undo-variant</v-icon>Reset</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: false,
    activeSwitch: true,
    expectationsSwitch: false,
    planTypeList: ["Onboarding", "Offboarding"],
    selectedAssignedPositions: [],
    selectedReportingGroups: [],
    regionsListValue: [],
    planTypeSelected: "",
    locationsListValue: [],
    options: {
      active_toggle: true,
      exceptions_toggle: false,
      filters: { plan_types: [], regions: [], locations: [], positions: [] },
    },
  }),
  computed: {
    ...mapState("dashboard", [
      "loading",
      "employees",
      "plans",
      "tasks",
      "items",
      "regions",
      "locations",
      "reportingGroups",
    ]),
    ...mapState("assignments", [
      "loading",
      "item",
      "meta",
      "positions",
      "regionsSearching",
      "employeesOptions",
      "employeesSearching",
      "employeesData",
      "employeesPayload",
    ]),
    allAssignedPositions() {
      return this.selectedAssignedPositions.length === this.positions.length;
    },
    selectSomeAssignedPositions() {
      return this.selectedAssignedPositions.length > 0;
    },
    icon() {
      if (this.allAssignedPositions) return "mdi-close-box";
      if (this.selectSomeAssignedPositions) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    $route(to, from) {
      this.activeSwitch = true;
      this.expectationsSwitch = false;
      this.resetFilters();
      this.drawer = false;
    },
    activeSwitch() {
      this.options.active_toggle = this.activeSwitch;

      this.setApiCall();
    },
    expectationsSwitch() {
      this.options.exceptions_toggle = this.expectationsSwitch;
      this.setApiCall();
    },
    planTypeSelected() {
      this.options.filters.plan_types =
        this.planTypeSelected === "Onboarding"
          ? [1]
          : this.planTypeSelected === "Offboarding"
          ? [2]
          : [];
      this.setApiCall();
    },
    regionsListValue() {
      this.options.filters.regions = this.regionsListValue;
      this.setApiCall();
    },
    locationsListValue() {
      this.options.filters.locations = this.locationsListValue;
      this.setApiCall();
    },
    selectedAssignedPositions() {
      this.options.filters.positions = this.selectedAssignedPositions;
      this.setApiCall();
    },
    // selectedReportingGroups() {
    //   this.options.filters.reporting_groups = this.selectedReportingGroups;
    //   this.setApiCall();
    // }
  },
  methods: {
    ...mapActions("assignments", [
      "fetchAssignmentGroup",
      "fetchGroups",
      "fetchPositions",
    ]),
    ...mapActions("dashboard", [
      "fetchDashboardSummary",
      "fetchRegions",
      "fetchLocations",
      "fetchReportingGroups",
    ]),
    toggle() {
      this.$nextTick(() => {
        if (this.allAssignedPositions) {
          this.selectedAssignedPositions = [];
        } else {
          this.selectedAssignedPositions = this.positions
            .filter(x => !x.hasOwnProperty("header"))
            .map(x => x.value);
        }
      });
    },
    async setApiCall() {
      // TODO - move this logic to Dashboard.vue WTF
      if (this.$route.fullPath === "/") {
        await this.fetchDashboardSummary(this.options);
      } else {
        // OMFG move this to dashboard.js and use ApiDataMixin.updateParams
        await this.$CpEvent.$emit("headerFiltersChanged", this.options);
      }
    },
    resetFilters() {
      this.planTypeSelected = "";
      this.regionsListValue = [];
      this.locationsListValue = [];
      this.selectedAssignedPositions = [];
      this.selectedReportingGroups = [];
      this.options = {
        active_toggle: this.activeSwitch,
        exceptions_toggle: this.expectationsSwitch,
        filters: { plan_types: [], regions: [], locations: [], positions: [] },
      };
      this.setApiCall();
    },
  },
  mounted() {
    this.setApiCall();
    this.fetchPositions();
    this.fetchRegions();
    this.fetchLocations();
    this.fetchReportingGroups();
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  align-items: stretch;
  justify-content: flex-end;

  &::v-deep .v-dialog.right-drawer-dialog {
    margin-top: 54px;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 0;
    max-height: none;
  }

  .v-card {
    height: 100%;
  }
}
</style>
