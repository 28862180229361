<template>
  <cp-fullscreen-module-modal :modalConf="modalConf" @opened="onOpen">
    <template #title>{{ editMode ? "Edit" : "Create a" }} Plan</template>

    <div v-if="itemLoading">Loading...</div>
    <v-card v-else class="form-controls-sec-wrap modal-content-card">
      <v-form autocomplete="off">
        <v-card-text>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>1</v-chip>
              <h6>Plan Type</h6>
            </div>
            <div v-if="editMode" class="pt-radio-group">
              <span>
                <strong>This plan's type:</strong>
                {{ selectedType.label }}
              </span>
            </div>
            <div v-else class="pt-radio-group">
              <v-radio-group
                row
                mandatory
                v-bind="fields.plan_type_id.attrs"
                @change="fields.plan_type_id.input"
              >
                <v-radio label="Onboarding" :value="1"></v-radio>
                <v-radio label="Offboarding" :value="2"></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>2</v-chip>
              <h6>Plan Title</h6>
            </div>
            <v-text-field
              v-bind="fields.title.attrs"
              @input="fields.title.input"
              data-lpignore="true"
              dense
              outlined
              persistent-placeholder
              persistent-hint
            ></v-text-field>
            <v-textarea
              v-bind="fields.description.attrs"
              @input="fields.description.input"
              outlined
              persistent-placeholder
              hide-details
            ></v-textarea>
          </div>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>3</v-chip>
              <h6>Positions</h6>
              <v-checkbox
                :input-value="allPositions"
                :true-value="true"
                @change="fields.all_positions.input"
                label="Apply to all positions"
              />
            </div>
            <v-expand-transition>
              <v-select
                v-show="!allPositions"
                v-bind="fields.position_ids.attrs"
                @input="fields.position_ids.input"
                :items="positionsWithHeaders"
                label="Assigned Positions"
                persistent-hint
                dense
                outlined
                multiple
              />
            </v-expand-transition>
          </div>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>4</v-chip>
              <h6>Locations</h6>
              <v-checkbox
                :input-value="allLocations"
                @change="fields.all_locations.input"
                label="Apply to all locations"
              />
            </div>
            <v-expand-transition>
              <div id="all-locations-menu" v-show="!allLocations">
                <v-menu
                  allow-overflow
                  max-height="300"
                  top
                  open-on-focus
                  attach="#all-locations-menu"
                  :close-on-content-click="false"
                >
                  <template #activator="scope">
                    <v-text-field
                      dense
                      outlined
                      label="Locations"
                      :value="regionSelectionText"
                      persistent-hint
                      @focus="$event.target.blur()"
                      v-on="scope.on"
                      :errorMessages="fields.location_ids.attrs.errorMessages"
                    />
                  </template>

                  <v-card class="locations-tree-view">
                    <v-treeview
                      :value="fields.location_ids.attrs.value"
                      @input="fields.location_ids.input"
                      :items="regionTreeItems"
                      selectable
                      @update:open="() => {}"
                      dense
                      open-on-click
                      activatable
                    />
                  </v-card>
                </v-menu>
              </div>
            </v-expand-transition>
          </div>
          <cp-unhandled-errors :formConf="formConf" />
          <cp-form-modal-success-or-buttons
            :modalConf="modalConf"
            :formConf="formConf"
            :buttonConf="{
              createDraft: {
                action: 'saveDraft',
                text: 'Create Draft',
                icon: 'mdi-email-off-outline',
                props: { color: 'primary' },
              },
            }"
            :buttons="[editMode ? 'update' : 'createDraft']"
            @submit="submit"
            @saveDraft="saveAsDraft"
            noun="Plan"
            :success-message="`Plan ${editMode ? 'Saved' : 'Created'}!`"
          />
        </v-card-text>
      </v-form>
    </v-card>
  </cp-fullscreen-module-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { itemFormModal, planTypeObj } from "@/store/modules/plans";

export default {
  name: "PlanForm",
  mixins: [itemFormModal.mixin, itemFormModal.modalMixin],
  computed: {
    ...mapState("plans", ["apiErrors", "item", "itemLoading"]),
    ...mapGetters("plans", ["regionTreeItems", "positionsWithHeaders"]),
    editMode() {
      return !!this.item.id;
    },
    selectedType() {
      return planTypeObj[this.itemFormData.plan_type_id];
    },
    regionSelectionText() {
      const count = this.itemFormData.location_ids.length;
      return count ? `${count} locations selected` : "";
    },
    allLocations() {
      return this.itemFormData.all_locations;
    },
    allPositions() {
      return this.itemFormData.all_positions;
    },
  },

  methods: {
    ...mapActions("plans", {
      fetchPlans: "fetch",
    }),
    ...mapActions("plans", [
      "postPlan",
      "editPlan",
      "openPlanDetailModal",
      "post",
      "put",
      "resetApiErrors",
    ]),
    ...mapActions("plans", [
      "fetchRegions",
      "openItemDetailModal",
      "fetchPositions",
    ]),

    onOpen({ promise }) {
      this.fetchRegions();
      this.fetchPositions();
    },

    async submit() {
      const reqConf = this.editMode ? { method: "PUT", id: this.item.id } : {};
      const response = await this.itemSubmit(reqConf);
      if (response.data)
        this.closeModal({
          created: !this.editMode,
          success: true,
          id: response.data.id,
        });
    },

    saveAsDraft() {
      this.setItemValue({ plan_status_id: 3 });
      this.submit();
    },
  },

  watch: {
    allLocations(newValue) {
      if (newValue) this.setItemValue({ location_ids: [] });
    },
    allPositions(newValue) {
      if (newValue) this.setItemValue({ position_ids: [] });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox.v-input--is-focused {
  .v-input__slot,
  .v-icon,
  .v-label {
    color: $primary;
  }
}
</style>
