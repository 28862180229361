import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,_vm._b({attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cog")])],1)]},{"on":on,"attrs":attrs})]}}],null,true)},'v-menu',_vm.$attrs,false),[_c(VCard,[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function(item,i){return _c(VListItem,_vm._b({key:("menu-item-" + i),on:{"click":item.click}},'v-list-item',item.props,false),[(item.icon)?_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-"+_vm._s(item.icon))])],1):_vm._e(),_c(VListItemTitle,[_vm._v(_vm._s(item.text))]),(item.explanation)?_c(VListItemAction,[_c('cp-explain',[_c('span',{domProps:{"innerHTML":_vm._s(item.explanation)}})])],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }