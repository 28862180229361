import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.tabSlug === 'active')?_c('div',[_c(VListItem,{on:{"click":function($event){return _vm.copyItem(_vm.item.id)}}},[_c(VListItemTitle,[_vm._v("Duplicate "+_vm._s(_vm.title))])],1)],1):_vm._e(),(_vm.tabSlug === 'archived')?_c('div',[_c(VListItem,{on:{"click":function($event){return _vm.restoreItem(_vm.item.id)}}},[_c(VListItemTitle,[_vm._v(" Restore "+_vm._s(_vm.title)+" ")])],1)],1):_vm._e(),(_vm.tabSlug === 'archived' && _vm.item.is_deletable)?_c('div',[_c(VListItem,{on:{"click":function($event){return _vm.removeItem(_vm.item.id)}}},[_c(VListItemTitle,[_vm._v(" Delete "+_vm._s(_vm.title)+" ")])],1)],1):(_vm.tabSlug === 'active')?_c('div',[_c(VDialog,{attrs:{"persistent":"","content-class":"dialog-confirm"},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c(VListItem,{on:{"click":function () {}}},[_c(VListItemTitle,_vm._g(_vm._b({},'v-list-item-title',scope.attrs,false),scope.on),[_vm._v(" Archive "+_vm._s(_vm.title)+" ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Confirm Deactivation ")]),_c(VCardText,[_c('p',[_vm._v(" Do you want to deactivate this "+_vm._s(_vm.title)+"? You can reactivate it later, If you wish. ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("This "+_vm._s(_vm.title)+" is currently in use with "+_vm._s(_vm.item.employees)+" employees")])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.archiveItem(_vm.item.id)}}},[_vm._v(" Deactivate ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }