<template>
  <div class="calculator-page">
    <cp-h1>Michael's "super duper fancy" calculator 🙄</cp-h1>
    <cp-card class="calc-card calc-card--options" dense :loading="calcPageStuff.awaitingResponse" flush-body>
      <template #title>
        Options
      </template>

      <template #actions>
        <v-btn v-if="showClearBtn" x-small rounded color="error" @click="reset"
          :disabled="calcPageStuff.awaitingResponse">
          Clear
        </v-btn>
      </template>

      <div class="px-3 mb-3">
        <cp-search v-model="calcPageStuff.q_text" @input="fetchData()" />
      </div>

      <v-expansion-panels>
        <v-expansion-panel v-if="calcPageStuff.meta.filters">
          <v-expansion-panel-header>
            Filters ({{ filterCount }})
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <!-- 
                v-if="filter != 'reporting_group'" -->
            <v-select v-for="filter in filterKeys" :key="filter" v-model="calcPageStuff.filters[filter]"
              :multiple="calcPageStuff.meta.filters[filter].multiple" dense
              :label="calcPageStuff.meta.filters[filter].label" :items="calcPageStuff.meta.filters[filter].values"
              @input="fetchData()" :disabled="calcPageStuff.awaitingResponse" />
            <!-- <v-select
                v-else
                :key="`${filter}-RG`"
                v-model="$store.state.groups.selected"
                multiple
                dense
                :label="calcPageStuff.meta.filters[filter].label"
                :items="calcPageStuff.meta.filters[filter].values"
                @input="fetchData()"
                :disabled="calcPageStuff.awaitingResponse"
              /> -->
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="calcPageStuff.meta.structures">
          <v-expansion-panel-header>
            Structures ({{ structureCount }})
          </v-expansion-panel-header>
          <v-expansion-panel-content eager>
            <v-select v-for="structure in structureKeys" :key="structure" v-model="calcPageStuff.structures[structure]"
              :multiple="calcPageStuff.meta.structures[structure].multiple" dense
              :label="calcPageStuff.meta.structures[structure].label"
              :items="calcPageStuff.meta.structures[structure].values" @input="fetchData()"
              :disabled="calcPageStuff.awaitingResponse" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </cp-card>

    <cp-card class="calc-card  calc-card--payload" dense :loading="calcPageStuff.awaitingResponse">
      <template #title>
        Payload
      </template>

      <vue-json-pretty :data="{
        filters: calcPageStuff.filters,
        structures: calcPageStuff.structures,
        q_text: calcPageStuff.q_text,
      }" />
    </cp-card>

    <cp-card class="calc-card  calc-card--response" :class="{ fetching: calcPageStuff.awaitingResponse }" dense
      :loading="calcPageStuff.awaitingResponse">
      <template #title>
        Response
      </template>

      <template #actions>
        <v-btn x-small rounded color="success" :disabled="calcPageStuff.awaitingResponse"
          @click="calcPageStuff.download()">
          <v-icon x-small class="mr-2">mdi-download</v-icon>Download
        </v-btn>
      </template>

      <vue-json-pretty :data="calcPageStuff.data" />
    </cp-card>
  </div>
</template>

<script>
import Omnirequest from "@/utils/omnirequest";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

function countKeysWithNonEmptyArrays(object) {
  if (!object) return 0;
  const keys = Object.keys(object);
  return keys.reduce((r, key) => {
    if (Array.isArray(object[key]) && object[key].length) r += 1;
    return r;
  }, 0);
}

export default {
  name: "Calculator",

  components: {
    VueJsonPretty,
  },

  data() {
    return {
      tab: 0,
      calcPageStuff: new Omnirequest(),
      fetchDelayTimeout: null,
      fetchDelayCountdown: null,
      fetchDelay: 3,
    };
  },

  computed: {
    filterCount() {
      return countKeysWithNonEmptyArrays(this.calcPageStuff.filters);
    },
    filterKeys() {
      return Object.keys(this.calcPageStuff.meta.filters);
    },
    structureCount() {
      return countKeysWithNonEmptyArrays(this.calcPageStuff.structures);
    },
    structureKeys() {
      return Object.keys(this.calcPageStuff.meta.structures);
    },
    showClearBtn() {
      return this.filterCount || this.structureCount;
    },
  },

  mounted() {
    this.fetchData(0);
  },

  methods: {
    reset() {
      this.calcPageStuff.reset();
      clearTimeout(this.fetchDelayTimeout);
      clearInterval(this.fetchDelayCountdown);
      this.fetchData(0);
    },

    fetchData({ delay = 1500, download = false } = {}) {
      this.fetchDelay = delay / 1000;

      clearTimeout(this.fetchDelayTimeout);
      clearInterval(this.fetchDelayCountdown);

      this.fetchDelayCountdown = setInterval(() => {
        this.fetchDelay -= 0.1;
        this.$CpEvent.$emit("snackAlert", {
          message: "Awaiting Selections..." + this.fetchDelay.toFixed(1),
          color: "grey",
          timeout: 0,
        });
      }, 100);

      this.fetchDelayTimeout = setTimeout(() => {
        clearInterval(this.fetchDelayCountdown);

        this.$CpEvent.$emit("snackAlert", {
          message: "Sending Request...",
          color: "primary",
          timeout: 5000,
        });

        this.calcPageStuff
          .fetch({
            download: download,
          })
          .then(data => {
            this.$CpEvent.$emit("snackAlert", {
              message: "Request Successful!",
              color: "success",
              timeout: 1500,
            });
          });
      }, delay);
    },
  },
};
</script>

<style lang="scss" scoped>
.calculator-page {
  font-size: 0;

  &::v-deep {
    .v-card__text {
      max-height: 80vh;
      overflow: auto;
      white-space: nowrap;
    }

    .v-card__actions {
      padding-top: 0;
      padding-bottom: 0;
    }

    .calc-card {
      display: inline-block;
      vertical-align: top;
      font-size: 1rem;

      &.calc-card--options {
        width: 250px;
      }

      &.calc-card--payload {
        margin-left: 10px;
        margin-right: 10px;
        width: 400px;
      }

      &.calc-card--response {
        width: calc(100% - 670px);
      }
    }

    .v-expansion-panel-header {
      padding: 12px;
    }

    .v-expansion-panel-content,
    .v-expansion-panel-content__wrap {
      padding: 6px;
    }
  }

  .fetching::v-deep .v-card__text {
    opacity: 0.1;
  }

  .tabs-header {
    margin-bottom: 12px;
  }
}
</style>
