import Vue from "vue";
import App from "./App.vue";
import { CarrotPatchV2, store, router, vuetify } from "@cp/install";
import "./styles/fiver-ui.scss";

Vue.use(CarrotPatchV2);

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify,
}).$mount("#app");
