<template>
  <cp-fullscreen-module-modal
    :modalConf="modalConf"
    @opened="onOpen"
    v-on="$listeners"
  >
    <template #title>{{ editMode ? "Edit" : "Create" }} Task</template>
    <v-form ref="form" :class="{ transitionsDisabled }" autocomplete="off">
      <v-card class="form-controls-sec-wrap modal-content-card">
        <v-card-text>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>1</v-chip>
              <h6>
                {{ editMode || addingToPlanMode ? "" : "Select " }}Task Type
              </h6>

              <v-spacer />

              <template v-if="editMode">
                <v-chip v-if="isArchived" text class="mr-3">
                  <v-icon class="mr-1">mdi-archive-outline</v-icon>
                  Archived
                </v-chip>

                <settings-menu :items="actions" />
              </template>
            </div>
            <div v-if="editMode || addingToPlanMode" class="pt-radio-group">
              <span>
                <strong>This task's type:</strong>
                {{
                  itemFormData.task_type_id === 1 ? "Onboarding" : "Offboarding"
                }}
              </span>
            </div>
            <div v-else class="pt-radio-group">
              <v-radio-group
                row
                mandatory
                v-bind="fields.task_type_id.attrs"
                @change="fields.task_type_id.input"
              >
                <v-radio label="Onboarding" :value="1"></v-radio>
                <v-radio label="Offboarding" :value="2"></v-radio>
              </v-radio-group>
            </div>
          </div>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>2</v-chip>
              <h6>Enter Task Title</h6>
            </div>
            <v-text-field
              v-bind="fields.title.attrs"
              @input="fields.title.input"
              :disabled="item.is_core"
              data-lpignore="true"
              dense
              outlined
            ></v-text-field>
            <label for="quill-container" class="v-label"
              >Task Description</label
            >
            <VueEditor
              v-bind="quillEditorProps"
              @input="fields.description.input"
            />
            <v-textarea
              style="display: none"
              v-bind="fields.description.attrs"
              :disabled="item.is_core"
              data-lpignore="true"
              outlined
              hide-details
              class="mt-4"
            ></v-textarea>
          </div>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>3</v-chip>
              <h6>Assign Task To</h6>
            </div>

            <v-radio-group v-model="uiOnlyAss" @change="changeAssignmentType">
              <v-radio value="" label="Manager" :disabled="item.is_core" />
              <v-expand-transition>
                <v-card
                  v-show="
                    [
                      '',
                      'district_manager',
                      'regional_manager',
                      'property_manager',
                      'direct_supervisor',
                    ].includes(fields.assignment_type.attrs.value)
                  "
                  class="sub-option"
                >
                  <v-card-text>
                    <h6>
                      Select a Manager
                      <cp-explain>
                        This task will only be assigned to the<br />
                        {{ currentTaskType.employeeFor }}'s manager.
                      </cp-explain>
                    </h6>
                    <v-radio-group
                      v-bind="fields.assignment_type.attrs"
                      @change="fields.assignment_type.input"
                      :disabled="item.is_core"
                    >
                      <v-radio
                        label="District Manager"
                        value="district_manager"
                      />
                      <v-radio
                        label="Regional Manager"
                        value="regional_manager"
                      />
                      <v-radio
                        label="Property Manager"
                        value="property_manager"
                      />
                      <v-radio
                        label="Direct Supervisor"
                        value="direct_supervisor"
                      />
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <v-radio value="assignment_group" label="Assignment Group" />
              <v-expand-transition>
                <v-card
                  v-show="
                    fields.assignment_type.attrs.value === 'assignment_group'
                  "
                  class="sub-option"
                >
                  <v-card-text>
                    <h6 class="mb-3">
                      Select An Assignment Group
                      <cp-explain>
                        This task will initially be assigned to the group
                        selected.<br />
                        If it isn't completed on time, it will escalate.
                      </cp-explain>
                    </h6>
                    <v-select
                      v-bind="fields.assignment_group_id.attrs"
                      @input="fields.assignment_group_id.input"
                      :items="assignments"
                      clearable
                      background-color="white"
                      placeholder="Search"
                      prepend-inner-icon="mdi-magnify"
                      dense
                      outlined
                      class="ass-select"
                    />
                    <h6 class="text-center mb-4 mt-n3">or</h6>
                    <div class="text-right">
                      <v-btn
                        @click="openAssModal({ mode: 'assignToTask' })"
                        color="#1E64DC"
                        block
                        outlined
                      >
                        <v-icon>mdi-account-multiple-plus</v-icon>
                        Create New Assignment
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <v-radio
                value="employee"
                label="Employee"
                :disabled="item.is_core"
              />
              <v-expand-transition>
                <v-alert
                  v-show="fields.assignment_type.attrs.value === 'employee'"
                  type="info"
                  icon="mdi-information"
                  style="align-self: start; margin-left: 32px;"
                >
                  This task will be completed by the employee.
                </v-alert>
              </v-expand-transition>
            </v-radio-group>
          </div>
          <div class="form-controls-sec">
            <div class="fcs-head">
              <v-chip>4</v-chip>
              <h6>Due Date and Notifications</h6>
            </div>
            <div class="plan-details-wrap atm-due-date-wrap">
              <div class="pd-info atm-due-date-info">
                <div>
                  <p class="due-date-task">Due Date:</p>
                  <p class="before-due-date">
                    <strong v-if="itemFormData.due_type === 'on'">
                      On {{ currentTaskType.dueRelativeTo }}
                    </strong>
                    <span v-else>
                      <strong>
                        {{
                          pluralize("Day", itemFormData.due_day_distance, true)
                        }}
                      </strong>
                      {{ itemFormData.due_type }}
                      {{ currentTaskType.dueRelativeTo }}
                    </span>
                  </p>
                </div>
                <div>
                  <p class="due-date-task">Email Alert Lead Time:</p>
                  <p class="before-due-date">
                    <strong>
                      {{ pluralize("Day", itemFormData.alert_lead_time, true) }}
                    </strong>
                    before due date
                  </p>
                </div>
                <div>
                  <p class="due-date-task">Task Escalated:</p>
                  <p class="before-due-date">
                    <strong>
                      {{
                        pluralize("Day", itemFormData.days_till_escalate, true)
                      }}
                    </strong>
                    after due date
                  </p>
                </div>
                <div>
                  <p class="due-date-task">Task Abandon:</p>
                  <p class="before-due-date">
                    <strong>
                      {{
                        pluralize("Day", itemFormData.days_till_abandon, true)
                      }}
                    </strong>
                    after escalation date
                  </p>
                </div>

                <v-icon
                  v-if="!item.is_core"
                  class="atm-edit-icon"
                  @click="openDueSubFormModal"
                >
                  mdi-pencil
                </v-icon>
              </div>
            </div>
          </div>
          <div v-if="editMode && !item.is_core" class="form-controls-sec mb-3">
            <div class="fcs-head">
              <v-chip>5</v-chip>
              <h6>Add Attachments (optional)</h6>
            </div>
            <div class="atm-file-attachment">
              <v-btn variant="text" @click="openAttachmentsSubFormModal">
                <v-icon>mdi-paperclip</v-icon> Add Attachment
              </v-btn>
              <p>
                Max Size: (10mb) Types: (pdf, ppt, doc, xls, jpeg, png)
              </p>
            </div>
            <div v-if="item.files">
              <v-chip
                class="ma-2 atm-chips"
                closable
                model-value="true"
                v-for="file in item.files"
                :key="file.id"
              >
                <v-icon>mdi-star</v-icon>{{ file.name
                }}<v-icon @click="removeAttachment(file.id)"
                  >mdi-close-circle</v-icon
                >
              </v-chip>
            </div>
          </div>
          <div class="form-controls-sec mb-3">
            <div class="fcs-head">
              <v-chip>{{ editMode && !item.is_core ? "6" : "5" }}</v-chip>
              <h6>Add Tags (optional)</h6>
            </div>
            <v-combobox
              v-bind="fields.tag_list.attrs"
              @change="updateTags"
              :items="tags"
              @update:search-input="searchForTagsDebounced"
              item-value="text"
              label="type to create custom tags"
              multiple
              chips
              deletable-chips
              small-chips
              outlined
            >
              <template v-slot:no-data>
                <v-list-item class="subheading">
                  <div v-if="tagSearch && !tags.length">
                    Press
                    <v-chip class="mx-1 px-1" color="success" text label x-small
                      >ENTER</v-chip
                    >
                    to create:
                    <v-chip class="ml-2" pill text small>
                      {{ tagSearch }}
                    </v-chip>
                  </div>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <cp-unhandled-errors :formConf="formConf" />
          <div class="text-right mt-4">
            <v-btn
              v-if="!editMode"
              @click="saveAndOpenAttachments"
              :loading="submitting"
              class="mr-4"
              color="primary"
            >
              <v-icon>mdi-paperclip</v-icon>
              Add Attachments
            </v-btn>
            <v-btn @click="saveAndClose" color="primary">
              {{ editMode ? "Save Changes" : "Create Task" }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <upload-file :taskId="item.id" @closed="reFetchItem" />
    <due-date-notifications />
  </cp-fullscreen-module-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import pluralize from "pluralize";
import { VueEditor } from "vue2-editor";

import { onCpEvent } from "@cp/mixins";
import {
  itemFormModal,
  taskTypeObj,
  dueSubForm,
  attachmentSubForm,
} from "@/store/modules/tasks";
import { itemFormModal as assModal } from "@/store/modules/assignments";
import { get } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";

export default {
  name: "TaskForm",
  components: { VueEditor },
  mixins: [
    itemFormModal.mixin,
    itemFormModal.modalMixin,
    itemFormModal.routedModalMixin,
    onCpEvent(assModal.keys.modalClosedEvent, function(...args) {
      this.assignmentGroupModalClosed(...args);
    }),
  ],
  data() {
    return {
      uiOnlyAss: "", // needed for on-load
      tagSearch: "",
      transitionsDisabled: true,
      _tagSearchTimer: 0,
    };
  },
  computed: {
    ...mapState("tasks", ["assignments", "tags"]),
    isArchived() {
      return this.item.task_status_id === 2;
    },
    editMode() {
      return !!this.item.id;
    },
    addingToPlanMode() {
      return /^addToPlan/.test(this.$store.state.tasks.itemFormModalMode);
    },
    currentTab() {
      switch (this.itemFormData.assignment_type) {
        case "assignment_group":
          return 1;
        case "employee":
          return 2;
        default:
          return 0;
      }
    },
    currentTaskType() {
      return taskTypeObj[this.itemFormData.task_type_id];
    },
    actions() {
      const actions = [];
      const employeeCount = this.item.employees_on_task;

      if (this.isArchived)
        actions.push({
          icon: "archive-off-outline",
          text: "Restore Task",
          click: () =>
            this.confirm({
              verb: "restore",
              noun: "task",
              appendText:
                "This will activate it, which will start assigning tasks, and sending emails.",
              action: "tasks/restoreTask",
            }),
        });
      else
        actions.push(
          {
            icon: "archive-outline",
            text: "Archive Task",
            click: () =>
              this.confirm({
                verb: "archive",
                noun: "task",
                appendText:
                  "This will only deactivate it, and you can reactivate it at any time.",
                alertText: employeeCount
                  ? `This task is currently in use with ${employeeCount} employees.`
                  : "This task is not currently being used by any employees.",
                alertProps: employeeCount
                  ? { icon: "mdi-account-alert" }
                  : { icon: "mdi-check", color: "success" },
                action: "tasks/archiveTask",
              }),
          },
          {
            icon: "content-copy",
            text: "Duplicate Task",
            click: () => this.duplicateTask(),
          }
        );

      if (this.item["is_deletable?"])
        actions.push({
          icon: "trash-can-outline",
          text: "Delete Task",
          click: () =>
            this.confirm({
              verb: "delete",
              noun: "task",
              alertText: "This will delete the task permanently!",
              action: "tasks/deleteTask",
            }),
          props: {
            class: "red--text text--darken-2",
          },
        });

      return actions;
    },
    quillEditorProps() {
      const value = get(this.itemFormData, "description");
      return {
        value,
        editorToolbar: [
          ["bold"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["clean"],
        ],
      };
    },
  },

  methods: {
    pluralize,
    ...mapActions("tasks", [
      "deleteAttatchment",
      "fetchAssignments",
      "duplicateTask",
      dueSubForm.keys.modalOpen,
      attachmentSubForm.keys.modalOpen,
    ]),
    ...mapActions("plans", ["addTasks", "openPlanDetailModal"]),
    ...mapActions("assignments", { openAssModal: "openItemFormModal" }),
    ...mapActions("confirm", ["confirm"]),
    changeAssignmentType(value) {
      this.fields.assignment_type.input(value);
      switch (value) {
        case "":
          this.setItemValue({ assignment_group_id: "" });
          break;
        case "assignment_group":
          this.setItemTouched("assignment_group_id");
          break;
        case "employee":
          this.setItemValue({ assignment_group_id: "" });
          break;
      }
    },
    assignmentGroupModalClosed({ success, id } = {}) {
      if (success) {
        this.setItemValue({ assignment_group_id: id });
        this.fetchAssignments();
      }
    },
    attachmentModalUpdate(value) {
      if (!value && !this.editMode) this.closeModal();
    },
    reFetchItem() {
      this.fetchItem({ id: this.item.id });
    },
    searchForTagsDebounced(q_text) {
      this.tagSearch = q_text;
      this.$store.state.tasks.tagsLoading = true;
      clearTimeout(this._tagSearchTimer);
      this._tagSearchTimer = setTimeout(() => {
        this.$store.dispatch("tasks/fetchTags", {
          params: { filters: { q_text } },
        });
      }, 300);
    },
    async removeAttachment(attachmentId) {
      let ids = {
        task_id: this.item.id,
        attachment_id: attachmentId,
      };
      await this.deleteAttatchment(ids);
      this.reFetchItem();
    },
    submit() {
      const reqConf = this.editMode ? { method: "PUT", id: this.item.id } : {};
      return this.itemSubmit(reqConf);
    },
    async saveAndClose() {
      const response = await this.submit();
      if (response.data)
        this.closeModal({ success: true, id: response.data.id });
    },
    saveAndOpenAttachments() {
      this.submit().then(({ data }) => {
        this.fetchItem({ id: data.id });
        this.openAttachmentsSubFormModal();
      });
    },
    updatedFiles(value) {
      if (!value && !this.editMode) this.closeModal();
    },
    async onOpen({ promise }) {
      this.transitionsDisabled = true;

      // set task_type_id to match plan_type (when we are adding to a plan)
      if (this.addingToPlanMode) {
        this.itemFormData.task_type_id = get(
          this.$store,
          "state.plans.item.plan_type_id",
          1
        );
      }

      // if we're editing, wait for the fetch
      if (promise) await promise;
      if (this.item.is_core && this.item.assignment_type === "") {
        this.uiOnlyAss = "assignment_group";
        this.itemFormData.assignment_type = "assignment_group";
      } else {
        const isAGorNH =
          !this.editMode ||
          ["assignment_group", "employee"].includes(this.item.assignment_type);
        this.uiOnlyAss = isAGorNH ? this.item.assignment_type : "";
      }
      await wait(100);
      this.transitionsDisabled = false;
    },
    updateTags(arrOfStrOrObjs) {
      // Can't use the value, because it could be a string, or an object, have to check
      const value = arrOfStrOrObjs.map(x =>
        x.hasOwnProperty("text") ? x.text : x
      );

      console.log("TaskForm.updateTags:");
      console.log(value);
      this.fields.tag_list.input(value);
    },
  },
  mounted() {
    // TODO move to own modal file
    this.fetchAssignments();
  },
};
</script>

<style lang="scss" scoped>
.atm-due-date-info {
  p {
    margin: 0;
  }
}

.v-card.sub-option {
  background: #eee;
  align-self: start;
  margin-left: 32px;

  + * {
    margin-top: 10px;
  }
}

.ass-select::v-deep .v-select__selections input {
  display: none;
}

.v-form.transitionsDisabled .sub-option {
  transition: none !important;
}
</style>
