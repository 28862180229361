<template>
  <div>
    <cp-h1>
      {{ constants.DASHBOARD }}
      <template #right>
        <cp-filters-menu :module="dashboardModule" />
      </template>
    </cp-h1>

    <div id="dashboard-report">
      <v-row>
        <v-col md="4" v-if="dashboard">
          <v-card
            :loading="dashboardLoading"
            class="dashboard-card dashboard-card-doughnut"
          >
            <v-card-title>{{ constants.COMPLIANCE_SCORE }}</v-card-title>
            <v-card-subtitle>
              {{ complianceSummaryText }}
            </v-card-subtitle>
            <v-card-text class="text-center">
              <v-progress-circular
                class="dashboard-compliance-score"
                :rotate="-90"
                :size="183"
                :width="20"
                :value="dashboard.compliant_percentage"
                :color="
                  dashboard.compliant_percentage < 70 ? '#E40000' : '#009A44'
                "
              >
                <v-icon>mdi-forum-outline</v-icon>
                {{ Math.round(dashboard.compliant_percentage) || "0" }}%
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="8" :loading="dashboardLoading">
          <v-card class="dashboard-card dashboard-card-doughnut">
            <v-card-title>{{ constants.TASK_COMPLIANCE }}</v-card-title>
            <v-card-subtitle>
              {{ tasksSummaryText }}
            </v-card-subtitle>
            <v-card-text>
              <v-row align="center">
                <v-col md="12" lg="5">
                  <doughnut v-bind="doughnutProps" />
                </v-col>
                <v-col md="12" lg="7">
                  <v-list class="list-task-compliance">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-badge
                          color="#009A44"
                          dot
                          class="v-badge-circle"
                        ></v-badge>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          constants.COMPLETED
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon class="font-weight-bold">{{
                        dashboard.completed || "NA"
                      }}</v-list-item-icon>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-badge
                          color="#FEDC08"
                          dot
                          class="v-badge-circle"
                        ></v-badge>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          constants.PENDING
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon class="font-weight-bold">{{
                        dashboard.pending || "NA"
                      }}</v-list-item-icon>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-badge
                          color="#FBAF57"
                          dot
                          class="v-badge-circle"
                        ></v-badge>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          constants.CANT_COMPLETE
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon class="font-weight-bold">{{
                        dashboard.cant_complete || "NA"
                      }}</v-list-item-icon>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-badge
                          color="#E40000"
                          dot
                          class="v-badge-circle"
                        ></v-badge>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          constants.PAST_DUE
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon class="font-weight-bold">{{
                        dashboard.past_due || "NA"
                      }}</v-list-item-icon>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-badge
                          color="#8B5DCB"
                          dot
                          class="v-badge-circle"
                        ></v-badge>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          constants.ABANDONED
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon class="font-weight-bold">{{
                        dashboard.abandoned || "NA"
                      }}</v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="6">
          <v-card
            :loading="regionsLoading"
            class="dashboar-card dashboard-card-list"
          >
            <v-card-title>{{ constants.REGIONS }}</v-card-title>
            <v-card-text>
              <empty-screen
                title="Region"
                v-if="regions.length === 0"
                tabSlug="active"
              />
              <v-list v-if="regions">
                <v-list-item
                  v-for="item in regions"
                  :key="item.m_id"
                  :to="{ name: 'RegionReport', params: { id: item.m_id } }"
                >
                  <v-list-item-icon>
                    <v-badge
                      color="#E40000"
                      dot
                      class="v-badge-circle"
                      v-if="item.compliant_percentage < 70"
                    ></v-badge>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    {{ Math.round(item.compliant_percentage) }}%
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="6">
          <v-card
            :loading="plansLoading"
            class="dashboard-card dashboard-card-list"
          >
            <v-card-title>{{ constants.PLANS }}</v-card-title>
            <v-card-text>
              <EmptyScreen
                title="Plan"
                v-if="plans.length === 0"
                tabSlug="active"
                :plans_from_dahsboard="true"
              />
              <v-list>
                <v-list-item
                  v-for="item in plans"
                  :key="item.m_id"
                  :to="{ name: 'PlanReport', params: { id: item.m_id } }"
                >
                  <v-list-item-icon>
                    <v-badge
                      color="#E40000"
                      dot
                      class="v-badge-circle"
                      v-if="item.compliant_percentage < 70"
                    ></v-badge>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    {{ Math.round(item.compliant_percentage) }}%
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pluralize from "pluralize";

import { capitalize } from "@cp/utils/stringUtils";
import { genericConstants } from "@/constants/genericConstants";
import { dashboard } from "@/store/modules/dashboard";

export default {
  name: genericConstants.DASHBOARD,
  data() {
    return {
      dashboardModule: dashboard,
      constants: genericConstants,
    };
  },
  computed: {
    ...mapState("dashboard", [
      "dashboard",
      "dashboardLoading",
      "dashboardMeta",
    ]),
    ...mapState("dashboard/regions", ["regions", "regionsLoading"]),
    ...mapState("dashboard/plans", ["plans", "plansLoading"]),
    doughnutProps() {
      let value = {
        completed: 0,
        pending: 0,
        cant_complete: 0,
        past_due: 0,
        abandoned: 0,
        defaultColorValue: 0,
      };
      let totalScore = 0;
      for (const key in value) {
        if (this.dashboard.hasOwnProperty(key)) {
          value[key] = this.dashboard[key];
          totalScore += this.dashboard[key];
        }
      }
      if (!totalScore) {
        value.defaultColorValue = 100;
      }
      return {
        value,
        totalScore,
      };
    },
    complianceSummaryText() {
      const keys = ["regions", "locations", "positions"];
      const summary = this.dashboardMeta.summary;
      return keys
        .filter(k => summary[k] && summary[k] >= 2)
        .map(k => pluralize(capitalize(k), this.dashboardMeta.summary[k], true))
        .join(", ");
    },
    tasksSummaryText() {
      const keys = [
        "plans",
        "employees",
        "onboarding_tasks",
        "offboarding_tasks",
      ];
      const labels = {
        onboarding_tasks: "Onboarding Tasks",
        offboarding_tasks: "Offboarding Tasks",
      };
      const summary = this.dashboardMeta.summary;
      return keys
        .filter(k => summary[k] && summary[k] >= 2)
        .map(k =>
          labels[k]
            ? `${summary[k]} ${labels[k]}`
            : pluralize(capitalize(k), this.dashboardMeta.summary[k], true)
        )
        .join(", ");
    },
  },

  mounted() {
    this.$store.dispatch(dashboard.p.a.fetch);
  },
};
</script>

<style>
.htmlToCanvas .dashboard-card-list .v-list {
  max-height: none;
}
</style>

<style lang="scss" scoped>
.v-list .v-list-item .v-list-item__icon {
  margin-right: 32px;
}
</style>
