<template>
  <div v-bind="$attrs" class="v-messages theme--light error--text">
    <div class="v-messages__message" v-for="(msg, i) in otherErrors" :key="i">
      {{ msg }}
    </div>
  </div>
</template>

<script>
import { capitalize } from "@cp/utils/stringUtils";

export default {
  props: {
    errors: { type: Object, required: true },
    alreadyDisplayed: { type: Array, default: () => [] },
  },
  computed: {
    otherErrors() {
      return Object.entries(this.errors).reduce((r, [key, value]) => {
        // If the error is already being displayed elsewhere, skip it.
        if (this.alreadyDisplayed.includes(key)) return r;

        // otherwise, condense into a single array, with key: value strings
        if (Array.isArray(value)) {
          r.push(...value.map(v => `${capitalize(key)}: ${v}`));
        } else {
          r.push(`${capitalize(key)}: ${value}`);
        }
        return r;
      }, []);
    },
  },
};
</script>
