import { ItemFormModal } from "@cp/store/mixins";
import { requiredIf } from "@cp/utils/rules";
import { parse } from "@cp/utils/dateUtils";

const module = "taskStatusForm";

export const taskStatusForm = new ItemFormModal({
  module,
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/user_tasks`,
  url: "/:id",
  urlTemplate: true,
  initialValue: {
    completed_at: "",
    admin_actions: [],
    task: {},
    on_behalf_of: {},
    assignees: [],
    plans: [],
    files: [],
    due_by_date: "",
    abandon_date: "",
    escalate_date: "",
    user_task_progress_status_id: 1,
    user_task_compliance_status_id: 0,
    notes: [],
  },
  fields: {
    admin_action: {
      resetOthers: ["completed_by", "completed_at"],
    },
    completed_by: {
      rules: [requiredIf(({ admin_action }) => admin_action === "complete")],
    },
    completed_at: {
      initialValue: parse().format("YYYY-MM-DD"),
      rules: [requiredIf(({ admin_action }) => admin_action === "complete")],
    },
    note: {
      rules: [requiredIf(({ admin_action }) => admin_action != "complete")],
    },
  },
});
taskStatusForm.adminActions = {
  complete: {
    text: "Mark Complete",
    icon: "mdi-check-bold",
  },
  cancel: {
    text: "Mark Cancelled",
    icon: "mdi-cancel",
    explanation: "Cancels the item, so it doesn't show up in assignee's lists.",
  },
  un_cancel: {
    text: "Un Cancel",
    icon: "mdi-arrow-left-top",
    explanation: "Cancels the item, so it doesn't show up in assignee's lists.",
  },
  send_back: {
    text: "Send back",
    icon: "mdi-account-arrow-left",
    explanation:
      "Marks the item as incomplete, so that the assignees see it in their todo list.",
  },
  update_note: {
    text: "Add Note",
    icon: "mdi-pencil-box-outline",
  },
};

export default taskStatusForm.toVuex;
