<template>
  <v-menu bottom offset-y left v-bind="$attrs">
    <template #activator="{on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn icon v-bind="attrs" v-on="on" color="primary">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-list dense>
        <v-list-item
          v-for="(item, i) in items"
          :key="`menu-item-${i}`"
          @click="item.click"
          v-bind="item.props"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action v-if="item.explanation">
            <cp-explain>
              <span v-html="item.explanation" />
            </cp-explain>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: { items: { type: Array, default: () => [] } },
};
</script>

<style scoped>
.v-list-item .v-list-item__icon:first-child {
  margin-right: 16px;
}
.v-list-item .v-list-item__icon,
.v-list-item .v-list-item__icon .v-icon,
.v-list-item .v-list-item__title {
  color: inherit;
}
</style>
