<template>
  <cp-fullscreen-module-modal :modalConf="modalConf">
    <template #title>Plan Detail</template>

    <v-card class="modal-content-card">
      <v-card-text>
        <div class="plan-details-wrap">
          <div class="pd-sec-head">
            <h2>
              <v-icon>mdi-clipboard-check</v-icon>
              {{ item.title }}

              <core-indicator v-if="item.is_core" class="ml-4">
                <div>
                  <h3>This is a core plan.</h3>
                  It applies to all locations and positions.
                </div>
              </core-indicator>
            </h2>

            <v-spacer />

            <v-chip v-if="isArchived" text class="mr-3">
              <v-icon class="mr-1">mdi-archive-outline</v-icon>
              Archived
            </v-chip>

            <template v-if="isDraft">
              <v-chip text class="mr-3">
                <v-icon class="mr-1">mdi-email-off-outline</v-icon>
                Draft
              </v-chip>
              <v-btn @click="publish" color="primary" class="mr-3">
                <v-icon left>mdi-email-fast-outline</v-icon>
                Publish
              </v-btn>
            </template>

            <v-btn
              v-if="!item.is_core"
              @click="openItemFormModal({ id: item.id, mode: 'detailEdit' })"
              color="primary"
              class="mr-2"
              text
            >
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Edit Plan
            </v-btn>

            <settings-menu :items="actions" />
          </div>
          <div class="pd-info">
            <p>
              <strong>Plan Type:</strong>
              {{ item.plan_type.label }}
            </p>
            <p>
              <strong>Positions:</strong>
              {{ item.positions_for_plan.map(data => data.name).join(", ") }}
            </p>
            <p>
              <strong>Description:</strong>
              {{ item.description }}
            </p>
          </div>
          <div class="pd-tasks-list-wrap">
            <div class="pd-sec-head">
              <h2><v-icon>mdi-check-decagram</v-icon> Task List</h2>
              <v-btn
                outlined
                max-height="32"
                color="primary"
                class="ml-auto"
                @click="openTaskModal"
              >
                <v-icon left>mdi-check-decagram</v-icon> Create New Task
              </v-btn>
              <v-btn
                @click="openTasksAddExistingTaskModal"
                outlined
                max-height="32"
                color="primary"
                class="ml-12px"
              >
                <v-icon left>mdi-check-decagram</v-icon> Add Existing Task
              </v-btn>
            </div>
            <template v-for="task in item.tasks">
              <v-menu max-width="500" absolute offset-y>
                <template v-slot:activator="scope">
                  <v-list class="tasks-list-wrap">
                    <v-list-item v-bind="scope.attrs" v-on="scope.on">
                      <v-list-item-content>
                        <v-list-item-title class="d-flex align-center">
                          <v-icon color="#008A09">mdi-check-decagram</v-icon>

                          <span class="mx-4">
                            {{ task.title }}
                          </span>

                          <core-indicator v-if="task.is_core" size="24">
                            <div>
                              <h3>This is a core task.</h3>
                              It cannot be removed.
                            </div>
                          </core-indicator>

                          <cp-explain
                            v-if="
                              !task.assignment_type ||
                                (task.assignment_type === 'assignment_group' &&
                                  !task.assignment_group_id)
                            "
                          >
                            <template #activator="{on}">
                              <v-icon v-on="on" class="ml-3" color="error"
                                >mdi-alert</v-icon
                              >
                            </template>
                            Missing Assignment!
                          </cp-explain>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-icon color="#8B5DCB">mdi-calendar-clock</v-icon>
                          <span class="mx-4">{{ task.schedule }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </template>
                <v-card class="description-popover">
                  <v-card-title>
                    {{ task.title }}
                  </v-card-title>
                  <v-card-text>
                    <p><strong>Description:</strong>
                      <div v-html="task.description" class="displayHTML"></div>
                    </p>
                    <p><strong>Schedule:</strong>{{ task.schedule }}</p>
                    <p>
                      <strong>Plans Using This Task:</strong
                      >{{ task.plans.map(data => data.title).join(", "), }}
                    </p>
                    <p><strong>Assigned To:</strong>{{ task.assigned_to }}</p>
                    <p>
                      <strong>Attachments:</strong
                      >{{
                        task.files.length
                          ? task.files.map(element => element.name).join(", ")
                          : "N/A"
                      }}
                    </p>
                    <v-btn
                      @click="navToTask(task.id)"
                      color="primary"
                      class="mr-2"
                      outlined
                      small
                    >
                      <v-icon left>mdi-pencil</v-icon>
                      Edit
                    </v-btn>
                    <v-btn
                      v-if="!(item.is_core && task.is_core)"
                      @click="removeTaskFromItem(task.id)"
                      color="warning"
                      outlined
                      small
                    >
                      <v-icon left>mdi-playlist-remove</v-icon>
                      Remove
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
            <no-data-screen
              v-if="!item.tasks.length"
              text="Your task list is empty"
              icon="mdi-check-decagram"
              sub-text="Add or create some tasks for this plan"
              :show-button="false"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>

    <add-existing-task @closed="ifSuccessRefetchItem" />
  </cp-fullscreen-module-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { onCpEvent } from "@cp/mixins";
import { itemDetailModal, itemFormModal } from "@/store/modules/plans";

import { itemFormModal as taskFormModal } from "@/store/modules/tasks";

export default {
  name: "ModalPlanDetail",
  mixins: [
    itemDetailModal.modalMixin,
    itemDetailModal.routedModalMixin,
    onCpEvent(taskFormModal.keys.modalClosedEvent, function(...args) {
      this.taskModalClosed(...args);
    }),
    onCpEvent(itemFormModal.keys.modalClosedEvent, function() {
      this.refetchItem();
    }),
  ],
  props: {
    planDetailModalFlag: Boolean,
  },
  computed: {
    ...mapState("plans", ["item"]),
    isArchived() {
      return this.item.plan_status_id === 2;
    },
    isDraft() {
      return this.item.plan_status_id === 3;
    },
    actions() {
      const actions = [];
      const employeeCount = this.item.employees_on_plan;

      if (this.isArchived)
        actions.push({
          icon: "archive-off-outline",
          text: "Restore Plan",
          click: () =>
            this.confirm({
              verb: "restore",
              noun: "plan",
              appendText:
                "This will activate it, which will start assigning tasks, and sending emails.",
              action: "plans/restorePlan",
            }),
        });
      else
        actions.push({
          icon: "archive-outline",
          text: "Archive Plan",
          click: () =>
            this.confirm({
              verb: "archive",
              noun: "plan",
              appendText:
                "This will only deactivate it, and you can reactivate it at any time.",
              alertText: employeeCount
                ? `This plan is currently in use with ${employeeCount} employees.`
                : "This plan is not currently being used by any employees.",
              alertProps: employeeCount
                ? { icon: "mdi-account-alert" }
                : { icon: "mdi-check", color: "success" },
              action: "plans/archivePlan",
            }),
        });

      if (!this.isArchived && !this.item.is_core)
        actions.push({
          icon: "content-copy",
          text: "Duplicate Plan",
          click: () => this.duplicatePlan(),
        });

      if (this.item["is_deletable?"])
        actions.push({
          icon: "trash-can-outline",
          text: "Delete Plan",
          click: () =>
            this.confirm({
              verb: "delete",
              noun: "plan",
              alertText: "This will delete the plan permanently!",
              action: "plans/deletePlan",
            }),
          props: {
            class: "red--text text--darken-2",
          },
        });

      return actions;
    },
  },
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("plans", [
      "openItemFormModal",
      "openTasksAddExistingTaskModal",
      "addTasksToItem",
      "removeTaskFromItem",
      "duplicatePlan",
      "refetchItem",
    ]),
    openTaskModal() {
      this.$store.dispatch("tasks/openItemFormModal", {
        mode: `addToPlan:${this.item.id}`,
      });
    },
    async taskModalClosed({ success, id } = {}) {
      if (success) {
        await this.addTasksToItem([id]);
        this.refetchItem();
      }
    },
    ifSuccessRefetchItem({ success } = {}) {
      if (success) this.refetchItem();
    },
    navToTask(id) {
      // THIS INTRODUCES A URL BUG, but has a much nicer experience
      // YOLO
      // this.closeModal();
      // this.$router.push({ name: "Tasks" });
      this.$store.dispatch("tasks/openItemFormModal", { id });
    },
    publish() {
      const hasUnnassignedTasks = this.item.tasks.some(
        task =>
          !task.assignment_type ||
          (task.assignment_type === "assignment_group" &&
            !task.assignment_group_id)
      );
      const alert = hasUnnassignedTasks
        ? {
            alertText: "Some tasks do not have assignments",
            alertProps: { type: "error", text: true },
          }
        : {};
      this.confirm({
        verb: "publish",
        noun: "plan",
        appendText:
          "This will activate it, which will start assigning tasks, and sending emails.",
        action: "plans/publishPlan",
        ...alert,
      });
    },
  },
};
</script>
