<template>
  <main v-if="ready">
    <cp-app
      :navItems="navItems"
      :profileItems="profileItems"
      :mobileBreakpoint="960"
    >
      <template #modals>
        <plan-form />
        <plan-detail />
        <assignment-form />
        <task-form @closed="taskFormClosed" />
      </template>
    </cp-app>
  </main>
</template>

<script>
const sbSite = process.env.VUE_APP_WEBSITE_PATH;
const iframeAuth = process.env.VUE_APP_AUTH_IFRAME;
import { Auth } from "@cp/lib";

export default {
  name: "Fiver",
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    navItems() {
      return [
        // { text: "Dashboard", cpIcon: "dashboard", to: "/", exact: true },
        {
          text: "Plans",
          icon: "clipboard-text-multiple",
          to: "/plans",
        },
        { text: "Tasks", icon: "check-decagram", to: "/tasks" },
        {
          text: "Assignments",
          icon: "account-group",
          to: "/assignments",
        },
        { text: "Employees", icon: "account", to: "/employees" },
        // {
        //   text: "Reports",
        //   cpIcon: "histogram",
        //   children: [
        //     { text: "Plans", to: "/reports/plans" },
        //     { text: "Tasks", to: "/reports/tasks" },
        //     { text: "Assignees", to: "/reports/assignees" },
        //     { text: "Regions", to: "/reports/regions" },
        //     { text: "Locations", to: "/reports/locations" },
        //   ],
        // },
      ];
    },

    profileItems() {
      return [
        {
          text: "About Swift Bunny",
          to: `${sbSite}`,
          cpIcon: "swift-bunny-icon",
          target: "_blank",
        },
      ];
    },
  },
  methods: {
    taskFormClosed({ mode, success } = {}) {
      if (!success) return;

      const message =
        mode === "addToPlan" ? "New task added to plan" : "Task Created!";

      this.$CpEvent.$emit("snackAlert", {
        message,
        color: "success",
      });
    },
  },
  created() {
    // if you are in a redirect loop, uncomment this, to see what's wrong
    // if (Auth.alreadyPromptedLogin) {
    //   debugger;
    // }
    // Get the auth cookies from QA.
    // If you get an endless loop, delete the cookies ["iam_token", "iam_uuid"] and reload
    if (iframeAuth && !Auth.hasJwt) {
      const iframe = document.createElement("iframe");
      iframe.src = iframeAuth;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const handler = messageEvent => {
        if (messageEvent.data && messageEvent.data.message === "auth-pass") {
          let uuid = messageEvent.data.value.iam_uuid;
          let token = messageEvent.data.value.iam_token;

          /* if iframe doesn't have the cookies this will:
           * 1. clear localhost cookies
           * 2. logout of qa
           * 3. reidrect to qa login
           * 4. redirect back to localhost
           */
          if (!uuid || !token) {
            return Auth.resetCookiesAndLogout();
          }

          // otherwise, set local cookies from iframe
          uuid = JSON.parse(decodeURIComponent(uuid));
          token = JSON.parse(decodeURIComponent(token));
          this.$cookies.set("iam_uuid", uuid);
          this.$cookies.set("iam_token", token);

          window.removeEventListener("message", handler, false);
          this.ready = true;
        }
      };
      window.addEventListener("message", handler, false);
    } else {
      this.ready = true;
      return;
    }
  },
};
</script>
