import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"app":"","overlay":"","temporary":"","width":"360px","right":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"drawer-header"},[_c('h5',[_vm._v("Notes")]),_c(VBtn,{staticClass:"drawer-close-btn",attrs:{"dense":"","small":"","text":"","color":"primary"},on:{"click":_vm.closeDrawer}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v("mdi-close")]),_vm._v("Close")],1)],1),_c(VList,{staticClass:"comment-list",attrs:{"three-line":""}},[_vm._l((_vm.items),function(item,index){return [(item.title)?_c(VListItem,{key:index},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"avatar-icon"},[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(item.title)}}),_c(VListItemSubtitle,{domProps:{"innerHTML":_vm._s(item.subtitle)}})],1)],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }