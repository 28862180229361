<template>
  <div>
    <cp-explain>
      <template #activator="{on}">
        <v-avatar color="primary" v-on="on" :size="size">
          <cp-icon
            icon="swift_bunny_logo"
            :style="{ width: `${size - 10}px` }"
          />
        </v-avatar>
      </template>
      <slot />
    </cp-explain>
  </div>
</template>

<script>
export default {
  props: { size: { required: false, default: 28 } },
};
</script>

<style lang="scss" scoped>
svg.icon {
  fill: white;
  border-radius: 0;
}
</style>
