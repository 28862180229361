import { ApiDataMixin, ApiDataWithMeta } from "@cp/store/mixins";
import { filtersAddon, exportsAddon } from "@cp/store/addons";
import { nonEmpty } from "@cp/utils/itertees";
import { deepMerge, get } from "@cp/utils/objectUtils";

const module = "dashboard";
const reportsUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/reports/api`;

class NonBespokeDashboardPage extends ApiDataWithMeta {
  constructor({
    scopes = [],
    filters = [],
    filtersOptions = {},
    exportOptions = {},
    ...config
  }) {
    const initialValue = {
      completed: 0,
      pending: 0,
      cant_complete: 0,
      past_due: 0,
      abandoned: 0,
      defaultColorValue: 0,
    };
    const initialMeta = {
      summary: {
        regions: 0,
        locations: 0,
        positions: 0,
        employees: 0,
      },
    };
    const params = {
      structures: {
        addon_options: ["include_summary"],
      },
    };
    super({ ...config, name: "dashboard", initialValue, initialMeta, params });
    if (filters.length) {
      this.add(filtersAddon(filters, filtersOptions));
    }
    if (nonEmpty(exportOptions)) {
      this.add(exportsAddon(exportOptions));
    }

    this.subModules = [];
    const modules = {};
    for (const name of scopes) {
      const subModule = new DashboardSubModule({
        name,
        module: name,
        baseUrl: config.baseUrl,
        parent: this,
      });
      this.subModules.push(subModule);
      modules[name] = subModule.toVuex;
    }

    this.add({ modules });
  }

  mapData(response) {
    const result = get(response, "data.data.results[0]", {});
    result.summary = get(response, "data.summary", {});
    return result;
  }

  fetch(...args) {
    super.fetch(...args);
    const fetchPaths = this.subModules.map(sm => sm.p.a.fetch);
    for (const fetchPath of fetchPaths) {
      args[0].dispatch(fetchPath, null, { root: true });
    }
  }
}

class DashboardSubModule extends ApiDataMixin {
  constructor(config) {
    const params = { structures: { scope: config.name } };
    const initialValue = [];
    super({ ...config, params, initialValue });
  }

  mapData(response) {
    // Michael is a stinky bumm
    return get(response, "data.data.results", []);
  }

  getParams(state, getters, rootState, rootGetters) {
    const prevParams = super.getParams(state, getters, rootState, rootGetters);
    const filters = rootGetters[this.parent.p.g.params].filters;
    return deepMerge({}, prevParams, { filters });
  }
}

export const dashboard = new NonBespokeDashboardPage({
  module,
  baseUrl: reportsUrl,
  scopes: ["regions", "plans", "tasks"],
  filters: [
    {
      type: "radio",
      key: "plan_types",
      items: [
        { label: "All", value: "" },
        { label: "Onboarding", value: 1 },
        { label: "Offboarding", value: 2 },
      ],
      initialValue: "",
    },
    {
      type: "sub_query",
      key: "regions",
      label: "Regions",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "locations",
      label: "Locations",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "positions",
      label: "Positions",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "tasks",
      label: "Task",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "assignees",
      label: "Assignee",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "users",
      label: "Employee",
      multiple: true,
    },
  ],
  exportOptions: { htmlToCanvas: "#dashboard-report" },
});

export default dashboard.toVuex;
