import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tasks.length)?_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"text-caption"},[_vm._v("Compliance")]),_c('div',{staticClass:"bar-container"},_vm._l((_vm.statuses),function(status){return _c('cp-explain',{key:status.id,attrs:{"root-slot":"","nudge-bottom":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({key:status.id,staticClass:"status-bar",style:(status.style),on:{"click":function($event){return _vm.$emit('filter', status.filterValue)}}},on),[_vm._v(" "+_vm._s(status.tasks.length)+" ")])]}}],null,true)},[_c(VCard,{staticClass:"text-center"},[_c('div',{staticClass:"px-2"},[_vm._v(_vm._s(status.status.title))]),_c(VDivider),_c('div',{staticClass:"text-caption px-2"},[_vm._v("click to filter")])],1)],1)}),1),_c('div',{staticClass:"bar-container"},[(_vm.compliances.compliant)?_c('div',{staticClass:"compliance-bar",style:({ width: (((_vm.compliances.compliant / _vm.tasks.length) * 100) + "%") })},[_c('span',[_vm._v("Compliant")])]):_vm._e(),_c(VSpacer,{attrs:{"vertical":""}}),(_vm.compliances.non_compliant)?_c('div',{staticClass:"compliance-bar",style:({
        width: (((_vm.compliances.non_compliant / _vm.tasks.length) * 100) + "%"),
      })},[_c('span',[_vm._v("Non-compliant")])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }