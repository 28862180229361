import pluralize from "pluralize";

import { mergeMixins, Table, Item, Items } from "@cp/store/mixins";
import { parse, toMDY } from "@cp/utils/dateUtils";
import { get } from "@cp/utils/objectUtils";
import { truthy } from "@cp/utils/itertees";
import { unique } from "@cp/utils/arrayUtils";

const module = "employees";
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;
const userUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/users`;
const enrollmentsUrl = `${fiverUrl}/enrollments/autocomplete`;
const userTasksUrl = `${fiverUrl}/user_tasks/autocomplete`;

export const employeesTable = new Table({
  module,
  name: "employees",
  noun: "employee",
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/employees/autocomplete`,
  compoundId: true,
  initialMeta: {
    filter_options: {
      lifecycle_statuses: { values: [] },
      locations: { values: [] },
    },
  },
  tableOptions: {
    sort_by: "users.first_name",
  },
  headers: [
    {
      text: "Employee Name",
      sortable: true,
      value: "users.first_name",
      cellClass: "clickable-cell",
    },
    {
      text: "Job Title",
      sortable: false,
      value: "position",
    },
    {
      text: "Location",
      sortable: false,
      value: "location",
    },
    {
      text: "Status",
      sortable: false,
      value: "user_lifecycle_statuses_id",
    },
  ],
  filters: [
    "q_text",
    {
      type: "radio",
      key: "general",
      items: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Archived",
          value: "archived",
        },
      ],
    },
    {
      type: "sub_query",
      label: "Region",
      key: "regions",
      multiple: true,
    },
    {
      type: "sub_query",
      label: "Location",
      key: "locations",
      multiple: true,
    },
    {
      type: "sub_query",
      label: "Position",
      key: "positions",
      multiple: true,
    },
    {
      type: "sub_query",
      label: "Status",
      key: "lifecycle_statuses",
      multiple: true,
    },
  ],
  exportOptions: { htmlToCanvas: "#employees-table" },
});

class EmployeeStatus extends Item {
  constructor(config) {
    super(config);

    this.enrollments = new Items({
      module: "enrollments",
      parent: this,
      baseUrl: enrollmentsUrl,
      params: { page: { size: "all" }, filter: { status: [1] } },
      initialValue: [],
    });
    this.userTasks = new Items({
      module: "userTasks",
      parent: this,
      baseUrl: userTasksUrl,
      params: { page: { size: "all" } },
      initialValue: [],
    });

    this.add({
      state: {
        employeeId: "",
      },
      modules: {
        enrollments: this.enrollments.toVuex,
        userTasks: this.userTasks.toVuex,
      },
    });
  }

  fetch(ctx, { id }) {
    ctx.state.employeeId = id;
    super.fetch(ctx, { id });
    ctx.dispatch(
      this.enrollments.p.a.fetch,
      { params: { filters: { users: [id] } } },
      { root: true }
    );
    ctx.dispatch(
      this.userTasks.p.a.fetch,
      { params: { filters: { employees: [id] } } },
      { root: true }
    );
  }
}

const statusPage = new EmployeeStatus({
  module,
  baseUrl: userUrl,
  compoundId: true,
  initialValue: {
    avatar: {
      url: "",
      thumb: { url: "" },
    },
    email: "",
    full_name: "",
    location: "",
    position: "",
    managers: [],
  },
});

export default mergeMixins(employeesTable, statusPage, {
  getters: {
    employeeEnrollments(state) {
      const enrollments = get(state, "enrollments.items", []);
      return enrollments.map(x => {
        const tasks = state.userTasks.items.filter(task => {
          const p_ids = unique(task.plans.map(y => y.id));
          return p_ids.includes(x.plan_id);
        });

        return { ...x, tasks };
      });
    },
  },
});
