<template>
  <v-card class="description-popover">
    <v-card-title class="grey--text text--darken-2">{{
      item.title
    }}</v-card-title>
    <v-card-text>
      <p>
        <strong>Description:</strong>
        <div v-html="item.description" class="displayHTML"></div>
      </p>
      <p>
        <strong>Positions this plan is used for:</strong>
        {{ item.positions_for_plan.map(x => x.name).join(", ") }}
      </p>
      <p class="mb-0">
        <strong>Employees enrolled in this plan:</strong>
        {{ item.employees_on_plan }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PlanDetailCard",
  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: break-word;
}
.v-list {
  padding-top: 0;
  padding-bottom: 0;

  .v-list-item {
    &:not(:last-child) {
      border-bottom: 1px solid #afb4c5;
    }

    &__title {
      font-size: 1rem;
      font-weight: 700;
      color: #52555e;
    }

    p {
      color: #52555e;
      margin-top: 3px;

      span {
        color: #767b87;
      }
    }
  }
}

.empty {
  padding-top: 1.5rem;
  text-align: center;

  & > .v-icon {
    width: 3.5rem;
    height: 3.5rem;
    background: rgba(30, 100, 220, 0.1);
    border: 1px solid rgba(30, 100, 220, 0.3);
    border-radius: 50%;
    color: #1e64dc;
    font-size: 2rem;
    margin-bottom: 0.375rem;
  }

  h4,
  p {
    color: #767b87;
    margin: 0.5rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
  }

  p {
    font-size: 14px;
  }

  .v-btn {
    .v-icon {
      margin-right: 4px;
    }
  }
}
</style>
