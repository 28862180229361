import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-module-modal',_vm._g({staticClass:"modal-content-card",attrs:{"modalConf":_vm.modalConf,"max-width":"600"}},_vm.$listeners),[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","dense":""}},[_vm._v("File Upload "),_c(VBtn,{staticClass:"ml-auto",attrs:{"icon":"","dark":""},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"file-upload-card",class:{ 'grey lighten-2': _vm.dragover },on:{"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false}}},[_c('div',{staticClass:"drag-files-box"},[_c(VIcon,{attrs:{"aria-hidden":"false","color":"primary"}},[_vm._v(" mdi-upload ")]),_c('h5',[_vm._v("Drag files to upload")]),_c('p',[_vm._v(" Max Size: (10mb) Types: (jpg, jpeg, gif, png, pdf, doc, docx, xls, xlsx, ppt, pptx) ")])],1),_c('div',{staticClass:"text-center mt-3"},[_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.isSelecting},on:{"click":_vm.handleFileImport}},[_vm._v(" Choose File ")]),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","multiple":""},on:{"change":_vm.onFileChanged}})],1),_c(VList,[_vm._l((_vm.files),function(ref,i){
var file = ref.file;
var progress = ref.progress;
var error = ref.error;
return [(i > 0)?_c(VDivider,{key:("file-" + i + "-divider")}):_vm._e(),_c(VListItem,{key:("file-" + i)},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"#AFB4C5","size":"34"}},[_vm._v("mdi-file-document")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"file-name-wrapper"},[_c('div',{staticClass:"file-name"},[_vm._v(" "+_vm._s(file.name)+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(file.size)+" bytes")])])]),(error)?_c('div',{staticClass:"d-flex align-center"},[_c(VAlert,{staticClass:"mb-0",attrs:{"type":"error","text":"","dense":""}},[_vm._v(" "+_vm._s(error)+" ")])],1):_c(VListItemSubtitle,[(progress === 0)?_c('span',[_vm._v("Ready to upload")]):(progress === 100)?_c('span',{staticClass:"success--text d-flex align-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]),_vm._v(" Completed ")],1):_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(progress)+"%")]),_c(VProgressLinear,{attrs:{"color":"success","value":progress}})],1)])],1),_c(VListItemAction,[(progress < 100 || error)?_c(VBtn,{attrs:{"small":"","color":error ? 'error' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(i)}}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Remove ")],1):_vm._e()],1)],1)]})],2)],1),_c(VCardActions,[_c(VSpacer),(_vm.filesToUpload.length === 0)?_c(VBtn,{attrs:{"small":""},on:{"click":_vm.closeModal}},[_vm._v(" Close ")]):_vm._e(),_c(VBtn,{attrs:{"elevation":"2","disabled":_vm.filesToUpload.length === 0,"color":"primary","small":"","loading":_vm.saving},on:{"click":_vm.saveAttachments}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }