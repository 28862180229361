<template>
  <cp-module-modal :modalConf="modalConf" v-on="$listeners" width="1100">
    <v-toolbar dark color="primary" height="52">
      <v-toolbar-title>Select a template:</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small icon class="mr-1" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-radio-group v-model="selectedTemplateId" class="mt-0">
        <v-tabs v-model="tab" vertical>
          <v-tab
            v-for="template in templates"
            :key="`tab-${template.id}`"
            class="justify-start px-0"
          >
            <v-radio :label="template.title" :value="template.id" />
          </v-tab>
          <v-tab-item
            v-for="template in templates"
            :key="`item-${template.id}`"
            :transition="false"
            :transition-reverse="false"
          >
            <template-preview-card :item="template" />
          </v-tab-item>
        </v-tabs>
      </v-radio-group>
      <v-card-actions class="justify-end">
        <div v-if="success">
          <CpSuccessIcon class="mr-4" xLarge />
          Plan Created
        </div>
        <div v-else>
          <v-btn color="primary" text @click="closeModal">Cancel</v-btn>
          <v-btn
            :disabled="!selectedTemplateId"
            :loading="loading"
            @click="create"
            color="primary"
            class="ml-3"
          >
            Create from Template
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </cp-module-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { wait } from "@cp/utils/promiseUtils";
import { get } from "@cp/utils/objectUtils";
import { onCpEvent } from "@cp/mixins";

import { templates } from "@/store/modules/plans";

export default {
  mixins: [
    templates.modalMixin,
    onCpEvent(templates.keys.modalOpenedEvent, function() {
      this.selectedTemplateId = this.templates[0].id;
      this.tab = 0;
    }),
  ],
  data() {
    return {
      success: false,
      tab: 0,
      selectedTemplateId: "",
    };
  },
  computed: {
    ...mapState("plans", ["templates"]),
    ...mapState("plans", { loading: "templatesLoading" }),
  },
  methods: {
    ...mapActions("plans", { fetch: templates.keys.fetch }),
    async create() {
      try {
        const response = await templates.request({
          method: "PUT",
          url: `/${this.selectedTemplateId}/copy`,
        });
        this.success = true;

        await wait(1500);

        this.closeModal();
        const id = get(response, "data.data.id");
        this.$emit("success", id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 0 0 4px 4px;
}

::v-deep .v-list-group__header,
.v-list-item__content {
  padding: 0;
}

::v-deep .v-list-group__header__append-icon {
  display: none;
}

.v-radio {
  padding-left: 16px;
  height: 48px;
  position: relative;
  top: 0px;
  margin-bottom: 0 !important;
}
</style>
