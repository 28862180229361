import { ApiDataWithMeta } from "@cp/store/mixins";
import { filtersAddon } from "@cp/store/addons";

export class Filterable extends ApiDataWithMeta {
  constructor({ filters = [], filtersOptions = {}, ...config }) {
    super({ initialValue: [], ...config });
    if (filters.length) {
      this.add(filtersAddon(filters, filtersOptions));
    }
  }
}
