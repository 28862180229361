import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemAvatar,{staticClass:"placeholder-avatar"},[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.employee.first_name)+" "+_vm._s(_vm.employee.last_name)+" "),(_vm.showDisclaimer && _vm.employee.disclaimer)?_c('span',{staticClass:"ml-4 disclaimer"},[_vm._v(_vm._s(_vm.employee.disclaimer))]):_vm._e()]),_c(VListItemSubtitle,{staticClass:"d-flex"},[_c('div',{staticClass:"mr-3"},[_c('span',[_vm._v("Postion:")]),_vm._v(" "+_vm._s(_vm.position))]),(_vm.region)?_c('div',{staticClass:"mr-3"},[_c('span',[_vm._v("Region:")]),_vm._v(" "+_vm._s(_vm.region))]):_vm._e(),(_vm.location)?_c('div',[_c('span',[_vm._v("Location:")]),_vm._v(" "+_vm._s(_vm.location))]):_vm._e()])],1),(_vm.showDelete)?_c(VListItemAction,{on:{"click":function($event){return _vm.$emit('delete')}}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }