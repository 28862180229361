<template>
  <cp-module-modal
    :modalConf="modalConf"
    @opened="savePrevious"
    @click:outside="resetAndClose"
    max-width="600"
  >
    <v-card class="modal-content-card">
      <v-toolbar color="primary" dark dense
        >Due Date and Notifications</v-toolbar
      >

      <v-card-text>
        <p>
          Setup the Schedule for your Task
          <cp-explain>
            When should this task be completed relative to employee's
            <strong>{{ relativeTo }}</strong>
          </cp-explain>
        </p>
        <v-list class="ddn-modal-list" dense>
          <v-list-item class="py-2">
            <v-list-item-content>
              <div class="list-title-wrapper dd-list-item">
                <v-list-item-title>Due Date:</v-list-item-title>
                <v-radio-group
                  v-bind="fields.due_type.attrs"
                  @change="fields.due_type.input"
                  row
                  hide-details
                >
                  <v-radio label="Before" value="before"></v-radio>
                  <v-radio label="On" value="on"></v-radio>
                  <v-radio label="After" value="after"></v-radio>
                </v-radio-group>
                <v-list-item-action>
                  <v-label>({{ relativeTo }})</v-label>
                </v-list-item-action>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="item-as-child"
            v-if="itemFormData.due_type != 'on'"
          >
            <v-list-item-content class="pb-5">
              <div class="list-title-wrapper">
                <v-list-item-title></v-list-item-title>
                <div class="increment-and-decrement">
                  <v-btn
                    outlined
                    dense
                    color="indigo"
                    @click="decrement('due_day_distance')"
                    :disabled="
                      itemFormData.due_day_distance <=
                        fields.due_day_distance.attrs.min
                    "
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-minus </v-icon>
                  </v-btn>
                  <v-text-field
                    v-bind="fields.due_day_distance.attrs"
                    @input="fields.due_day_distance.input"
                    class="dueRelativeNumber"
                    color="indigo"
                    dense
                    outlined
                  />
                  <v-btn
                    dense
                    outlined
                    color="indigo"
                    @click="increment('due_day_distance')"
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-plus </v-icon>
                  </v-btn>
                </div>
                <v-label
                  >{{ pluralize("Day", itemFormData.due_day_distance) }}
                  {{ itemFormData.due_type }}
                  {{ relativeTo }}</v-label
                >
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="py-5">
              <div class="list-title-wrapper">
                <v-list-item-title>Email Alert Lead Time:</v-list-item-title>
                <div class="increment-and-decrement">
                  <v-btn
                    outlined
                    dense
                    color="indigo"
                    @click="decrement('alert_lead_time')"
                    :disabled="
                      itemFormData.alert_lead_time <=
                        fields.alert_lead_time.attrs.min
                    "
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-minus </v-icon>
                  </v-btn>
                  <v-text-field
                    v-bind="fields.alert_lead_time.attrs"
                    @input="fields.alert_lead_time.input"
                    class="dueRelativeNumber"
                    color="indigo"
                    dense
                    outlined
                  ></v-text-field>
                  <v-btn
                    dense
                    outlined
                    color="indigo"
                    @click="increment('alert_lead_time')"
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-plus </v-icon>
                  </v-btn>
                </div>
                <v-label
                  >{{ pluralize("Day", itemFormData.alert_lead_time) }} before
                  due date</v-label
                >
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-5">
              <div class="list-title-wrapper">
                <v-list-item-title>Task Escalated After:</v-list-item-title>
                <div class="increment-and-decrement">
                  <v-btn
                    outlined
                    dense
                    color="indigo"
                    @click="decrement('days_till_escalate')"
                    :disabled="
                      itemFormData.days_till_escalate <=
                        fields.days_till_escalate.attrs.min
                    "
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-minus </v-icon>
                  </v-btn>
                  <v-text-field
                    v-bind="fields.days_till_escalate.attrs"
                    @input="fields.days_till_escalate.input"
                    class="dueRelativeNumber"
                    color="indigo"
                    dense
                    outlined
                  ></v-text-field>
                  <v-btn
                    dense
                    outlined
                    color="indigo"
                    @click="increment('days_till_escalate')"
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-plus </v-icon>
                  </v-btn>
                </div>
                <v-label
                  >{{ pluralize("Day", itemFormData.days_till_escalate) }} after
                  due date</v-label
                >
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-5">
              <div class="list-title-wrapper">
                <v-list-item-title>Task Abandon After:</v-list-item-title>
                <div class="increment-and-decrement">
                  <v-btn
                    outlined
                    dense
                    color="indigo"
                    @click="decrement('days_till_abandon')"
                    :disabled="
                      itemFormData.days_till_abandon <=
                        fields.days_till_abandon.attrs.min
                    "
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-minus </v-icon>
                  </v-btn>
                  <v-text-field
                    v-bind="fields.days_till_abandon.attrs"
                    @input="fields.days_till_abandon.input"
                    class="dueRelativeNumber"
                    color="indigo"
                    dense
                    outlined
                  ></v-text-field>
                  <v-btn
                    dense
                    outlined
                    color="indigo"
                    @click="increment('days_till_abandon')"
                    tabindex="-1"
                  >
                    <v-icon size="14"> mdi-plus </v-icon>
                  </v-btn>
                </div>
                <v-label
                  >{{ pluralize("Day", itemFormData.days_till_abandon) }} after
                  escalation date</v-label
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="2"
          @click="closeModal"
          color="primary"
          dense
          :disabled="!allValid"
        >
          Save Settings
        </v-btn>
      </v-card-actions>
    </v-card>
  </cp-module-modal>
</template>

<script>
import pluralize from "pluralize";

import { isEmpty, pick } from "@cp/utils/objectUtils";
import { itemFormModal, dueSubForm, taskTypeObj } from "@/store/modules/tasks";

const FIELDS = [
  "due_type",
  "due_day_distance",
  "alert_lead_time",
  "days_till_escalate",
  "days_till_abandon",
];

export default {
  name: "DueDateNotifications",
  mixins: [itemFormModal.mixin, dueSubForm.modalMixin],
  data() {
    return {
      previous: {
        due_type: "after",
        due_day_distance: 1,
        alert_lead_time: 4,
        days_till_escalate: 7,
        days_till_abandon: 14,
      },
    };
  },
  computed: {
    relativeTo() {
      return taskTypeObj[this.itemFormData.task_type_id].dueRelativeTo;
    },
    allValid() {
      return isEmpty(pick(this.errors, FIELDS));
    },
  },
  methods: {
    pluralize,
    savePrevious() {
      const previous = pick(this.itemFormData, FIELDS);
      Object.assign(this.previous, previous);
    },
    increment(fieldKey) {
      const field = this.fields[fieldKey];
      const value = parseInt(field.attrs.value) || field.attrs.min;
      const nextValue = Math.max(0, value + 1);
      field.input(nextValue);
    },
    decrement(fieldKey) {
      const field = this.fields[fieldKey];
      const value = parseInt(field.attrs.value) || field.attrs.min || 1;
      const nextValue = Math.max(field.attrs.min, value - 1);
      field.input(nextValue);
    },
    resetAndClose() {
      this.setItemValue(this.previous);
      const resetTouchedObj = Object.keys(this.previous).reduce((r, key) => {
        r[key] = false;
        return r;
      }, {});
      console.log(resetTouchedObj);
      this.setItemTouched(resetTouchedObj);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.dueRelativeNumber.v-text-field.v-text-field--enclosed.v-input--dense {
  height: 42px;

  // because the border was getting chopped off
  margin: 3px 0 0;

  .v-input__slot {
    padding: 0 !important;
  }

  .v-input__control {
    padding-bottom: 14px;
    position: relative;
  }

  .v-text-field__details {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    margin: 0 auto;
    padding: 0;
    width: auto;
    max-width: 500px;
    white-space: nowrap;
  }
}
</style>

<style lang="scss" scoped>
.ddn-modal-list {
  .v-list-item,
  .v-list-item__content {
    padding: 0;
  }
}
</style>
