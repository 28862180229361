<template>
  <div class="empty-block">
    <div>
      <v-icon class="icon-large">mdi-clipboard-text-multiple</v-icon>
      <h4>
        No {{ tabSlug[0].toUpperCase() + "" + tabSlug.slice(1) }} {{ title }}s
      </h4>
      <div
        v-if="
          tabSlug === 'active' && title != 'Region' && !plans_from_dahsboard
        "
      >
        <p>
          Start by adding some <strong>{{ title }}s</strong>
        </p>
        <v-btn @click="openModal = true" color="primary" class="mt-2"
          ><v-icon>mdi-plus</v-icon> Add A {{ title }}</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EmptyScreen",
  components: {},
  props: {
    title: String,
    tabSlug: String,
    plans_from_dahsboard: Boolean,
  },
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    closeModal() {
      this.openModal = false;
    },
    close() {
      this.openModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-block {
  background: #fff;
  padding: 3rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-listing & {
    min-height: 40vh;
  }

  &.empty-block-border {
    border: 1px solid #afb4c5;
    border-radius: 4px;
  }

  .v-icon.icon-large {
    width: 5.75rem;
    height: 5.75rem;
    border-radius: 50%;
    background: rgba(30, 100, 220, 0.1);
    border: 1px solid rgba(30, 100, 220, 0.3);
    border-radius: 50%;
    color: #1e64dc;
    font-size: 3.5rem;
    margin-bottom: 0.375rem;
  }

  h4,
  p {
    color: #767b87;
    margin: 0.5rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
  }

  p {
    font-size: 14px;
  }
}
</style>
