<template>
  <v-list-item>
    <v-list-item-avatar class="placeholder-avatar">
      <v-icon>mdi-account</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{ employee.first_name }}
        {{ employee.last_name }}
        <span
          v-if="showDisclaimer && employee.disclaimer"
          class="ml-4 disclaimer"
          >{{ employee.disclaimer }}</span
        >
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex">
        <div class="mr-3"><span>Postion:</span> {{ position }}</div>
        <div v-if="region" class="mr-3"><span>Region:</span> {{ region }}</div>
        <div v-if="location"><span>Location:</span> {{ location }}</div>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="showDelete" @click="$emit('delete')">
      <v-btn icon>
        <v-icon color="primary">mdi-delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { isObject } from "@cp/utils/objectUtils";

export default {
  props: {
    employee: Object,
    showDelete: Boolean,
    showDisclaimer: Boolean,
  },
  computed: {
    // these need to work with haystack data, and fiver data :(
    region() {
      if (isObject(this.employee.region)) return this.employee.region.label;
      return this.employee.region;
    },
    location() {
      if (isObject(this.employee.location)) return this.employee.location.label;
      return this.employee.location;
    },
    position() {
      if (isObject(this.employee.position)) return this.employee.position.label;
      return this.employee.position;
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item .v-list-item__content {
  .v-list-item__title {
    font-weight: 500;
    color: $gray-6;

    .disclaimer {
      color: $gray-4;
      font-weight: 400;
    }
  }

  .v-list-item__subtitle {
    font-size: 16px;
    color: $gray-6;
    font-weight: 400;

    span {
      color: $gray-4;
      display: inline-block;
      margin-right: 5px;
    }
  }
}
</style>
