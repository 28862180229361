<template>
  <cp-module-modal
    :modalConf="modalConf"
    max-width="600"
    class="modal-content-card"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark dense
        >File Upload
        <v-btn icon dark @click="closeModal" class="ml-auto">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="file-upload-card"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{ 'grey lighten-2': dragover }"
      >
        <div class="drag-files-box">
          <v-icon aria-hidden="false" color="primary">
            mdi-upload
          </v-icon>
          <h5>Drag files to upload</h5>
          <p>
            Max Size: (10mb) Types: (jpg, jpeg, gif, png, pdf, doc, docx, xls,
            xlsx, ppt, pptx)
          </p>
        </div>

        <div class="text-center mt-3">
          <v-btn
            color="primary"
            small
            :loading="isSelecting"
            @click="handleFileImport"
          >
            Choose File
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileChanged"
            multiple
          />
        </div>
        <v-list>
          <template v-for="({ file, progress, error }, i) in files">
            <v-divider v-if="i > 0" :key="`file-${i}-divider`" />
            <v-list-item :key="`file-${i}`">
              <v-list-item-avatar>
                <v-icon color="#AFB4C5" size="34">mdi-file-document</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="file-name-wrapper">
                  <div class="file-name">
                    {{ file.name }}
                    <span class="text--secondary"> {{ file.size }} bytes</span>
                  </div>
                </v-list-item-title>

                <div v-if="error" class="d-flex align-center">
                  <v-alert class="mb-0" type="error" text dense>
                    {{ error }}
                  </v-alert>
                </div>
                <v-list-item-subtitle v-else>
                  <span v-if="progress === 0">Ready to upload</span>
                  <span
                    v-else-if="progress === 100"
                    class="success--text d-flex align-center"
                  >
                    <v-icon class="mr-2" color="success">mdi-check-bold</v-icon>
                    Completed
                  </span>
                  <div class="d-flex align-center" v-else>
                    <div class="mr-2">{{ progress }}%</div>
                    <v-progress-linear color="success" :value="progress" />
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="progress < 100 || error"
                  small
                  @click.stop="removeFile(i)"
                  :color="error ? 'error' : ''"
                >
                  <v-icon left small>mdi-trash-can-outline</v-icon>
                  Remove
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="filesToUpload.length === 0" @click="closeModal" small>
          Close
        </v-btn>
        <v-btn
          elevation="2"
          @click="saveAttachments"
          :disabled="filesToUpload.length === 0"
          color="primary"
          small
          :loading="saving"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </cp-module-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { get } from "@cp/utils/objectUtils";
import { attachmentSubForm } from "@/store/modules/tasks";

export default {
  name: "Upload",
  mixins: [attachmentSubForm.modalMixin],
  data() {
    return {
      dragover: false,
      files: [],
      isSelecting: false,
      selectedFile: null,
      saving: false,
    };
  },

  computed: {
    ...mapState("tasks", {
      taskId: s => get(s, "item.id"),
    }),
    filesToUpload() {
      return this.files.filter(
        ({ progress, error }) => !(progress === 100 || error)
      );
    },
  },

  methods: {
    ...mapActions("tasks", ["addAttatchment"]),
    saveAttachments() {
      this.saving = true;
      const promises = [];

      for (const index in this.filesToUpload) {
        const file = this.filesToUpload[index];
        let data = new FormData();
        data.append("add_attachment[file]", file.file);
        const onUploadProgress = e =>
          (file.progress = Math.round((e.loaded * 100) / e.total));
        try {
          promises.push(
            this.addAttatchment({
              id: this.taskId,
              file: data,
              onUploadProgress,
            })
              .then(x => {
                file.progress = 100;
                return true;
              })
              .catch(err => {
                console.log(err);
                file.error = get(err, ["response", "data", "file", 0]);
              })
          );
        } catch (err) {
          console.log(err);
          file.error = get(err, ["response", "data", "file", 0]);
        }
      }
      Promise.all(promises)
        .then(responses => {
          if (responses.every(x => x === true)) {
            this.close(responses.length);
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    close(fileCount) {
      this.files.splice(0, this.files.length);
      if (fileCount) {
        this.$CpEvent.$emit("snackAlert", {
          message: `${fileCount} files were attached to task`,
          color: "success",
        });
        this.closeModal();
      } else {
        this.closeModal();
      }
    },

    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      Object.values(e.target.files).forEach(this.addFile);
    },

    addFile(file) {
      this.files.push({
        file,
        progress: 0,
        error: "",
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    onDrop(e) {
      this.dragover = false;
      Object.values(e.dataTransfer.files).forEach(this.addFile);
    },
  },
};
</script>
