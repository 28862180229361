<template>
  <div>
    <cp-h1>
      Region Report
      <template #subtitle>
        <cp-breadcrumbs v-bind="breadcrumbProps" />
      </template>
    </cp-h1>
    <v-card class="modal-content-card mcc-details">
      <v-card-text>
        <div class="d-flex flex-column align-center">
          <v-progress-circular
            class="dashboard-compliance-score mt-0"
            :rotate="-90"
            :size="120"
            :width="14"
            :value="report.compliant_percentage"
            :color="report.compliant_percentage < 70 ? '#E40000' : '#009A44'"
          >
            {{ report.compliant_percentage }}%
          </v-progress-circular>
          <p class="text-center">
            <small>Average<br />Compliance</small>
          </p>
        </div>
        <div>
          <h4 class="mb-3 d-flex">
            <v-icon>mdi-check-decagram</v-icon>{{ detail.full_name }}
          </h4>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-tabs v-model="currentTab">
        <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab.label }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(tab, i) in tabs" :key="i">
          <report-sub-table v-bind="tab.tableProps" v-on="tab.tableListeners" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { regionReport } from "@/store/modules/reports";

export default {
  name: "TaskReport",
  mixins: [regionReport.mixin],
  computed: {
    ...mapState("reports/regionDetail", ["detail", "detailLoading"]),
    ...mapState("reports/regionReport", ["report", "reportLoading"]),
    loading() {
      return this.detailLoading || this.reportLoading;
    },
  },
  methods: {},
};
</script>
