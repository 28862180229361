import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-module-modal',{attrs:{"modalConf":_vm.modalConf,"max-width":"600"},on:{"opened":_vm.savePrevious,"click:outside":_vm.resetAndClose}},[_c(VCard,{staticClass:"modal-content-card"},[_c(VToolbar,{attrs:{"color":"primary","dark":"","dense":""}},[_vm._v("Due Date and Notifications")]),_c(VCardText,[_c('p',[_vm._v(" Setup the Schedule for your Task "),_c('cp-explain',[_vm._v(" When should this task be completed relative to employee's "),_c('strong',[_vm._v(_vm._s(_vm.relativeTo))])])],1),_c(VList,{staticClass:"ddn-modal-list",attrs:{"dense":""}},[_c(VListItem,{staticClass:"py-2"},[_c(VListItemContent,[_c('div',{staticClass:"list-title-wrapper dd-list-item"},[_c(VListItemTitle,[_vm._v("Due Date:")]),_c(VRadioGroup,_vm._b({attrs:{"row":"","hide-details":""},on:{"change":_vm.fields.due_type.input}},'v-radio-group',_vm.fields.due_type.attrs,false),[_c(VRadio,{attrs:{"label":"Before","value":"before"}}),_c(VRadio,{attrs:{"label":"On","value":"on"}}),_c(VRadio,{attrs:{"label":"After","value":"after"}})],1),_c(VListItemAction,[_c(VLabel,[_vm._v("("+_vm._s(_vm.relativeTo)+")")])],1)],1)])],1),(_vm.itemFormData.due_type != 'on')?_c(VListItem,{staticClass:"item-as-child"},[_c(VListItemContent,{staticClass:"pb-5"},[_c('div',{staticClass:"list-title-wrapper"},[_c(VListItemTitle),_c('div',{staticClass:"increment-and-decrement"},[_c(VBtn,{attrs:{"outlined":"","dense":"","color":"indigo","disabled":_vm.itemFormData.due_day_distance <=
                      _vm.fields.due_day_distance.attrs.min,"tabindex":"-1"},on:{"click":function($event){return _vm.decrement('due_day_distance')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-minus ")])],1),_c(VTextField,_vm._b({staticClass:"dueRelativeNumber",attrs:{"color":"indigo","dense":"","outlined":""},on:{"input":_vm.fields.due_day_distance.input}},'v-text-field',_vm.fields.due_day_distance.attrs,false)),_c(VBtn,{attrs:{"dense":"","outlined":"","color":"indigo","tabindex":"-1"},on:{"click":function($event){return _vm.increment('due_day_distance')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-plus ")])],1)],1),_c(VLabel,[_vm._v(_vm._s(_vm.pluralize("Day", _vm.itemFormData.due_day_distance))+" "+_vm._s(_vm.itemFormData.due_type)+" "+_vm._s(_vm.relativeTo))])],1)])],1):_vm._e(),_c(VListItem,[_c(VListItemContent,{staticClass:"py-5"},[_c('div',{staticClass:"list-title-wrapper"},[_c(VListItemTitle,[_vm._v("Email Alert Lead Time:")]),_c('div',{staticClass:"increment-and-decrement"},[_c(VBtn,{attrs:{"outlined":"","dense":"","color":"indigo","disabled":_vm.itemFormData.alert_lead_time <=
                      _vm.fields.alert_lead_time.attrs.min,"tabindex":"-1"},on:{"click":function($event){return _vm.decrement('alert_lead_time')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-minus ")])],1),_c(VTextField,_vm._b({staticClass:"dueRelativeNumber",attrs:{"color":"indigo","dense":"","outlined":""},on:{"input":_vm.fields.alert_lead_time.input}},'v-text-field',_vm.fields.alert_lead_time.attrs,false)),_c(VBtn,{attrs:{"dense":"","outlined":"","color":"indigo","tabindex":"-1"},on:{"click":function($event){return _vm.increment('alert_lead_time')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-plus ")])],1)],1),_c(VLabel,[_vm._v(_vm._s(_vm.pluralize("Day", _vm.itemFormData.alert_lead_time))+" before due date")])],1)])],1),_c(VListItem,[_c(VListItemContent,{staticClass:"py-5"},[_c('div',{staticClass:"list-title-wrapper"},[_c(VListItemTitle,[_vm._v("Task Escalated After:")]),_c('div',{staticClass:"increment-and-decrement"},[_c(VBtn,{attrs:{"outlined":"","dense":"","color":"indigo","disabled":_vm.itemFormData.days_till_escalate <=
                      _vm.fields.days_till_escalate.attrs.min,"tabindex":"-1"},on:{"click":function($event){return _vm.decrement('days_till_escalate')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-minus ")])],1),_c(VTextField,_vm._b({staticClass:"dueRelativeNumber",attrs:{"color":"indigo","dense":"","outlined":""},on:{"input":_vm.fields.days_till_escalate.input}},'v-text-field',_vm.fields.days_till_escalate.attrs,false)),_c(VBtn,{attrs:{"dense":"","outlined":"","color":"indigo","tabindex":"-1"},on:{"click":function($event){return _vm.increment('days_till_escalate')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-plus ")])],1)],1),_c(VLabel,[_vm._v(_vm._s(_vm.pluralize("Day", _vm.itemFormData.days_till_escalate))+" after due date")])],1)])],1),_c(VListItem,[_c(VListItemContent,{staticClass:"py-5"},[_c('div',{staticClass:"list-title-wrapper"},[_c(VListItemTitle,[_vm._v("Task Abandon After:")]),_c('div',{staticClass:"increment-and-decrement"},[_c(VBtn,{attrs:{"outlined":"","dense":"","color":"indigo","disabled":_vm.itemFormData.days_till_abandon <=
                      _vm.fields.days_till_abandon.attrs.min,"tabindex":"-1"},on:{"click":function($event){return _vm.decrement('days_till_abandon')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-minus ")])],1),_c(VTextField,_vm._b({staticClass:"dueRelativeNumber",attrs:{"color":"indigo","dense":"","outlined":""},on:{"input":_vm.fields.days_till_abandon.input}},'v-text-field',_vm.fields.days_till_abandon.attrs,false)),_c(VBtn,{attrs:{"dense":"","outlined":"","color":"indigo","tabindex":"-1"},on:{"click":function($event){return _vm.increment('days_till_abandon')}}},[_c(VIcon,{attrs:{"size":"14"}},[_vm._v(" mdi-plus ")])],1)],1),_c(VLabel,[_vm._v(_vm._s(_vm.pluralize("Day", _vm.itemFormData.days_till_abandon))+" after escalation date")])],1)])],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"2","color":"primary","dense":"","disabled":!_vm.allValid},on:{"click":_vm.closeModal}},[_vm._v(" Save Settings ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }