import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-h1',{scopedSlots:_vm._u([(_vm.meta.pagination.records && _vm.tableProps.options.itemsPerPage)?{key:"subtitle",fn:function(){return [_vm._v(" Total: "+_vm._s(_vm.meta.pagination.records)+" ")]},proxy:true}:null,{key:"right",fn:function(){return [_c('cp-filters-menu',{staticClass:"search--center",attrs:{"module":_vm.table}})]},proxy:true}],null,true)},[_vm._v(" Employees ")]),_c(VCard,{staticClass:"card-listing"},[_c('div',{staticClass:"pb-4"},[_c(VDataTable,_vm._g(_vm._b({staticClass:"clickable-rows",attrs:{"id":"employees-table"},on:{"click:row":function (item) { return _vm.$router.push({
              name: 'EmployeeStatus',
              params: { id: item.id },
            }); }},scopedSlots:_vm._u([{key:"header.position",fn:function(ref){
            var header = ref.header;
return [_c('cp-table-header-subqueryable',{attrs:{"header":header,"table":_vm.table,"submodule-ref-key":"employeesPositions"}})]}},{key:"header.location",fn:function(ref){
            var header = ref.header;
return [_c('cp-table-header-subqueryable',{attrs:{"header":header,"table":_vm.table,"submodule-ref-key":"employeesLocations"}})]}},{key:"header.user_lifecycle_statuses_id",fn:function(ref){
            var header = ref.header;
return [_c('cp-table-header-subqueryable',{attrs:{"header":header,"table":_vm.table,"submodule-ref-key":"employeesLifecycleStatuses"}})]}},{key:"item.users.first_name",fn:function(ref){
            var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.full_name))])]}},{key:"item.alert",fn:function(ref){
            var item = ref.item;
return [(parseInt(item.compliant_percentage) < 70)?_c(VBadge,{staticClass:"v-badge-circle",attrs:{"color":"#E40000","dot":""}}):_vm._e()]}},{key:"item.compliant_percentage",fn:function(ref){
            var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(Math.round(item.compliant_percentage))+"%")])]}},{key:"item.user_lifecycle_statuses_id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.lifecycle_name)+" ")]}}])},'v-data-table',_vm.tableProps,false),_vm.tableListeners))],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }