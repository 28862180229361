<template>
  <div class="position-relative">
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <div class="chart-total">
      <small>Total</small> <span class="d-block">{{ totalScore || "0" }}</span>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: {
    totalScore: Number,
    value: Object,
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    chartData() {
      let chartData = {
        datasets: [
          {
            backgroundColor: [
              "#009A44",
              "#FED608",
              "#FBAF57",
              "#E40000",
              "#8B5DCB ",
              "#e7eaf4",
            ],
            data: [
              this.value.completed,
              this.value.pending,
              this.value.cant_complete,
              this.value.past_due,
              this.value.abandoned,
              this.value.defaultColorValue,
            ],
          },
        ],
      };
      return chartData;
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 70,
        plugins: {
          tooltip: {
            enabled: true,
          },
        },
      },
    };
  },
};
</script>
