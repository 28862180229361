<template>
  <cp-explain :close-on-content-click="false" max-width="550" offset-x>
    <component :is="component" :item="item" />
  </cp-explain>
</template>

<script>
export default {
  props: {
    component: { type: String, required: true },
    item: { type: Object, required: true },
  },
};
</script>
