<template>
  <div>
    <cp-h1>
      Region Reports

      <template #subtitle>Total: {{ meta.pagination.records }}</template>
      <template #right>
        <cp-filters-menu :module="table" class="search--right" />
      </template>
    </cp-h1>

    <v-card>
      <report-table
        v-bind="tableProps"
        v-on="tableListeners"
        @click:row="
          item =>
            $router.push({
              name: 'RegionReport',
              params: { id: item.m_id },
            })
        "
      ></report-table>
    </v-card>
  </div>
</template>

<script>
import { regionsTable } from "@/store/modules/reports";

export default {
  mixins: [regionsTable.mixin, regionsTable.filtersMixin],
};
</script>
