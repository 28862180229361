import { ApiDataWithLoadMore } from "@cp/store/mixins";
import { deepMerge } from "@cp/utils/collectionUtils";
import { isEmpty } from "@cp/utils/objectUtils";

export class Search extends ApiDataWithLoadMore {
  constructor({
    params: paramsArg,
    loadMore = false,
    addFilter = () => ({}),
    mapOptions = ({ slug: value, name: text } = {}) => ({ value, text }),
    ...config
  }) {
    const initialValue = [];
    const params = deepMerge(
      {
        sort_by: "name",
        sort_desc: false,
        q_text: "",
        page: {
          size: loadMore ? 15 : "all",
        },
        filters: [],
      },
      paramsArg
    );
    super({ ...config, initialValue, params });
    this.instantiate({ loadMore, addFilter, mapOptions });
  }

  getParams(...getterCtx) {
    const filterToAdd = this.addFilter(...getterCtx);
    return isEmpty(filterToAdd)
      ? {}
      : {
          filters: [filterToAdd],
        };
  }

  mapData(response) {
    const items = super.mapData(response);
    return items.map(this.mapOptions);
  }
}
