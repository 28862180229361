import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-module-modal',_vm._g({attrs:{"modalConf":_vm.modalConf,"width":"1100"}},_vm.$listeners),[_c(VToolbar,{attrs:{"dark":"","color":"primary","height":"52"}},[_c(VToolbarTitle,[_vm._v("Select a template:")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","icon":""},on:{"click":_vm.closeModal}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCard,[_c(VRadioGroup,{staticClass:"mt-0",model:{value:(_vm.selectedTemplateId),callback:function ($$v) {_vm.selectedTemplateId=$$v},expression:"selectedTemplateId"}},[_c(VTabs,{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.templates),function(template){return _c(VTab,{key:("tab-" + (template.id)),staticClass:"justify-start px-0"},[_c(VRadio,{attrs:{"label":template.title,"value":template.id}})],1)}),_vm._l((_vm.templates),function(template){return _c(VTabItem,{key:("item-" + (template.id)),attrs:{"transition":false,"transition-reverse":false}},[_c('template-preview-card',{attrs:{"item":template}})],1)})],2)],1),_c(VCardActions,{staticClass:"justify-end"},[(_vm.success)?_c('div',[_c('CpSuccessIcon',{staticClass:"mr-4",attrs:{"xLarge":""}}),_vm._v(" Plan Created ")],1):_c('div',[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"ml-3",attrs:{"disabled":!_vm.selectedTemplateId,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.create}},[_vm._v(" Create from Template ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }