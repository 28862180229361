<template>
  <div>
    <cp-h1>
      Plan Reports

      <template #subtitle>Total: {{ meta.pagination.records }}</template>
      <template #right>
        <cp-filters-menu :module="table" class="search--right" />
      </template>
    </cp-h1>

    <v-card>
      <report-table
        v-bind="tableProps"
        v-on="tableListeners"
        @click:row="
          item =>
            $router.push({ name: 'PlanReport', params: { id: item.m_id } })
        "
      ></report-table>
    </v-card>
  </div>
</template>

<script>
import { plansTable } from "@/store/modules/reports";

export default {
  mixins: [plansTable.mixin, plansTable.filtersMixin],
};
</script>
