<template>
  <div>
    <div v-if="tabSlug === 'active'">
      <v-list-item @click="copyItem(item.id)">
        <v-list-item-title>Duplicate {{ title }}</v-list-item-title>
      </v-list-item>
    </div>
    <div v-if="tabSlug === 'archived'">
      <v-list-item @click="restoreItem(item.id)">
        <v-list-item-title> Restore {{ title }} </v-list-item-title>
      </v-list-item>
    </div>
    <div v-if="tabSlug === 'archived' && item.is_deletable">
      <v-list-item @click="removeItem(item.id)">
        <v-list-item-title> Delete {{ title }} </v-list-item-title>
      </v-list-item>
    </div>
    <div v-else-if="tabSlug === 'active'">
      <v-dialog v-model="dialog" persistent content-class="dialog-confirm">
        <template v-slot:activator="scope">
          <v-list-item @click="() => {}">
            <v-list-item-title v-bind="scope.attrs" v-on="scope.on">
              Archive {{ title }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-card>
          <v-card-title>
            Confirm Deactivation
          </v-card-title>
          <v-card-text>
            <p>
              Do you want to deactivate this {{ title }}? You can reactivate it
              later, If you wish.
            </p>
            <p class="mb-0">
              <strong
                >This {{ title }} is currently in use with
                {{ item.employees }} employees</strong
              >
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="archiveItem(item.id)">
              Deactivate
            </v-btn>
            <v-btn color="primary" @click="dialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ActionModal",
  props: {
    item: Object,
    title: String,
    tabSlug: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions("plans", {
      fetchPlans: "fetch",
    }),
    ...mapActions("plans", [
      "deletePlan",
      "duplicatePlan",
      "archivePlan",
      "restorePlan",
    ]),
    // ...mapActions("plans", {
    //   fetchTasks: "fetch",
    // }),
    ...mapActions("tasks", [
      "fetchTasks",
      "deleteTask",
      "duplicateTask",
      "archiveTask",
      "restoreTask",
    ]),
    // ...mapActions("plans", {
    //   fetchGroups: "fetch",
    // }),
    ...mapActions("assignments", [
      "fetchGroups",
      "deleteGroup",
      "duplicateGroup",
      "archiveGroup",
      "restoreGroup",
    ]),

    showSnackAlert(text) {
      this.$CpEvent.$emit("snackAlert", {
        message: text,
        color: "success",
      });
    },

    async removeItem(id) {
      this.title === "Plan"
        ? await this.deletePlan(id)
        : this.title === "Task"
        ? await this.deleteTask(id)
        : this.title === "Group"
        ? await this.deleteGroup(id)
        : null;
      let text = `${this.title} Deleted`;
      this.showSnackAlert(text);
      this.title === "Plan"
        ? this.fetchPlans()
        : this.title === "Task"
        ? this.fetchTasks()
        : this.title === "Group"
        ? this.fetchGroups()
        : null;
    },
    async copyItem(id) {
      this.title === "Plan"
        ? await this.duplicatePlan(id)
        : this.title === "Task"
        ? await this.duplicateTask(id)
        : this.title === "Group"
        ? await this.duplicateGroup(id)
        : null;
      let text = `${this.title} Duplicated`;
      this.showSnackAlert(text);
      this.title === "Plan"
        ? this.fetchPlans()
        : this.title === "Task"
        ? this.fetchTasks()
        : this.title === "Group"
        ? this.fetchGroups()
        : null;
    },
    async archiveItem(id) {
      // this.dialog = false;
      this.title === "Plan"
        ? await this.archivePlan(id)
        : this.title === "Task"
        ? await this.archiveTask(id)
        : this.title === "Group"
        ? await this.archiveGroup(id)
        : null;
      let text = `${this.title} Archived`;
      this.showSnackAlert(text);
      this.title === "Plan"
        ? this.fetchPlans()
        : this.title === "Task"
        ? this.fetchTasks()
        : this.title === "Group"
        ? this.fetchGroups()
        : null;
    },
    async restoreItem(id) {
      this.title === "Plan"
        ? await this.restorePlan(id)
        : this.title === "Task"
        ? await this.restoreTask(id)
        : this.title === "Group"
        ? await this.restoreGroup(id)
        : null;
      let text = `${this.title} Restored`;
      this.showSnackAlert(text);
      this.title === "Plan"
        ? this.fetchPlans()
        : this.title === "Task"
        ? this.fetchTasks()
        : this.title === "Group"
        ? this.fetchGroups()
        : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
