<template>
  <div>
    <cp-h1>
      {{ constants.TASKS }}
      <template #subtitle>Total: {{ meta.pagination.records }}</template>
      <template #rightOfTitle>
        <v-btn
          @click="openModal({ mode: 'create' })"
          color="primary"
          dark
          small
        >
          <v-icon left class="mr-1">mdi-plus</v-icon>
          New
        </v-btn>
      </template>
      <template #right>
        <cp-filters-menu :module="table" class="search--center" />
      </template>
    </cp-h1>
    <v-card class="card-listing">
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        @click:row="item => openModal({ id: item.id })"
        class="clickable-rows"
        hide-default-footer
      >
        <template #header.task_type_id="{header}">
          <cp-table-header-filterable
            :header="header"
            :table="table"
            filter-key="task_types"
          />
        </template>

        <template #no-data>
          <no-data-screen
            :text="noDataText"
            :noun="noun"
            @click="openModal({ mode: 'create' })"
          />
        </template>

        <template #item.is_core="{value, item}">
          <core-indicator v-if="value" class="ml-4 d-inline-block">
            <div>
              <h3>This is a core task.</h3>
              It enables core features.
            </div>
          </core-indicator>
          <cp-explain
            v-if="
              !item.assignment_type ||
                (item.assignment_type === 'assignment_group' &&
                  !item.assignment_group_id)
            "
          >
            <template #activator="{on}">
              <v-icon v-on="on" class="ml-3" color="error">mdi-alert</v-icon>
            </template>
            Missing Assignment!
          </cp-explain>
        </template>

        <template v-slot:item.updated_at="{ value }">
          {{ toMDY(value) }}
        </template>

        <template v-slot:item.task_type_id="{ item }">
          {{ item.task_type.label }}
        </template>

        <template v-slot:item.alert="{ item }">
          <cp-explain
            :close-on-content-click="false"
            max-width="550"
            offset-x
            root-slot
          >
            <task-detail-card :item="item" />
          </cp-explain>
        </template>

        <template #item.tag_list="{value}">
          <div class="d-flex justify-center" style="gap:4px">
            <v-chip v-for="(tag, i) in value" :key="i" pill text small>
              {{ tag }}
            </v-chip>
          </div>
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { onCpEvent } from "@cp/mixins";
import { toMDY } from "@cp/utils/dateUtils.js";
import { itemsTable, itemFormModal } from "@/store/modules/tasks";
import { genericConstants } from "../constants/genericConstants";

export default {
  name: "Tasks",
  mixins: [
    itemsTable.mixin,
    itemsTable.filtersMixin,
    itemFormModal.modalMixin,
    onCpEvent(itemFormModal.keys.modalClosedEvent, function() {
      this.fetchItems();
    }),
  ],
  data() {
    return {
      constants: genericConstants,
    };
  },
  methods: {
    toMDY,
    ...mapActions("tasks", ["fetchItems"]),
  },
};
</script>
