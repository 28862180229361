<template>
  <v-card class="modal-content-card">
    <v-card-text>
      <div class="plan-details-wrap">
        <div class="pd-sec-head">
          <h2>
            <v-icon>mdi-clipboard-check</v-icon>
            {{ item.title }}

            <core-indicator v-if="item.is_core" class="ml-4">
              <div>
                <h3>This is a core plan.</h3>
                It applies to all locations and positions.
              </div>
            </core-indicator>
          </h2>
        </div>
        <div class="pd-info">
          <p>
            <strong>Plan Type:</strong>
            {{ item.plan_type.label }}
          </p>
          <p>
            <strong>Positions:</strong>
            {{ item.positions_for_plan.map(data => data.name).join(", ") }}
          </p>
          <p>
            <strong>Description:</strong>
            <div v-html="item.description" class="displayHTML"></div>
          </p>
        </div>
        <div class="pd-tasks-list-wrap">
          <v-list class="tasks-list-wrap">
            <v-list-item v-for="task in item.tasks" :key="task.id">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <v-icon color="#008A09">mdi-check-decagram</v-icon>

                  <span class="mx-4">
                    {{ task.title }}
                  </span>

                  <core-indicator v-if="task.is_core" size="24">
                    <div>
                      <h3>This is a core task.</h3>
                      It cannot be removed.
                    </div>
                  </core-indicator>

                  <cp-explain
                    v-if="
                      !task.assignment_type ||
                        (task.assignment_type === 'assignment_group' &&
                          !task.assignment_group_id)
                    "
                  >
                    <template #activator="{on}">
                      <v-icon v-on="on" class="ml-3" color="error"
                        >mdi-alert</v-icon
                      >
                    </template>
                    Missing Assignment!
                  </cp-explain>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon color="#8B5DCB">mdi-calendar-clock</v-icon>
                  <span class="mx-4">{{ task.schedule }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <no-data-screen
            v-if="!item.tasks.length"
            text="This template task list is empty"
            icon="mdi-check-decagram"
            sub-text="You will need to create tasks for this plan"
            :show-button="false"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ModalPlanDetail",
  props: {
    item: { type: Object, required: true },
  },
  computed: {},
  methods: {
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("plans", [
      "openItemFormModal",
      "openTasksAddExistingTaskModal",
      "addTasksToItem",
      "removeTaskFromItem",
      "duplicatePlan",
      "refetchItem",
    ]),
    openTaskModal() {
      this.$store.dispatch("tasks/openItemFormModal", {
        mode: `addToPlan:${this.item.id}`,
      });
    },
    async taskModalClosed({ success, id } = {}) {
      if (success) {
        await this.addTasksToItem([id]);
        this.refetchItem();
      }
    },
    ifSuccessRefetchItem({ success } = {}) {
      if (success) this.refetchItem();
    },
    navToTask(id) {
      // THIS INTRODUCES A URL BUG, but has a much nicer experience
      // YOLO
      // this.closeModal();
      // this.$router.push({ name: "Tasks" });
      this.$store.dispatch("tasks/openItemFormModal", { id });
    },
  },
};
</script>

<style scoped>
.plan-details-wrap .pd-tasks-list-wrap::v-deep .empty-block {
  min-height: 0;
}
</style>
