import dashboard from "./modules/dashboard";
import plans from "./modules/plans";
import tasks from "./modules/tasks";
import assignments from "./modules/assignments";
import reports from "./modules/reports";
import enrollments from "./modules/enrollments";
import employees from "./modules/employees";
import taskStatusForm from "./modules/taskStatusForm";

export default {
  dashboard,
  plans,
  tasks,
  assignments,
  reports,
  enrollments,
  employees,
  taskStatusForm,
};
