<template>
  <v-data-table v-bind="$attrs" v-on="$listeners">
    <template v-slot:item.alert="{ item }">
      <v-badge
        v-if="parseInt(item.compliant_percentage) < 70"
        color="#E40000"
        dot
        class="v-badge-circle"
      ></v-badge>
    </template>

    <template v-slot:item.compliant_percentage="{ item }">
      <strong>{{ Math.round(item.compliant_percentage) }}%</strong>
    </template>
  </v-data-table>
</template>

<script>
export default {};
</script>
