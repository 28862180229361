<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    overlay
    temporary
    width="360px"
    right
  >
    <div class="drawer-header">
      <h5>Notes</h5>
      <v-btn
        dense
        class="drawer-close-btn"
        small
        text
        color="primary"
        @click="closeDrawer"
        ><v-icon size="16">mdi-close</v-icon>Close</v-btn
      >
    </div>

    <!-- <v-divider></v-divider> -->

    <!-- <v-list dense>
            <v-list-item v-for="item in items" :key="item.title" link>
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list> -->
    <v-list three-line class="comment-list">
      <template v-for="(item, index) in items">
        <!-- <v-divider v-if="item.divider" :key="index" :inset="item.inset"></v-divider> -->
        <v-list-item v-if="item.title" :key="index">
          <v-list-item-avatar>
            <v-icon class="avatar-icon">mdi-account</v-icon>
            <!-- <v-img :src="item.avatar"></v-img> -->
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="item.title"></v-list-item-title>
            <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { colors } from "vuetify/lib";

export default {
  name: "CommentThreadDrawer",
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      drawer: null,
      items: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "{Task Assignee} <span>01/15/23</span>",
          subtitle: `Maecenas sem tellus, tincidunt quis rutrum dictum, ultrices sed dolor.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: "{Admin} <span> 01/12/23</span>",
          subtitle: `Sounds OK.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "{Task Assignee} <span>01/11/23</span>",
          subtitle: "Curabitur congue gravida turpis et scelerisque.",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "{Task Assignee} <span>01/09/23</span>",
          subtitle:
            "Donec consectetur diam in auctor ultricies. Vestibulum pulvinar tincidunt lacinia. Vivamus interdum justo eros.",
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "{Admin} <span>01/08/23</span>",
          subtitle:
            "Sed dapibus finibus tortor, elementum auctor libero euismod sed.",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "{Task Assignee} <span>01/07/23</span>",
          subtitle: "Nulla gravida vitae neque eu rutrum.",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "{Admin} <span>01/04/23</span>",
          subtitle:
            "Curabitur dignissim, lorem sed posuere lobortis, sem nunc maximus justo, interdum dignissim tellus lorem ut elit.",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "{Admin} <span>01/02/23</span>",
          subtitle: "Duis imperdiet vel enim ut gravida.",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "{Task Assignee} <span>12/27/22</span>",
          subtitle:
            "Etiam elementum orci turpis, non efficitur tortor fringilla eu. Suspendisse potenti.",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "{Admin} <span>12/26/22</span>",
          subtitle:
            "Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        },
      ],
    };
  },
  methods: {
    closeDrawer() {
      this.$emit("update:drawer", false);
    },
  },
};
</script>
