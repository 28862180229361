<template>
  <div>
    <cp-h1>
      Task Report
      <template #subtitle>
        <cp-breadcrumbs v-bind="breadcrumbProps" />
      </template>
    </cp-h1>
    <v-card class="modal-content-card mcc-details">
      <v-card-text>
        <div class="d-flex flex-column align-center">
          <v-progress-circular
            class="dashboard-compliance-score mt-0"
            :rotate="-90"
            :size="120"
            :width="14"
            :value="report.compliant_percentage"
            :color="report.compliant_percentage < 70 ? '#E40000' : '#009A44'"
          >
            {{ report.compliant_percentage }}%
          </v-progress-circular>
          <p class="text-center">
            <small>Average<br />Compliance</small>
          </p>
        </div>
        <div>
          <h4 class="mb-3 d-flex">
            <v-icon class="mr-3">mdi-check-decagram</v-icon>{{ detail.title }}
          </h4>
          <p>
            <strong>Description:</strong>
            <div v-html="detail.description" class="displayHTML"></div>
          </p>
          <p>
            <strong>Schedule:</strong>
            {{ detail.schedule || "N/A" }}
          </p>
          <p>
            <strong>Plans Using This Task:</strong>
            <span v-if="detail.plans && detail.plans.length">
              {{ detail.plans.map(x => x.title).join(", ") }}
            </span>
            <span v-else>
              N/A
            </span>
          </p>
          <p>
            <strong>Assigned To:</strong>
            {{ detail.assigned_to || "N/A" }}
          </p>
          <p class="mb-0">
            <strong>Attachments:</strong>
            <span v-if="detail.files && detail.files.length">
              <cp-file-chip
                v-for="file in detail.files"
                :key="file.id"
                :name="file.name"
                :url="file.url"
                class="mx-3"
                small
              />
            </span>
            <span v-else>N/A</span>
          </p>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-tabs v-model="currentTab">
        <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab.label }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(tab, i) in tabs" :key="i">
          <report-sub-table v-bind="tab.tableProps" v-on="tab.tableListeners" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { taskReport } from "@/store/modules/reports";

export default {
  name: "TaskReport",
  mixins: [taskReport.mixin],
  computed: {
    ...mapState("reports/taskDetail", ["detail", "detailLoading"]),
    ...mapState("reports/taskReport", ["report", "reportLoading"]),
    loading() {
      return this.detailLoading || this.reportLoading;
    },
  },
  methods: {},
};
</script>
