<template>
  <v-card class="description-popover">
    <v-card-title class="grey--text text--darken-2">{{
      item.task.name
    }}</v-card-title>
    <v-card-text>
      <p>
        <strong>Description:</strong>
        <div v-html="item.task.description" class="displayHTML"></div>
      </p>
      <p>
        <strong>Assignees:</strong>
        TODO -- assignees
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UserTaskDetailCard",
  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: break-word;
}
</style>
