<template>
  <cp-toolbar-module-modal
    :modalConf="modalConf"
    v-on="$listeners"
    width="unset"
    root-slot
  >
    <template #title>Update Task</template>
    <v-card tile>
      <v-card-text class="pt-4" style="max-width: 600px">
        <strong>{{ item.task.title }}</strong>

        <br />

        <template v-if="item.task.description">
          <strong>Description:</strong>
          <div v-html="item.task.description" class="displayHTML" />
        </template>

        <span v-if="item.assignees.length === 1">
          <strong>Assigned to:</strong> {{ item.assignees[0].name }}
        </span>
        <span v-else-if="item.assignees.length > 1">
          <strong>Assigned to:</strong>
          <cp-explain>
            <template #activator="{on}">
              <v-chip v-on="on" class="text-no-wrap" small>
                {{ pluralize("Employee", item.assignees.length, true) }}
              </v-chip>
            </template>

            <cp-profile-list :profiles="item.assignees" dense />
          </cp-explain>
        </span>
        <span v-else class="red--text">
          Task is unassigned
        </span>
        on
        {{ dates.created_at }}

        <br />

        <template v-if="item.files.length">
          <strong>Attachments:</strong>
          <cp-file-chip
            v-for="file in item.files"
            :key="file.id"
            :name="file.name"
            :url="file.url"
            class="ma-1"
            small
          />
          <br />
        </template>

        <strong>Due:</strong>
        {{ dates.due_by_date }}
        <span
          v-if="dates.dueDatePassed && !item.completed_at"
          class="red--text"
        >
          ({{ pluralize("day", dates.dueDaysAgo, true) }} ago)
        </span>

        <br />

        <strong>Escalation Date:</strong>
        {{ dates.escalate_date }}

        <br />

        <strong>Abandon Date:</strong>
        {{ dates.abandon_date }}

        <br />

        <template v-if="item.completed_at">
          <strong>Completed By:</strong>
          {{ item.completed_by_name }} on {{ dates.completed_at }}
          <span v-if="dates.completedAfterDue" class="red--text">
            ({{ pluralize("day", dates.completedAfterDueDays, true) }} late)
          </span>
        </template>
      </v-card-text>

      <v-alert :icon="status.icon" v-bind="status.chipProps" tile>
        <strong>{{ status.title }}</strong>
        <br />
        <span v-if="item.user_task_progress_status_id === 1">
          will alert on {{ dates.alert }}
        </span>
        <span v-if="item.user_task_progress_status_id === 2">
          due
          <span v-if="daysFrom.due > 0">
            in {{ pluralize("day", daysFrom.due, true) }}
          </span>
          <span v-else>today</span>
        </span>
        <span v-if="item.user_task_progress_status_id === 3">
          {{ pluralize("days", dates.dueDaysAgo, true) }} late
        </span>
        <span v-if="item.user_task_compliance_status_id === 1">
          Completed on time by
          {{ item.completed_by_name }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 2">
          Completed {{ pluralize("days", dates.dueDaysAgo, true) }} late by
          {{ item.completed_by_name }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 3">
          Marked <span class="font-weight-black">Can't Complete</span> by
          {{ item.completed_by_name }} on
          {{ dates.completed_at }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 4">
          Abandoned on {{ dates.abandon_date }}
        </span>
        <span v-if="item.user_task_compliance_status_id === 5">
          Marked
          <span class="font-weight-black">Canceled</span>
          {{ item.admin_note.replace(/^Cancell*ed /, "") }}
          on
          {{ dates.adminNote }}
        </span>
      </v-alert>

      <v-card-text v-if="item.notes.length" class="my-4 py-1">
        <v-alert
          v-for="(note, i) in item.notes"
          :key="`note-${i}`"
          color="blue-grey"
          class="note"
          text
        >
          <div class="note-attribution text-caption">
            {{ note.user }} at {{ toTIME(note.created_at) }}
            {{ toMDY(note.created_at) }}
          </div>
          {{ note.note }}
        </v-alert>
      </v-card-text>

      <v-card-text v-if="item.admin_actions.length" class="mt-4 pt-0">
        <v-btn-toggle
          v-bind="fields.admin_action.attrs"
          @change="handleAdminActionChange"
          class="mb-4"
          mandatory
        >
          <v-btn
            v-for="action in item.admin_actions"
            :key="action"
            :set="(button = actionButton(action))"
            v-bind="button.props"
            :value="action"
          >
            <v-icon v-if="button.icon">{{ button.icon }}</v-icon>
            {{ button.text }}
          </v-btn>
        </v-btn-toggle>

        <v-autocomplete
          v-if="itemFormData.admin_action === 'complete'"
          v-bind="fields.completed_by.attrs"
          @input="fields.completed_by.input"
          label="Completed By:"
          :items="completedByOptions"
          outlined
          ref="completedBy"
          @change="$refs.completedBy.internalSearch = ''"
        />

        <cp-date-picker
          v-if="itemFormData.admin_action === 'complete'"
          v-bind="fields.completed_at.attrs"
          @input="fields.completed_at.input"
          label="Completed On:"
          outlined
        />

        <v-textarea
          label="Enter your note here"
          v-bind="fields.note.attrs"
          @input="fields.note.input"
          auto-grow
          outlined
          rows="5"
          row-height="25"
          dense
        />

        <cp-form-modal-success-or-buttons
          :modalConf="modalConf"
          :formConf="formConf"
          :buttons="['cancel', 'update']"
          @submit="submit"
          noun="Task"
          success-message="Task Updated!"
        />
      </v-card-text>
    </v-card>
  </cp-toolbar-module-modal>
</template>
<script>
import { mapGetters } from "vuex";
import pluralize from "pluralize";

import { taskStatusForm } from "@/store/modules/taskStatusForm";
import {
  complianceStatusesById,
  progressStatusesById,
} from "@/store/modules/tasks";
import { parse, toMDY, toTIME } from "@cp/utils/dateUtils";
import { wait } from "@cp/utils/promiseUtils";

export default {
  name: "ModalEditTaskStatus",
  mixins: [taskStatusForm.mixin, taskStatusForm.modalMixin],
  computed: {
    ...mapGetters("auth", ["userId", "userName"]),
    dates() {
      const now = parse();
      const due = parse(this.item.due_by_date);
      const completed = parse(this.item.completed_at);
      return {
        now,
        due,
        created_at: toMDY(this.item.created_at),
        completed_at: toMDY(this.item.completed_at),
        updated_at: toMDY(this.item.updated_at),
        due_by_date: toMDY(this.item.due_by_date),
        escalate_date: toMDY(this.item.escalate_date),
        abandon_date: toMDY(this.item.abandon_date),
        dueDatePassed: now.isAfter(due, "day"),
        dueDaysAgo: now.diff(due, "days"),
        completedAfterDue: completed.isAfter(due, "day"),
        completedAfterDueDays: completed.diff(due, "days"),
        adminNote: toMDY(this.item.admin_note_created_at),
        alert: toMDY(this.item.alert_date),
      };
    },
    daysFrom() {
      const today = parse().startOf("day");
      const alert = parse(this.item.alert_date).startOf("day");
      const due = parse(this.item.due_by_date).startOf("day");
      return {
        alert: alert.diff(today, "days"),
        due: due.diff(today, "days"),
      };
    },
    completedByOptions() {
      if (this.$refs.completedBy && this.$refs.completedBy.internalSearch)
        return this.employeeOptions;
      return [
        { text: `${this.userName} (you)`, value: this.userId },
        ...this.item.assignees.map(ass => ({
          text: ass.name,
          value: ass.id,
        })),
      ];
    },
    status() {
      if (this.item.user_task_progress_status_id === 4)
        return complianceStatusesById[this.item.user_task_compliance_status_id];
      else return progressStatusesById[this.item.user_task_progress_status_id];
    },
  },
  methods: {
    pluralize,
    toMDY,
    toTIME,
    async submit() {
      const $vm = this;
      await this.itemSubmit({ id: this.item.id, method: "PUT" });
      if ($vm.success) {
        await wait(1500);
        $vm.closeModal({ success: true });
      }
    },
    actionButton(action) {
      return taskStatusForm.adminActions[action];
    },
    handleAdminActionChange(value) {
      this.fields.admin_action.input(value);
      if (value === "complete") {
        this.$nextTick(() => {
          this.fields.completed_by.input(this.userId);
          this.fields.completed_at.input(parse().format("YYYY/MM/DD"));
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
  .v-btn.v-btn--active {
    color: white !important;
    border-color: $primary;
    box-shadow: none;
    background-color: $primary;
  }

  .v-icon {
    color: inherit !important;
    fill: currentColor !important;
    &:first-child {
      margin-right: 8px;
    }
  }
}

.v-alert {
  margin-bottom: 0;
}

.v-alert.white--text::v-deep .v-icon {
  color: white;
}

.note {
  margin-top: 22px;

  & + .note {
    margin-top: 30px;
  }

  &:before {
    border-radius: 0 4px 4px 4px;
  }

  .note-attribution {
    position: absolute;
    left: 0;
    bottom: 100%;
    border-radius: 5px 5px 0 0;
    background: #eceff1;
    padding: 0 10px;
    box-shadow: inset 0 -2px 2px -2px rgba(0, 0, 0, 0.3);
  }
}
</style>
