<template>
  <div>
    <cp-h1>
      {{ constants.ASSIGNMENT_GROUPS }}

      <template #subtitle>Total: {{ meta.pagination.records }}</template>
      <template #rightOfTitle>
        <v-btn @click="openModal()" color="primary" dark small>
          <v-icon left class="mr-1">mdi-plus</v-icon>
          New
        </v-btn>
      </template>
      <template #right>
        <cp-filters-menu :module="table" class="search--center" />
      </template>
    </cp-h1>

    <v-card class="card-listing">
      <v-data-table
        v-bind="tableProps"
        v-on="tableListeners"
        @click:row="item => openModal({ id: item.id })"
        class="clickable-rows"
      >
        <template #no-data>
          <no-data-screen
            :text="noDataText"
            :noun="noun"
            @click="openModal()"
          />
        </template>

        <template v-slot:item.updated_at="{ value }">
          {{ toMDY(value) }}
        </template>

        <template v-slot:item.employee_count="{ item }">
          <v-btn
            v-if="!item.employee_count"
            @click="openModal({ id: item.id })"
            color="error"
            small
          >
            <v-icon small class="mr-5px">
              mdi-account-multiple-plus
            </v-icon>
            Add Employees
          </v-btn>
          <span v-else>{{ item.employee_count }}</span>
        </template>

        <template v-slot:item.alert="{ item }">
          <cp-explain
            :close-on-content-click="false"
            max-width="550"
            offset-x
            root-slot
          >
            <assignment-detail-card :item="item" />
          </cp-explain>
        </template>
      </v-data-table>

      <v-pagination
        v-if="!noData"
        class="mt-2 text-center"
        v-bind="paginationProps"
        v-on="paginationListeners"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { onCpEvent } from "@cp/mixins";
import { toMDY } from "@cp/utils/dateUtils.js";
import { itemsTable, itemFormModal } from "@/store/modules/assignments";
import { genericConstants } from "../constants/genericConstants";
import AssignmentDetailCard from "@/components/detailCards/AssignmentDetailCard.vue";

export default {
  components: { AssignmentDetailCard },
  name: "Assignments",
  mixins: [
    itemsTable.mixin,
    itemsTable.filtersMixin,
    itemFormModal.modalMixin,
    onCpEvent(itemFormModal.keys.modalClosedEvent, function() {
      this.fetchItems();
    }),
  ],
  data() {
    return {
      constants: genericConstants,
    };
  },
  methods: {
    toMDY,
    ...mapActions("assignments", ["fetchItems"]),
  },
};
</script>
