import cloneDeep from "clone-deep";
import { mapState } from "vuex";
import { Mixin, ApiDataMixin, Table } from "@cp/store/mixins";
import { deepMerge, get, getFirst, set } from "@cp/utils/objectUtils";
import { capitalize } from "@cp/utils/stringUtils";
import { eventbus } from "carrot-patch-v2/src/lib";

export const defaultTableOptions = {
  sort_desc: false,
  sort_by: "",
  page: {
    number: 1,
    size: 15,
  },
};

export class ReportsTable extends Table {
  constructor({
    tableOptions: tableOptionsArg = {},
    filters: filtersArg = [],
    ...config
  }) {
    const tableOptions = deepMerge(defaultTableOptions, tableOptionsArg);
    const filters = ["q_text", ...filtersArg];
    super({
      ...config,
      tableOptions,
      filters,
      exportOptions: { htmlToCanvas: "#report-table" },
    });
  }

  mapData(response) {
    // Michael is a stinky bumm
    return get(response, "data.data.results", []);
  }
}

const reportsApi = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/reports/api`;

export class Report extends ApiDataMixin {
  constructor({
    scope = "clients",
    params: paramsArg = {},
    filterByIdKey,
    singleResponse = false,
    ...config
  }) {
    const params = deepMerge(
      {
        page: {
          number: 1,
          size: 15,
        },
        sort_by: "",
        sort_desc: false,
        structures: {
          scope,
        },
      },
      paramsArg
    );
    const initialValue = {
      m_id: "",
      name: "",
      title: "",
      users_task_count: 0,
      users_count: 0,
      location_count: 0,
      task_count: 0,
      completed: 0,
      ontime: 0,
      pending: 0,
      cant_complete: 0,
      past_due: 0,
      abandoned: 0,
      canceled: 0,
      compliant: 0,
      non_compliant: 0,
      compliant_percentage: "",
    };
    super({ ...config, baseUrl: reportsApi, params, initialValue });
    this.instantiate({ scope, filterByIdKey, singleResponse });
  }

  mapData(response) {
    // Michael is a stinky bumm
    const path = this.singleResponse
      ? "data.data.results[0]"
      : "data.data.results";
    const def = this.singleResponse ? {} : [];
    return get(response, path, def);
  }

  fetch(ctx, args) {
    let config = cloneDeep(args);
    if (config && config.id && this.filterByIdKey) {
      set(config, ["params", "filters", this.filterByIdKey], [config.id]);
      delete config.id;
    }
    super.fetch(ctx, config);
  }
}

export class ReportPage extends Mixin {
  constructor({
    name,
    detailConfig: detailConfigArg = {},
    reportConfig: reportConfigArg = {},
    subReports = [],
    ...config
  }) {
    super(config);
    const keys = {
      id: `${name}Id`,
      fetch: `fetch${capitalize(name)}Report`,
      report: `${name}Report`,
      detail: `${name}Detail`,
    };
    this.instantiate({ keys, name });

    const reportConfig = deepMerge(
      {
        module: `${name}Report`,
        name: "report",
        singleResponse: true,
      },
      reportConfigArg
    );
    this.report = new Report({ ...reportConfig, parent: this });

    const detailConfig = deepMerge(
      {
        module: `${name}Detail`,
        name: "detail",
        url: "/:id",
        urlTemplate: true,
      },
      detailConfigArg
    );
    this.detail = new ApiDataMixin({ ...detailConfig, parent: this });

    this.subReports = [];
    this.subReportModules = {};

    for (const config of subReports) {
      const subReportKey = `${name}${capitalize(config.scope)}SubReport`;
      const subReport = new SubReport({
        ...config,
        parent: this,
        module: subReportKey,
        name: config.scope,
      });
      this.subReports.push(subReport);
      this.subReportModules[subReportKey] = subReport.toVuex;
    }

    this.add({
      state: {
        [keys.id]: "",
      },
      modules: {
        [keys.report]: this.report.toVuex,
        [keys.detail]: this.detail.toVuex,
        ...this.subReportModules,
      },
    });
  }

  get resetPaths() {
    return [
      `${this.report.mp}/${this.report.k.reset}`,
      `${this.detail.mp}/${this.detail.k.reset}`,
      ...this.subReports.map(sr => `${sr.mp}/${sr.k.reset}`),
    ];
  }

  get mixin() {
    const $reportPage = this;
    return {
      data() {
        return {
          currentTab: 0,
          reportPage: $reportPage,
        };
      },
      computed: {
        ...mapState($reportPage.mp, { id: $reportPage.k.id }),
        ...mapState($reportPage.detail.mp, ["detail", "detailLoading"]),
        ...mapState($reportPage.report.mp, ["report", "reportLoading"]),
        loading() {
          return this.detailLoading || this.reportLoading;
        },
        breadcrumbProps() {
          const nounCaps = capitalize($reportPage.name);
          const dT = this.detail.title;
          const dFN = this.detail.full_name;
          const dN = this.detail.name;
          const title = dT || dFN || dN;
          return {
            items: [
              {
                text: `${nounCaps} Reports`,
                to: { name: `${nounCaps}sReports` },
                exact: true,
              },
              {
                text: title,
              },
            ],
          };
        },
        tabs() {
          return $reportPage.subReports.map(sr => ({
            label: sr.label,
            tableProps: {
              class: { "clickable-rows": sr.routeName },
              ...sr.mixin.computed.tableProps.call(this),
            },
            tableListeners: {
              "click:row"(item) {
                if (sr.routeName) {
                  eventbus.$emit("redirect", {
                    name: sr.routeName,
                    params: { id: item.m_id },
                  });
                }
              },
              ...sr.mixin.computed.tableListeners.call(this),
            },
          }));
        },
      },
      methods: {
        fetch() {
          const id = this.$route.params.id;
          if (!id) return;
          set(this.$store.state, $reportPage.p.s.id, id);
          this.$store.dispatch(
            $reportPage.report.p.a.fetch,
            { id },
            { root: true }
          );
          this.$store.dispatch(
            $reportPage.detail.p.a.fetch,
            { id },
            { root: true }
          );
        },
      },
      mounted() {
        this.fetch();
      },
      beforeRouteUpdate(to, from, next) {
        this.fetch();
        next();
      },
      beforeDestroy() {
        this.$store.state.reports[$reportPage.keys.id] = "";
        for (const path of $reportPage.resetPaths) {
          this.$store.commit(path, null, { root: true });
        }
      },
    };
  }
}

export class SubReport extends Table {
  constructor({
    scope,
    params: paramsArg = {},
    label,
    routeName,
    headers: headersArg = [],
    ...config
  }) {
    if (!config.parent) {
      console.log(this);
      throw "SubReport is missing its parent";
    }
    const params = deepMerge({ structures: { scope } }, paramsArg);
    const headers = [
      {
        text: "",
        sortable: false,
        value: "alert",
        width: "50",
      },
      {
        text: "Score",
        sortable: true,
        value: "compliant_percentage",
        width: 120,
      },
      ...headersArg,
    ];
    super({
      ...config,
      params,
      headers,
      baseUrl: reportsApi,
      jsonApiResponse: false,
    });
    this.instantiate({ scope, label, routeName });
  }

  fetch(ctx, args) {
    let config = cloneDeep(args || {});
    const filterByIdKey = this.parent.report.filterByIdKey;
    const parentId = get(ctx.rootState, this.parent.p.s.id);
    if (parentId && filterByIdKey) {
      set(config, ["params", "filters", filterByIdKey], [parentId]);
      // delete config.id;
    }
    return super.fetch(ctx, config);
  }

  mapData(response) {
    // Michael is a stinky bumm
    return get(response, "data.data.results", []);
  }
}
