import { keyBy } from "@cp/utils/arrayUtils";
import {
  required,
  requiredIf,
  minMaxLength,
  startsWithNonSpace,
  endsWithNonSpace,
  allowMostChars,
} from "@cp/utils/rules";
import { modalAddon } from "@cp/store/addons";
import { mergeMixins, Mixin, ItemForm, ItemsTable } from "@cp/store/mixins";
import { toMDY } from "@cp/utils/dateUtils.js";
import { get } from "@cp/utils/objectUtils";
import { wait } from "@cp/utils/promiseUtils";
import { unique } from "@cp/utils/arrayUtils";

import { Search } from "../mixins";
import { eventbus } from "@cp/lib";

const module = "plans";
const fiverUrl = `${process.env.VUE_APP_FIVER_API_PATH}/en/v1`;
const marigoldUrl = `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1`;
const baseUrl = `${fiverUrl}/plans`;

export const planTypes = [
  {
    label: "Onboarding",
    value: 1,
    assignedTo: "new hires",
  },
  {
    label: "Offboarding",
    value: 2,
    assignedTo: "departing employees",
  },
];
export const planTypeObj = keyBy(planTypes, "value");

export const itemFormModal = new ItemForm({
  module,
  baseUrl,
  url: "/:id",
  urlTemplate: true,
  params: {
    structures: {
      addon_options: ["include_tasks"],
    },
  },
  initialValue: {
    all_locations: true,
    all_positions: true,
    id: "",
    plan_status_id: 1,
    plan_type_id: 1,
    title: "",
    description: "",
    updated_at: "",
    is_core: false,
    "is_deletable?": false,
    plan_type: {
      label: "Onboarding",
      value: 1,
    },
    plan_status: {
      label: "Active",
      value: 1,
    },
    employees_on_plan: 0,
    positions_for_plan: [],
    active_region_ids: [],
    active_location_ids: [],
    active_position_ids: [],
    location_ids: [],
    position_ids: [],
    tasks: [],
  },
  fields: {
    title: {
      label: "Plan Title",
      rules: [
        required,
        startsWithNonSpace,
        minMaxLength(3, 155),
        allowMostChars,
        endsWithNonSpace,
      ],
    },
    description: {
      label: "Description",
    },
    plan_type_id: { initialValue: 1 },
    plan_status_id: { initialValue: 1 },
    all_locations: { initialValue: true },
    location_ids: {
      initialValue: [],
      rules: [requiredIf(({ all_locations }) => !all_locations)],
    },
    all_positions: { initialValue: true },
    position_ids: {
      initialValue: [],
      rules: [requiredIf(({ all_positions }) => !all_positions)],
    },
  },
});
itemFormModal.add(
  modalAddon({
    modalName: "form",
    open({ dispatch, commit }, { id } = {}) {
      if (id) return dispatch("fetchItem", { id });
      else commit(itemFormModal.keys.reset);
    },
    async close({ dispatch, commit }, { mode, created, id }) {
      if (created) {
        dispatch("openItemDetailModal", { id });
        return;
      }
      await wait(300);
      commit("resetItem", { formOnly: true });
    },
  })
);
window.$itemFormModal = itemFormModal;

// THIS IS DIRTY
// For now, mock up the needed mixin for modalAddon
// in future, we should make a `class ItemDetailModal{}`
// but that involves too much work for now.
export const itemDetailModal = new Mixin({
  module,
  state: {
    detailModalItemId: "",
  },
  actions: {
    refetchItem({ state, dispatch }) {
      dispatch("fetchItem", { id: state.detailModalItemId });
    },
  },
});
itemDetailModal.instantiate({
  keys: {
    stateKey: "item",
    nameCaps: "Item",
  },
});
itemDetailModal.add(
  modalAddon({
    modalName: "detail",
    routeName: "Plans",
    routeParamId: "id",
    open({ state, dispatch }, { id } = {}) {
      state.detailModalItemId = id;
      if (id) dispatch("fetchItem", { id });
    },
    close({ state, commit }) {
      state.detailModalItemId = "";
      commit("resetItem");
    },
  })
);

export const itemsTable = new ItemsTable({
  module,
  baseUrl,
  noun: "plan",
  tableOptions: {
    sort_by: "title",
  },
  headers: [
    {
      text: "",
      value: "is_core",
      width: 0,
      class: "px-0",
      cellClass: "px-0",
      sortable: false,
    },
    {
      text: "Plans",
      value: "title",
      cellClass: "clickable-cell",
    },
    {
      text: "Type",
      value: "plan_type_id",
      sortable: false,
    },
    {
      text: "Modified",
      value: "updated_at",
      sortable: true,
    },
  ],
  filters: [
    "q_text",
    {
      type: "select",
      key: "status",
      label: "Status",
      items: [
        { label: "Active", value: 1 },
        { label: "Draft", value: 3 },
        { label: "Inactive", value: 2 },
      ],
      multiple: true,
      initialValue: [1, 3],
    },
    {
      type: "radio",
      key: "plan_types",
      items: [
        { label: "All", value: "" },
        { label: "Onboarding", value: 1 },
        { label: "Offboarding", value: 2 },
      ],
      initialValue: "",
    },
    {
      type: "sub_query",
      key: "location_initiators",
      label: "Plan has location",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "position_initiators",
      label: "Plan has position",
      multiple: true,
    },
    {
      type: "sub_query",
      key: "plan_tasks",
      filterKey: "tasks",
      label: "Plan has task",
      multiple: true,
    },
  ],
});

const regions = new Search({
  module,
  baseUrl: `${marigoldUrl}/geographical_groups/autocomplete`,
  name: "regions",
  params: {
    filters: [{ depth: "region" }, { depth: "corporate" }],
  },
  mapOptions: x => ({
    id: x.id,
    depth: x.depth,
    name: x.name,
    area_id: x.area_id,
    children: get(x, "locations", []).map(x => ({
      name: x.name,
      id: x.id,
    })),
  }),
});

export const tasks = new Search({
  module,
  baseUrl: `${process.env.VUE_APP_FIVER_API_PATH}/en/v1/tasks/autocomplete`,
  name: "tasks",
  loadMore: true,
  params: {
    sort_by: "",
    page: {
      size: 20,
    },
  },
  addFilter: ({ item }) => ({
    // task_types: item.plan_type_id === 1 ? "onboarding" : "offboarding",
    available_for_plan: item.id,
  }),
  // keep the whole object
  mapOptions: x => x,
  getters: {
    addExistingTaskModalTitle({ item }) {
      const type = item.plan_type_id === 1 ? "onboarding" : "offboarding";
      return `Search for an ${type} task`;
    },
  },
});
tasks.add(
  modalAddon({
    modalName: "addExistingTask",
  })
);

export const templates = new Search({
  module,
  baseUrl: `${fiverUrl}/templates`,
  name: "templates",
  params: {
    sort_by: "",
    page: { size: "all" },
    structures: {
      addon_options: ["include_tasks"],
    },
  },
  mapOptions: x => x,
});
templates.add(
  modalAddon({
    modalName: "createPlanFromTemplate",
    async open({ commit, dispatch }) {
      commit(templates.keys.reset);
      await dispatch(templates.keys.fetch);
    },
  })
);

const positions = new Search({
  module,
  baseUrl: `${marigoldUrl}/positions/autocomplete`,
  name: "positions",
  mapOptions: ({
    id: value,
    name: text,
    generalized_position_category: category,
  } = {}) => ({ value, text, category }),
});

function sortAlphabeticallyNullsLast(a, b) {
  // equal items sort equally
  if (a === b) return 0;

  // nulls sort after anything else
  if (a === null) return 1;
  if (b === null) return -1;

  return a < b ? -1 : 1;
}

export default mergeMixins(
  itemsTable,
  itemFormModal,
  itemDetailModal,
  regions,
  tasks,
  templates,
  positions,
  {
    getters: {
      plansCount(state) {
        return state.meta.additional_meta || {};
      },
      plansList(state) {
        return state.items.map(data => {
          return {
            id: data.id,
            plan: data.attributes.title,
            plan_status_id: data.attributes.plan_status_id,
            type: data.attributes.plan_type.label,
            modified: toMDY(data.attributes.updated_at),
            description: data.attributes.description,
            position: data.attributes.positions_for_plan
              .map(data => data.name)
              .join(", "),
            employees: data.attributes.employees_on_plan,
            is_deletable: data.attributes["is_deletable?"],
          };
        });
      },

      regionTreeItems({ regions: regionsOptions }) {
        const unnasignedArea = {
          id: "unnasigned",
          name: "Unnasigned Regions",
          children: [],
        };
        const areas = regionsOptions.filter(x => x.depth === "area");
        const regions = regionsOptions.filter(x => x.depth === "region");
        const corporate = regionsOptions.filter(x => x.depth === "corporate");

        // Add any corporate locations as a root level
        const items = [...corporate];
        if (areas.length) {
          items.push(...areas);
          regions.forEach(region => {
            const area = areas.find(x => x.id === region.area_id);
            if (area) area.children.push(region);
            else unnasignedArea.children.push(region);
          });

          // remove areas with no regions assigned
          for (let i = 0; i < items.length; i++) {
            if (!items[i].children.length) {
              items.splice(i, 1);
            }
          }

          // If any regions didn't have areas, push the unnasignedAreas as the last option
          if (unnasignedArea.children.length) areas.push(unnasignedArea);
        } else {
          items.push(...regions);
        }
        return items;
      },
      positionsWithHeaders({ positions }) {
        const categories = unique(positions.map(x => x.category));
        categories.sort(sortAlphabeticallyNullsLast);
        const response = [];
        categories.forEach(category => {
          response.push({ header: category || "Uncategorized Positions" });
          response.push(...positions.filter(x => x.category === category));
        });
        return response;
      },
    },
    actions: {
      addTasksToItem({ state }, task_ids) {
        const id = state.item.id;
        if (!id) return;
        return itemFormModal.client.put(`${state.item.id}/add_tasks`, {
          task_ids,
        });
      },
      async removeTaskFromItem({ state, dispatch }, task_id) {
        const id = state.item.id;
        if (!id) return;
        return itemFormModal.client
          .put(`${state.item.id}/remove_tasks`, {
            task_ids: [task_id],
          })
          .then(({ data }) => {
            dispatch("refetchItem");
            eventbus.$emit("snackAlert", {
              message: "Task removed from plan",
              color: "success",
            });
          });
      },

      async archivePlan({ state, dispatch }) {
        return itemFormModal.client
          .put("/archive", { ids: [state.item.id] })
          .then(() => {
            eventbus.$emit("snackAlert", {
              message: "Plan archived",
              color: "success",
            });
            dispatch("fetchItem", { id: state.item.id });
          });
      },

      async restorePlan({ state, dispatch }) {
        return itemFormModal.client
          .put("/restore", { ids: [state.item.id] })
          .then(() => {
            eventbus.$emit("snackAlert", {
              message: "Plan restored",
              color: "success",
            });
            dispatch("fetchItem", { id: state.item.id });
          });
      },

      async publishPlan({ state, dispatch }) {
        return itemFormModal.client
          .put("/publish", { ids: [state.item.id] })
          .then(() => {
            eventbus.$emit("snackAlert", {
              message: "Plan published",
              color: "success",
            });
            dispatch("fetchItem", { id: state.item.id });
          });
      },

      async duplicatePlan({ state, dispatch }) {
        const id = state.item.id;
        return itemFormModal.client.put(`${id}/duplicate`).then(() => {
          eventbus.$emit("snackAlert", {
            message: "Plan duplicated",
            color: "success",
          });
          dispatch("closeItemDetailModal");
        });
      },

      async deletePlan({ state, dispatch }) {
        const id = state.item.id;
        return itemFormModal.client.delete(id).then(() => {
          eventbus.$emit("snackAlert", {
            message: "Plan deleted",
            color: "success",
          });
          dispatch("closeItemDetailModal");
        });
      },
    },
  }
);
