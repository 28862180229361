<template>
  <div class="empty-block">
    <div>
      <v-icon class="icon-large">{{ icon }}</v-icon>
      <h4>{{ text }}</h4>
      <p v-if="subText">{{ subTextValue }}</p>
      <div v-if="showButton">
        <v-btn @click="$emit('click')" color="primary" class="mt-2">
          <v-icon class="mr-2">mdi-plus</v-icon>
          Add
          {{ article }}
          {{ capitalize(noun) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from "@cp/utils/stringUtils";

export default {
  name: "NoDataScreen",
  props: {
    text: { type: String, required: true },
    subText: { type: String, default: "" },
    icon: { type: String, default: "mdi-clipboard-text-multiple" },
    showButton: { type: Boolean, default: true },
    noun: { type: String, default: "item" },
  },
  computed: {
    article() {
      return /^[aeiou]/i.test(this.noun) ? "An" : "A";
    },
    subTextValue() {
      return this.subText || (this.showButton && "but you can create one!");
    },
  },
  methods: {
    capitalize,
  },
};
</script>

<style lang="scss" scoped>
.empty-block {
  background: #fff;
  padding: 3rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .card-listing & {
    min-height: 40vh;
  }

  &.empty-block-border {
    border: 1px solid #afb4c5;
    border-radius: 4px;
  }

  .v-icon.icon-large {
    width: 5.75rem;
    height: 5.75rem;
    border-radius: 50%;
    background: rgba(30, 100, 220, 0.1);
    border: 1px solid rgba(30, 100, 220, 0.3);
    border-radius: 50%;
    color: #1e64dc;
    font-size: 3.5rem;
    margin-bottom: 0.375rem;
  }

  h4,
  p {
    color: #767b87;
    margin: 0.5rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
  }

  p {
    font-size: 14px;
  }
}
</style>
